import React,{useState,useEffect} from "react";
import VendorService  from '../services/vendor.service';
import { Dna } from 'react-loader-spinner';

function VendorStatusProgress() {
    const [loading, setLoading] = useState(true);
    const [ vendorStatus, setVendorStatus ] = useState([]);
    const [submitted_on, setVendorSubmittedOn ] = useState(null);
    const [approved_by_admin, setVendorApprovedByAdmin ] = useState(null);
    const [accounts , setVendorAccounts ] = useState(null);
    const [management, setVendorManagement ] = useState(null);
    const [legal, setVendorLegal ] = useState(null);
    const [accept_contract_copy, setVendorAcceptContractCopy ] = useState(null);
    const [approved , setVendorApproved ] = useState(null);
    const MINUTE_MS = 600000;//10 mins 
    useEffect(() => {
        async function getVendorStatus() {
         let data = await VendorService.getVendorStatusProgress();
         if(data){
            data.map(function(val, index){
                if(val.status==="Submitted"){
                    setVendorSubmittedOn(convertDate(val.created_at));
                }
                if(val.status==="Approved by Admin"){
                    setVendorApprovedByAdmin(convertDate(val.created_at));
                }
                if(val.status==="Accounts"){
                    setVendorAccounts(convertDate(val.created_at));
                }
                if(val.status==="Management"){
                    setVendorManagement(convertDate(val.created_at));
                }
                if(val.status==="Legal"){
                    setVendorLegal(convertDate(val.created_at));
                }
                if(val.status==="Signed Contract Copy"){
                    setVendorAcceptContractCopy(convertDate(val.created_at));
                }
                if(val.status==="Approved"){
                    setVendorApproved(convertDate(val.created_at));
                }
            })
         }
         setVendorStatus(data);
        }
        getVendorStatus();
        setInterval(() => {
            getVendorStatus();
        }, MINUTE_MS);
        setLoading(false); 
       }, []);

       const convertDate=(date)=> {
        var todaysDate = new Date(date);
        var yyyy = todaysDate.getFullYear().toString();
        var mm = (todaysDate.getMonth()+1).toString();
        var dd  = todaysDate.getDate().toString();
        var mmChars = mm.split('');
        var ddChars = dd.split('');
        return (ddChars[1]?dd:"0"+ddChars[0]) + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + yyyy;
      }

    return (
        <>
            <div className="form-title">Progress Status of Form</div>
            <div className="progress-bar">
                        {(loading) ? ( <Dna
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="dna-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="dna-wrapper"
                                    />) : vendorStatus && (
                                            <ul>
                                                <li className={(submitted_on)?"completed" : ""}>
                                                    <div className="step-details">
                                                        <div className="label">Submitted on <br /> {submitted_on}</div>
                                                    </div>
                                                </li>
                                                <li className={(approved_by_admin)?"completed" : ""}>
                                                    <div className="step-details">
                                                        <div className="label">Approved by Admin <br /> on {approved_by_admin}</div>
                                                    </div>
                                                </li>
                                                <li className={(accounts)?"completed" : ""}>
                                                    <div className="step-details">
                                                        <div className="label">Accounts <br /> on {accounts}</div>
                                                    </div>
                                                </li>
                                                <li className={(management)?"completed" : ""}>
                                                    <div className="step-details">
                                                        <div className="label">Management <br /> on {management}</div>
                                                    </div>
                                                </li>
                                                <li className={(legal)?"completed" : ""}>
                                                    <div className="step-details">
                                                        <div className="label">Legal <br /> on {legal}</div>
                                                    </div>
                                                </li>
                                                <li className={(accept_contract_copy)? "completed" : ""}>
                                                    <div className="step-details">
                                                        <div className="label">Accept <br /> Contract Copy <br /> on {accept_contract_copy}</div>
                                                    </div>
                                                </li>
                                                <li className={(approved)?"completed" : ""}>
                                                    <div className="step-details">
                                                        <div className="label">Approved <br /> on {approved}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                        )}
                         </div>
        </>
    )
}
export default VendorStatusProgress;