import axios from "axios";
import Header from '../services/event/auth-header';
import AdminHeader from '../services/event/admin-header';
import SuperAdminHeader from '../services/event/sadmin-header';
import { toast } from 'react-toastify';

const apiHost = process.env.REACT_APP_API_URL;
//test evn
export async function postAxios(apiUrl,payload,file_flg) {
  return await axios.post(apiHost+apiUrl, payload, {
        headers: Header(file_flg)
      })
      .then((response) => {
        return  response.data;
      })
      .catch((error) => {
        const msg= error.response.data.message ||error.message;
        toastMsg(msg,'error');
        return false;
      })
}

export async function getAxios(apiUrl) {
 return await axios.get(apiHost+apiUrl,{headers: Header()})
            .then((response => {
                return response.data;
            }))
            .catch((error) => {
              const msg= error.response.data.message ||error.message;
              toastMsg(msg,'error');
                return false;
            });
}


export async function postAdminAxios(apiUrl,payload) {
  return await axios.post(apiHost+apiUrl, payload, {
        headers: AdminHeader(true)
      })
      .then((response) => {
        return  response.data;
      })
      .catch((error) => {
        const msg= error.response.data.message ||error.message;
        toastMsg(msg,'error');
        return false;
      })
}

export async function putAdminAxios(apiUrl,payload) {
  return await axios.put(apiHost+apiUrl, payload, {
        headers: AdminHeader(true)
      })
      .then((response) => {
        return  response.data;
      })
      .catch((error) => {
        console.log('error',error);
        const msg= error.response.data.message ||error.message;
        toastMsg(msg,'error');
        return false;
      })
}

export async function getAdminAxios(apiUrl) {
 return await axios.get(apiHost+apiUrl,{headers: AdminHeader()})
            .then((response => {
                return response.data;
            }))
            .catch((error) => {
              const msg= error.response.data.message ||error.message;
              toastMsg(msg,'error');
                return false;
            });
}

export async function postSuperAdminAxios(apiUrl,payload) {
  return await axios.post(apiHost+apiUrl, payload, {
        headers: SuperAdminHeader(true)
      })
      .then((response) => {
        return  response.data;
      })
      .catch((error) => {
        const msg= error.response.data.message ||error.message;
        toastMsg(msg,'error');
        return false;
      })
}

export async function putSuperAdminAxios(apiUrl,payload) {
  return await axios.put(apiHost+apiUrl, payload, {
        headers: SuperAdminHeader(true)
      })
      .then((response) => {
        return  response.data;
      })
      .catch((error) => {
        console.log('error',error);
        const msg= error.response.data.message ||error.message;
        toastMsg(msg,'error');
        return false;
      })
}

export async function getSuperAdminAxios(apiUrl) {
 return await axios.get(apiHost+apiUrl,{headers: SuperAdminHeader()})
            .then((response => {
                return response.data;
            }))
            .catch((error) => {
              const msg= error.response.data.message ||error.message;
              toastMsg(msg,'error');
                return false;
            });
}

export async function toastMsg(msg,type){
  if(type==='error'){
    toast.error(msg, {
      position: "top-right",
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }else{
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }

}