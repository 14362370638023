import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import '../css/file18.css';
import { RotatingTriangles, ColorRing } from 'react-loader-spinner'
import VendorService  from '../services/vendor.service';
import AuthService  from '../services/auth.service';
import Sidebar from '../components/sidebarComponent';
import VendorStatusProgressComponent from '../components/vendorStatusProgressComponent'
import VendorContractCopy from "../components/vendorContractCopy";


function VendorTrackingPage(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 10000;//10 sec 
    const [ vendor, setVendor ] = useState([]);
    const [preview, setPreviewData] = useState([]);
    const [file_scanned_pan, setFileScannedPan] = useState({});
    const [documentTypes, setDocumentTypes] = useState({}); // State variable for document types
    const [error_pan, setErrorPan] = useState({});
    const [fileErrors, setFileErrors] = useState({});
    const [selectedFilesis, setSelectedFiles] = useState({});
    const [formDataArray, setFormDataArray] = useState([]);


  

    const FormValidation = (selectedFiles, documentType) => {
        // Rest of your validation logic...
        // Example validation check
        if (!selectedFiles || selectedFiles.length === 0) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [documentType]: `Please upload a file ${documentType}`,
          }));
        } else {
          // File validation succeeded
          setFileScannedPan(selectedFiles[0]); // Set the file using selectedFiles[0]

        //   console.log("checking files at 0 what returns");
        //   console.log(selectedFiles);

          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [documentType]: "",
          }));
      
          // Perform any other necessary validations or actions for the selected file
          // ...

        //   console.log(selectedFiles);
      
          // Hide the validation message
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [documentType]: "",
          }));
        }

        return true;
      };
      
      
   

      const onSubmit = async (event) => {
       
        event.preventDefault();
        const documentTypes = ['pan_aadhar_undertaking', 'resume', 'other_document'];
        const selectedFilesObject = {};
        let isValid = true;

        const formData = new FormData();
        const formDataArray = [];


        documentTypes.forEach((documentType) => {
            const selectedFiles = event.target[`file_scanned_${documentType}`].files;
            // const selectedFiles = event.target.files;

            console.log(selectedFiles);
                  if (selectedFiles && selectedFiles.length > 0) {
            
                selectedFilesObject[documentType] = selectedFiles;

                // selectedFilesObject[documentType] = selectedFiles;
                // formData.append(`file_scanned_${documentType}`, selectedFiles);

                        // Log the file data from selectedFiles
                        for (let i = 0; i < selectedFiles.length; i++) {
                            // console.log(selectedFiles[i]);
                            formData.append(`file_scanned_${documentType}`, selectedFiles[i]);


                        }
                        setFormDataArray([...formDataArray, formData]);
                        console.log(formDataArray);

                } else {
                                    setFileErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [documentType]: "No file selected.",
                                    }));
                                    isValid = false;
                                    }
                                
                                });

                        console.log(formDataArray);

                }





      const ImageValidation =(file,type='')=>{
        console.log("imagvalidation");
        var filePath = file.name;
        console.log(file.size);
        // Allowing file type
        var allowedExtensions =  /(\.jpg|\.jpeg|\.pdf)$/i;
        if(type==='xlsx'){
            var allowedExtensions =  /(\.xlsx)$/i;
        }
        if(type==='pdf'){
            var allowedExtensions =  /(\.pdf)$/i;
        }
        if (!allowedExtensions.exec(filePath)) {
            return {status:false,"msg":"Invalid file type"};
        }else if (file.size < 4000 || file.size > 5000000) { // Size of document should be between 4KB and 5MB.
            return {status:false,"msg":"Size of document should be between 4KB and 5MB."};
        } 
        return {status:true,"msg":""};
      }
      
      
      
      
      
  

    useEffect(() => {
        if(!AuthService.isLoggedInVendor()){
            navigate(process.env.PUBLIC_URL+"/vendor-login");
        }else{
            getVendor();
        }
       async function getVendor() {
        let data = await VendorService.getCurrentVendor();
        setVendor(data);
        // setPreview(data.vendor_documents);
        setPreviewData(data.vendor_documents);
       }
       getVendor();
       setLoading(false); 
      }, []);

     


      const handleChangeFiles = (event, documentType) => {
        const file = event.target.files[0];
      
        const valid = ImageValidation(file);
      
        if (valid.status) {
          // Set the file and clear the error message
          setFileScannedPan(file);
          setSelectedFiles((prevSelectedFiles) => ({
            ...prevSelectedFiles,
            [documentType]: file,
          }));
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [documentType]: "",
          }));
      
          // Trigger FormValidation for the corresponding document type
        //   FormValidation(event, documentType);
        } else {
          // Reset the input value and display the error message
          event.target.value = null;
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [documentType]: valid.msg,
          }));
        }
      };
      
      
      
      
      
      

    
 
    
    const Capitalize =(str)=>{
        if (typeof str !== 'undefined')
            return str.charAt(0).toUpperCase() + str.slice(1);  
    }

    const convertDate=(date)=> {
        var todaysDate = new Date(date);
        var yyyy = todaysDate.getFullYear().toString();
        var mm = (todaysDate.getMonth()+1).toString();
        var dd  = todaysDate.getDate().toString();
        var mmChars = mm.split('');
        var ddChars = dd.split('');
        return (ddChars[1]?dd:"0"+ddChars[0]) + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + yyyy;
      }

    return(
        <div className="page-wrapper">
        <Sidebar sidebarFor="Vendor" final_form_flag={vendor.final_flag} />
        <div className="content-wrapper">
            <div className="page-content page-contributor-preview page-track-status">
                <div className="page-header">
                    <h4>Track Form Status</h4>
                </div>
                <div className="card">
                    <div className="card-body">
                        <VendorStatusProgressComponent/>
                        {(loading) ? (<RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangels-loading"
                                        wrapperStyle={{}}
                                        wrapperclassName="triangle-loading"
                                        visible={loading}
                                    />) : vendor.vendor_info && (   
                        <><form  className="contributor-form" onSubmit={onSubmit}>
                            
                          
                            {/* <section className="section-photo-signature">
                                <div className="cap-wrapper w-full">
                                    <div className="form-caption">Photo &amp; Signature</div>
                                    <div className="ps-wrapper">
                                        <div className="cap-wrapper">
                                            <div className="form-caption">Photo</div>
                                            <div className="image-preview-data">
                                                <div className="label">Preview Photo</div>
                                                <div className="image-preview">
                                                    <img src={src} alt={alt}/>
                                                </div>
                                            </div>
                                            {(photo_status==="Rejected") && (
                                                <>
                                                <div className="upload-wrapper">
                                                    <div className="attach-file-wrp">
                                                        <input type="file" id="file_photo" name="file_photo" onChange={handleChangePhoto}  accept=".jpg, .jpeg, .png"  />
                                                    </div>
                                                </div>
                                                 <label className="error"> {error_photo ? error_photo.msg : ""} </label>
                                                 </>
                                            )}                                                
                                        </div>
                                        <div className="cap-wrapper">
                                            <div className="form-caption">Signature</div>
                                            <div className="sign-preview-data">
                                                <div className="label">Preview Signature</div>
                                                <div className="image-preview">
                                                    <img src={src_s} alt={alt_s}/>
                                                </div>
                                            </div>
                                            {(signature_status==="Rejected") && (
                                                <>
                                                <div className="upload-wrapper">
                                                    <div className="attach-file-wrp">
                                                        <input type="file" id="file_signature" name="file_signature" onChange={handleChangeSignature} accept=".jpg, .jpeg, .png"  />
                                                    </div>
                                                </div>
                                                <label className="error"> {error_signature ? error_signature.msg : ""} </label>
                                                </>
                                            )}
                                        </div>
                                        <div className="ps-status">
                                            <div className="label">
                                            Signature Status:{(signature_status==="Processing")? signature_status :""}
                                                    {(signature_status==="Accepted") && (<div className="status-label accepted">
                                                            <i className="fi fi-verified-black"></i> Accepted
                                                        </div>)}
                                                        {(signature_status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                        <div className="reason-text">Reason : {signature_comments}.</div></>)} 
                                            <br/>
                                            Photo  Status: {(photo_status==="Processing")? photo_status :""}
                                                    {(photo_status==="Accepted") && (<div className="status-label accepted">
                                                            <i className="fi fi-verified-black"></i> Accepted
                                                        </div>)}
                                                        {(photo_status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                        <div className="reason-text">Reason : {photo_comments}.</div></>)} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section> */}

                            <section className="section-doc-upload">
                                <div className="cap-wrapper w-full">
                                    <div className="form-caption">Documents Upload 2</div>
                                    <div className="tble-wrapper">
                                        <div className="thead">
                                            <div className="th">Proof Name</div>
                                            <div className="th">Document Name</div>
                                            <div className="th attach-file-div">Attach File</div>
                                            <div className="th">Status</div>
                                        </div>
                                        <div className="tbody-wrapper">
                                           { preview.map((document) => (

                                                 <div className="tbody" key={document.id}>
                                                 <div className="td">
                                                     <div className="tab-label v-mob">Proof Name</div>
                                                     <div className="label text-upper">{ document.document_type}<span className="asterisk">*</span></div>
                                                 </div>
                                                 <div className="td">
                                                     <div className="tab-label v-mob">Document Name</div>
                                                     <div className="label text-upper">SCANNED { document.document_type}</div>
                                                 </div>
                                                 <div className="td attach-file-div">
                                                     <div className="tab-label v-mob">Attach File</div>
                                                     {(document.status==="Rejected") && (
                                                         <>
                                                         <div className="upload-wrapper">
                                                             <div className="attach-file-wrp">
                                                                 <input type="file" id={`file_scanned_${document.document_type}`}  name={`file_scanned_${document.document_type}`}  
      onChange={(event) => handleChangeFiles(event, document.document_type)}
      accept=".jpg, .jpeg, .pdf" />
                                                             </div>
                                                         </div>
                                                         <label className="error">{fileErrors[document.document_type]}</label>
                                                          </>    
                                                     )}
                                                     {(document.status!=="Rejected") && (
                                                     <div className="attachment">
                                                         <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                                         <div className="attachment-name">{document.document_path.substring(document.document_path.length - 37)}</div>
                                                     </div>
                                                     )}
                                                 </div>
                                                 <div className="td">
                                                     <div className="tab-label v-mob">Status</div>
                                                     <div className="label">
                                                     {(document.status==="Processing")? document.status :""}
                                                     {(document.status==="Accepted") && (<div className="status-label accepted">
                                                             <i className="fi fi-verified-black"></i> Accepted
                                                         </div>)}
                                                         {(document.status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                         <div className="reason-text">Reason : {document.comments}.</div></>)} 
                                                     </div>
                                                 </div>
                                             </div>
                                    ))};
                                           
                                         
                                        </div>
                                    </div>
                                </div>
                            </section>







                            































































                            {(vendor.status==="Rejected" && (<div className="form-action">
                                {(btn_disable) ? ( <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                                />) :
                                <button className="submit-btn btn-solid" type="submit">Submit</button>
                                }
                            </div>))}
                        </form>
                         {((vendor.status === "Legal" || vendor.status === "Signed Contract Copy" ) && (
							<VendorContractCopy />
						 ))}
                         </>
                         )}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default VendorTrackingPage;
