export default function authHeader(file_flg=false) {
    const user = JSON.parse(localStorage.getItem('user'));
    const vendor = JSON.parse(localStorage.getItem('vendor'));

    if (user && user.token && typeof user.isOtpVerified === 'undefined') {
      if(file_flg){
        let headers = {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Authorization": 'Bearer ' + user.token 
        }
        return headers; // for Spring Boot back-end    
      }else{
        let headers = {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Authorization": 'Bearer ' + user.token 
          }
          return headers; // for Spring Boot back-end    
        }  
    }else if (vendor && vendor.token && typeof vendor.isOtpVerified !== 'undefined') {
      if(file_flg){
        let headers = {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          "Authorization": 'Bearer ' + vendor.token 
        }
        return headers; // for Spring Boot back-end    
      }else{
        let headers = {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Authorization": 'Bearer ' + vendor.token 
          }
          return headers; // for Spring Boot back-end    
        }  
    } else {
        let headers = {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
          }
      return headers;
    }
  }