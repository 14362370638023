import {getAxios,postAxios,toastMsg} from '../utils/ApiHelper';

class LegalService {
  getAllUsers(page, pageSize, searchQuery) {
    return getAxios(`legal/get-all?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getNewUsers(page, pageSize, searchQuery) {
    return getAxios(`legal/get-new-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getSubmittedUsers(page, pageSize, searchQuery) {
    return getAxios(`legal/get-submitted-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getContractCopyUsers(page, pageSize, searchQuery) {
    return getAxios(`legal/get-contract-copy-user?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getCompleteUsers(page, pageSize, searchQuery) {
    return getAxios(`legal/get-complete-user?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }


  getUserInfo(id) {
    return getAxios(`legal/get/${id}`)
      .then(response => {
        return response.data;
      });
  }

  updateUserDetails(id, payload) {
    return postAxios(`legal/update/${id}`, payload)
      .then(response => {
        return response.data;
      });
  }

  setUploadContractCopy(payload,file_flg) {
    return postAxios("legal/add", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }

  updateUploadContractCopy(payload,file_flg) {
    return postAxios("legal/update", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }

}

export default new LegalService();
