import React, { useState } from "react";
import SuperAdminService from '../../services/superAdmin.service';
import StaffModal from "./staffModal";
import Pagination from "../pagination";
import { format } from 'date-fns';

function StaffManagement(props) {

    const { staffData, currentPage, limit, totalPages, totalResults, handlePagination, handleSearch, searchText, fetchDataAndSetState } = props;

    const [activeRows, setActiveRows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [staff, setStaff] = useState(null);
    const [mode, setMode] = useState();
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(true);

    const openModal = async (mode, id) => {
        setStaff(null);
        setLoading(false);
        setMode(mode);
        setIsModalOpen(true);
        const statesData = await SuperAdminService.getStates();
        setStates(statesData);
        if (mode === 'edit') {
            setLoading(true);
            const staff = await SuperAdminService.getStaff(id);
            setStaff(staff);
            setLoading(false);
        }
    };

    const deactivateStaff = async (id) => {
        const deactivate = await SuperAdminService.deactivateStaff(id);
        if (deactivate.status === 'fail' || deactivate.status === 'error') {
            return false;
        }
        await fetchDataAndSetState();
    };

    const activateStaff = async (id) => {
        const activate = await SuperAdminService.activateStaff(id);
        if (activate.status === 'fail' || activate.status === 'error') {
            return false;
        }
        await fetchDataAndSetState();
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setStaff(null);
    };

    const toggleRow = (index) => {
        setActiveRows(activeRows.includes(index)
            ? activeRows.filter((i) => i !== index)
            : [...activeRows, index]
        );
    };

    return (
        <div>
            <div className="search-filter">
                <div className="search-form has-search-icon">
                    <input type="text" className="input-control" placeholder="Search" onChange={handleSearch} value={searchText}></input>
                    <span className="icon-left">
                        <i className="fi fi-search-black"></i>
                    </span>
                </div>
                <div className="add-staff-action">
                    <button className="add-staff-btn" type="button" onClick={() => openModal('add')}>Add Staff</button>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Role</th>
                            <th>Location</th>
                            <th>Active Since</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {staffData && staffData.rows && staffData.rows.map((staff, index) => (
                            <React.Fragment key={staff.id}>
                                <tr className={`tblflx ${activeRows.includes(index) ? "tr_active" : ""}`}>
                                    <td><a className="tr_toggle_action" onClick={() => toggleRow(index)}><i className="fi fi-chevron-right-black"></i></a></td>
                                    <td data-label="Name">{staff.firstname}</td>
                                    <td data-label="Email">{staff.email}</td>
                                    <td data-label="Phone">{staff.phoneno}</td>
                                    <td data-label="Role">{staff.role}</td>
                                    <td data-label="Location">{staff.city}</td>
                                    <td data-label="Active Since">{format(new Date(staff?.created_at), 'd MMMM yyyy')}</td>
                                    <td data-label="Status">{staff.is_active ? "Active" : "Inactive"}</td>
                                </tr>
                                <tr id={`tr_${staff.id}`} className="tr_toggle">
                                    <td colSpan="8">
                                        <div className="table-details">
                                            <div className="left-data">
                                                <div className="date">
                                                    <span className="label">Created On :</span>
                                                    <span className="val">{format(new Date(staff?.created_at), 'd MMMM yyyy H:mm a')}</span>
                                                </div>
                                                {/* <div className="date">
                                            <span className="label">Last Sign in :</span>
                                            <span className="val">28 January 2023 at 11:12 AM</span>
                                        </div> */}
                                            </div>
                                            <div className="right-data">
                                                <div className="action">
                                                    {staff.is_active ? (
                                                        <button className="deactive-btn" type="button" onClick={() => deactivateStaff(staff.id)}>Deactivate</button>
                                                    ) : null}
                                                    {staff.is_active === false ? (
                                                        <button className="deactive-btn" type="button" onClick={() => activateStaff(staff.id)}>Activate</button>
                                                    ) : null}
                                                    <button className="edit-btn" type="button" onClick={() => openModal('edit', staff.id)}>Edit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
            <StaffModal mode={mode} isModalOpen={isModalOpen} closeModal={closeModal} staff={staff} states={states} loading={loading} fetchDataAndSetState={fetchDataAndSetState}/>
            {staffData.rows && staffData.rows.length > 0 && (
                <Pagination
                    currentPage={currentPage}
                    limit={limit}
                    totalPages={totalPages}
                    totalResults={totalResults}
                    handlePagination={handlePagination}
                />
            )}
        </div>
    )
}
export default StaffManagement;
