import {postAxios,toastMsg} from '../utils/ApiHelper';

class AuthService {
  login(payload) {
    return postAxios("vendor/signin", payload)
      .then(response => {
        if (typeof response !== 'undefined' && typeof response.data !== 'undefined' && typeof response.data.token !== 'undefined' && response.data.token) {
          localStorage.setItem("vendor", JSON.stringify(response.data));
          if(response.data){
            toastMsg(response.message,'success');
          }
          return response.data;
        }
        return response;
      });
  }

  superAdminLogin(payload) {
    return postAxios("user/signin", payload)
      .then(response => {
        if (typeof response.data !== 'undefined' && typeof response.data.token !== 'undefined' && response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          if(response.data){
            toastMsg(response.message,'success');
          }
        }
        return response;
      });
  }

  logout() {
    localStorage.removeItem("user");
    toastMsg("Logout successfully",'success');
  }

  logoutVendor() {
    localStorage.removeItem("vendor");
    toastMsg("Logout successfully",'success');
  }

  register(payload) {
    return postAxios("vendor/signup", payload).then(response => {
      if (response.data) {
        toastMsg(response.message,'success');
        localStorage.setItem("vendor_email", response.data.email);
      }
      return response;
    });
  }

  otpVerification(payload) {
    return postAxios("vendor/verifyotp", payload).then(response => {
      if (response) {
        toastMsg(response.message,'success');
      }
      return response;
    });
  }

  resendOtp(payload) {
    return postAxios("vendor/resendotp", payload).then(response => {
      if (response) {
        toastMsg(response.message,'success');
      }
      return response;
    });
  }

  isLoggedInVendor() {
    let data= JSON.parse(localStorage.getItem('vendor'));
    if(data && typeof data.token !== 'undefined'){
      return true;
    }
    return false;
  }

  isLoggedInUser() {
    let data= JSON.parse(localStorage.getItem('user'));
    if(data && typeof data.token !== 'undefined'){
      return true;
    }
    return false;
  }
  
}

export default new AuthService();