import React,{useState,useEffect} from "react";
import VendorService  from '../services/vendor.service';
import { Link } from "react-router-dom";
import { RotatingTriangles,ColorRing } from 'react-loader-spinner';
import 'react-responsive-modal/styles.css';

function VendorDocComponent(props){
    const [vendor_doc, setDoc] = useState({});
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 6000;//10 sec 
    const [file_scanned_pan, setFileScannedPan] = useState({});
    const [file_scanned_aadhaar, setfileScannedAadhaar] = useState({});
    const [file_scanned_cc, setFileScannedCc] = useState({});
    const [file_scanned_resume, setFileScannedCv] = useState({});
    const [error_pan, setErrorPan] = useState({});
    const [error_aadhaar, setErrorAadhaar] = useState({});
    const [error_cc, setErrorCc] = useState({});
    const [error_resume, setErrorCv] = useState({});
    const [loading, setLoading] = useState(true);
    const initialState = { alt: "", src: "" };
    const [{ alt_pan, src_pan }, setPanPreview] = useState(initialState);
    const [{ alt_aadhaar, src_aadhaar }, setAadhaarPreview] = useState(initialState);
    const [{ alt_cc, src_cc }, setCCPreview] = useState(initialState);
    const [{ alt_resume, src_resume }, setCvPreview] = useState(initialState);

    const [cont_opening_form, setContOpeningForm] = useState({});
    const [account_statement, setAccountStatement] = useState({});
    const [gst_declaration_form, setGstDeclarationForm] = useState({});
    const [self_declaration_form, setSelfDeclarationForm] = useState({});
    const [pan_aadhar_undertaking, setPanAadharUndertaking] = useState({});
    const [error_cont_opening_form, setErrorContOpeningForm] = useState({});
    const [error_account_statement, setErrorAccountStatement] = useState({});
    const [error_gst_declaration_form, setErrorGstDeclarationForm] = useState({});
    const [error_pan_aadhar_undertaking, setErrorPanAadharUndertaking] = useState({});
    const [error_self_declaration_form, setErrorSelfDeclarationForm] = useState({});
    const [{ alt_cont_opening_form, src_cont_opening_form }, setContOpeningFormPreview] = useState(initialState);
    const [{ alt_account_statement, src_account_statement }, setAccountStatementPreview] = useState(initialState);
    const [{ alt_gst_declaration_form, src_gst_declaration_form }, setGstDeclarationFormPreview] = useState(initialState);
    const [{ alt_self_declaration_form, src_self_declaration_form }, setSelfDeclarationFormPreview] = useState(initialState);
    const [{ alt_pan_aadhar_undertaking, src_pan_aadhar_undertaking }, setPanAadharUndertakingPreview] = useState(initialState);
    const [modalFilename, setmodalFilename] = useState("");
    const  handleChangePan = (event) => {
        const valid=ImageValidation(event.target.files[0]);
        if(valid.status){
            setFileScannedPan(event.target.files[0]);
        }else{
            event.target.value = null;
        }
        setErrorPan(valid);
        
    }
    const handleChangeAadhaar = (event) => {
        const valid=ImageValidation(event.target.files[0]);
        if(valid.status){
            setfileScannedAadhaar(event.target.files[0]);
        }else{
            event.target.value = null;
        }
        setErrorAadhaar(valid);

    }
    const  handleChangeCC = (event) => {
        const valid=ImageValidation(event.target.files[0]);
        if(valid.status){
            setFileScannedCc(event.target.files[0])
        }else{
            event.target.value = null;
        }
        setErrorCc(valid);

    }
    const  handleChangeCv = (event) => {
        const valid=ImageValidation(event.target.files[0]);
        if(valid.status){
            setFileScannedCv(event.target.files[0])
        }else{
            event.target.value = null;
        }
        setErrorCv(valid);

    }

    const  handleChangeContOpeningForm = (event) => {
        const valid=ImageValidation(event.target.files[0],'xlsx');
        if(valid.status){
            setContOpeningForm(event.target.files[0]);
        }else{
            event.target.value = null;
        }
        setErrorContOpeningForm(valid);
        
    }
    const handleChangeAccountStatement = (event) => {
        const valid=ImageValidation(event.target.files[0],'pdf');
        if(valid.status){
            setAccountStatement(event.target.files[0]);
        }else{
            event.target.value = null;
        }
        setErrorAccountStatement(valid);

    }
    const  handleChangeGstDeclarationForm = (event) => {
        const valid=ImageValidation(event.target.files[0],'pdf');
        if(valid.status){
            setGstDeclarationForm(event.target.files[0])
        }else{
            event.target.value = null;
        }
        setErrorGstDeclarationForm(valid);

    }
    const  handleChangeSelfDeclarationForm = (event) => {
        const valid=ImageValidation(event.target.files[0],'pdf');
        if(valid.status){
            setSelfDeclarationForm(event.target.files[0])
        }else{
            event.target.value = null;
        }
        setErrorSelfDeclarationForm(valid);

    }

    const  handleChangePanAadharUndertaking = (event) => {
        const valid=ImageValidation(event.target.files[0],'pdf');
        if(valid.status){
            setPanAadharUndertaking(event.target.files[0])
        }else{
            event.target.value = null;
        }
        setErrorPanAadharUndertaking(valid);

    }
    const viewPDF = (data, type)=> {
        const win = window.open("","_blank","titlebar=yes,width = 600, height = 600");
        if (win) {
          const document = `<!DOCTYPE html>
          <html>
          <head>
              <title>PDF in HTML</title>
          </head>
          <body>
          <iframe src="https://docs.google.com/gview?url=${data}&embedded=true" 
style="width:600px; height:500px;" frameborder="0"></iframe>

              
          </body>
          </html>
          `;
          win.document.write(document);
        }
      }

    const onSubmit = async (event) => {
        event.preventDefault();
        const isValid= await FormValidation(event);
        if (isValid) {
            setBtnDisable(true);
            const formData = new FormData();
            formData.append('file_scanned_pan', file_scanned_pan);
            formData.append('file_scanned_aadhaar', file_scanned_aadhaar);
            formData.append('file_scanned_cc', file_scanned_cc);
            formData.append('file_scanned_resume', file_scanned_resume);
            formData.append('file_scanned_contributor_form', cont_opening_form);
            formData.append('file_scanned_bank_statement', account_statement);
            formData.append('file_scanned_gst_form', gst_declaration_form);
            formData.append('file_scanned_declaration_form', self_declaration_form);
            formData.append('file_scanned_pan_undertaking', pan_aadhar_undertaking);
            formData.append('document_flag',true);
            console.log(formData,"formData");
            // return false;
          async function setDocsVendor() {
            if (props.document_flag) {
                let data = await VendorService.updateDocumentsVendor(formData,true);
                if(!data){
                    return false;
                }
            }else{
                let data = await VendorService.setDocumentsVendor(formData,true);
                if(!data){
                    return false;
                }
            }
            setTimeout(() => {
                setBtnDisable(false);
                props.setToggleStateClid(4);
            }, MINUTE_MS);
           }
           setDocsVendor(); 
        }
      }

      const ImageValidation =(file,type='')=>{
            var filePath = file.name;
            // Allowing file type
            var allowedExtensions =  /(\.jpg|\.jpeg|\.pdf)$/i;
            if(type==='xlsx'){
                var allowedExtensions =  /(\.xlsx)$/i;
            }
            if(type==='pdf'){
                var allowedExtensions =  /(\.pdf)$/i;
            }
            if (!allowedExtensions.exec(filePath)) {
                return {status:false,"msg":"Invalid file type"};
            }else if (file.size < 4000 || file.size > 5000000) { // Size of document should be between 4KB and 5MB.
                return {status:false,"msg":"Size of document should be between 4KB and 5MB."};
            } 
            return {status:true,"msg":""};
      }

     const isImg = (path) =>{
        var image = new Image(); 
        image.src = path;
        if (image.width === 0) {
            return false;
        }
        return true;
      }

      const FormValidation =(e)=>{
        let isValid= true;
        if(props.document_flag){
            if (typeof file_scanned_pan.name === 'undefined' && typeof file_scanned_aadhaar.name === 'undefined' && typeof file_scanned_cc.name === 'undefined' && typeof file_scanned_resume.name === 'undefined' && typeof cont_opening_form.name === 'undefined' && typeof account_statement.name === 'undefined' && typeof gst_declaration_form.name === 'undefined' && typeof pan_aadhar_undertaking.name === 'undefined') {
                setErrorPan({status:false,"msg":"Please upload scanned pan card"});
                isValid=false;
            }
        }else{
            if (typeof file_scanned_pan.name === 'undefined') {
                setErrorPan({status:false,"msg":"Please upload scanned pan card"});
                isValid=false;
            }
            if (typeof file_scanned_aadhaar.name === 'undefined') {
                setErrorAadhaar({status:false,"msg":"Please upload scanned aadhaar card"});
                isValid=false;
            }
            if (typeof file_scanned_cc.name === 'undefined') {
                setErrorCc({status:false,"msg":"Please upload scanned cancelled cheque"});
                isValid=false;
            }
            if (typeof file_scanned_resume.name === 'undefined') {
                setErrorCv({status:false,"msg":"Please upload scanned reume"});
                isValid=false;
            }
            if (typeof cont_opening_form.name === 'undefined') {
                setErrorContOpeningForm({status:false,"msg":"Please upload contributor opening form"});
                isValid=false;
            }
            if (typeof account_statement.name === 'undefined') {
                setErrorAccountStatement({status:false,"msg":"Please upload bank account statement"});
                isValid=false;
            }
            if (typeof gst_declaration_form.name === 'undefined') {
                setErrorGstDeclarationForm({status:false,"msg":"Please upload GST declaration form"});
                isValid=false;
            }
            if (typeof self_declaration_form.name === 'undefined') {
                setErrorSelfDeclarationForm({status:false,"msg":"Please upload self declaration form"});
                isValid=false;
            }
            if (typeof pan_aadhar_undertaking.name === 'undefined') {
                setErrorPanAadharUndertaking({status:false,"msg":"Please upload pan aadhar undertaking"});
                isValid=false;
            }
        }
        return isValid;
      }
      useEffect(() => {
        if(props.vendordocuments){
            setDoc(props.vendordocuments);
            props.vendordocuments.map(function(val, index){
                if(val.document_type==="pan card"){
                    setPanPreview(
                        {
                          src_pan:val.document_path,
                          alt_pan:val.document_name
                        }
                  );
                }
                if(val.document_type==="aadhaar card"){
                    setAadhaarPreview(
                        {
                          src_aadhaar:val.document_path,
                          alt_aadhaar:val.document_name
                        }
                  );
                }
                if(val.document_type==="cancelled cheque"){
                    setCCPreview(
                        {
                          src_cc:val.document_path,
                          alt_cc:val.document_name
                        }
                  );
                }
                if(val.document_type==="resume"){
                    setCvPreview(
                        {
                          src_resume:val.document_path,
                          alt_resume:val.document_name
                        }
                  );
                }
                if(val.document_type==="contributor_form"){
                    setContOpeningFormPreview(
                        {
                          src_cont_opening_form:val.document_path,
                          alt_cont_opening_form:val.document_name
                        }
                  );
                }
                if(val.document_type==="bank_statement"){
                    setAccountStatementPreview(
                        {
                          src_account_statement:val.document_path,
                          alt_account_statement:val.document_name
                        }
                  );
                }
                if(val.document_type==="gst_form"){
                    setGstDeclarationFormPreview(
                        {
                          src_gst_declaration_form:val.document_path,
                          alt_gst_declaration_form:val.document_name
                        }
                  );
                }
                if(val.document_type==="declaration_form"){
                    setSelfDeclarationFormPreview(
                        {
                          src_self_declaration_form:val.document_path,
                          alt_self_declaration_form:val.document_name
                        }
                  );
                }
                if(val.document_type==="pan_aadhar_undertaking"){
                    setPanAadharUndertakingPreview(
                        {
                          src_pan_aadhar_undertaking:val.document_path,
                          alt_pan_aadhar_undertaking:val.document_name
                        }
                  );
                }
                return true;
            })
            
             setLoading(false);
        }     
       },[props.vendordocuments]);

    return(
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : vendor_doc && ( 
         <form className="contributor-form doc-form" onSubmit={onSubmit} method="post" encType="multipart/form-data">
            <div className="contributor-form doc-section">
                <div className="form-col-2 form-horizontal">
                    <div className="help-text v-mob">Note: Documents should be in *.jpeg , *.jpg or *.pdf format. Size of document should be between 4KB and 2MB.</div>
                    <div className="form-cap-card">
                        <div className="form-caption">Documents Upload</div>
                        <div className="document-list">
                            <table className="doc-table">
                                <thead>
                                    <tr>
                                        <th>Proof Name</th>
                                        {/* <th>Document Name</th> */}
                                        <th>Attach File</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">Scanned Pan Card <span className="asterisk">*</span></div></td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">Scanned Pan Card</div>
                                        </td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_pan)) && (
                                                            <>
                                                                <div className="image-preview">
                                                                    <img src={src_pan} alt={alt_pan}/>
                                                                </div>
                                                                <div className="file-name">{alt_pan}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_pan)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_pan,'application/pdf')}>{alt_pan}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="file_scanned_pan"  name="file_scanned_pan"  onChange={handleChangePan}  accept="image/jpg, image/jpeg, .pdf"  />
                                                    <label className="error"> {error_pan ? error_pan.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_pan_name">No file chosen</span> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">SCANNED AADHAAR CARD <span className="asterisk">*</span></div></td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">SCANNED AADHAAR CARD</div></td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_aadhaar)) && (
                                                            <>
                                                                <div className="image-preview">
                                                                    <img src={src_aadhaar} alt={alt_aadhaar}/>
                                                                </div>
                                                                <div className="file-name"> {alt_aadhaar}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_aadhaar)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_aadhaar,'application/pdf')}>{alt_aadhaar}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="file_scanned_aadhaar" name="file_scanned_aadhaar" onChange={handleChangeAadhaar}  accept="image/jpg, image/jpeg, .pdf"   />
                                                    <label className="error"> {error_aadhaar ? error_aadhaar.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_aadhaar_name">No file chosen</span> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">CANCELLED CHEQUE <span className="asterisk">*</span></div>
                                        </td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">CANCELLED CHEQUE</div>
                                        </td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_cc)) && (   
                                                            <>
                                                                <div className="image-preview"> 
                                                                    <img src={src_cc} alt={alt_cc}/>
                                                                </div>
                                                                <div className="file-name"> {alt_cc}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_cc)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_cc,'application/pdf')}>{alt_cc}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="file_scanned_cc"  name="file_scanned_cc"  onChange={handleChangeCC}  accept="image/jpg, image/jpeg, .pdf" />
                                                    <label className="error"> {error_cc ? error_cc.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_cc_name">No file chosen</span> */}
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">Resume / CV
                                            <span className="asterisk">*</span></div>
                                        </td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">CANCELLED CHEQUE</div>
                                        </td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_resume)) && (   
                                                            <>
                                                                <div className="image-preview"> 
                                                                    <img src={src_resume} alt={alt_resume}/>
                                                                </div>
                                                                <div className="file-name"> {alt_resume}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_resume)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_resume,'application/pdf')}>{alt_resume}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="file_scanned_resume"  name="file_scanned_resume"  onChange={handleChangeCv}  accept="image/jpg, image/jpeg, .pdf" />
                                                    <label className="error"> {error_resume ? error_resume.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_cc_name">No file chosen</span> */}
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">Contributor Opening Form <span className="asterisk">*</span></div>
                                            (Only Xlsx)
                                        </td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">Scanned Pan Card</div>
                                        </td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <Link class="choose-file-btn" to="/files/Contributor-Opening-Form-Format.xlsx" target="_blank" download>Download</Link>
                                            <br></br>
                                            <br></br>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_cont_opening_form)) && (
                                                            <>
                                                                <div className="image-preview">
                                                                    <img src={src_cont_opening_form} alt={alt_cont_opening_form}/>
                                                                </div>
                                                                <div className="file-name">{alt_cont_opening_form}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_cont_opening_form)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_cont_opening_form,'application/pdf')}>{alt_cont_opening_form}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="cont_opening_form"  name="cont_opening_form"  onChange={handleChangeContOpeningForm}  accept=".xlsx"  />
                                                    <label className="error"> {error_cont_opening_form ? error_cont_opening_form.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_pan_name">No file chosen</span> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">Bank Account Statement <span className="asterisk">*</span></div>
                                            (Only Pdf)</td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">SCANNED AADHAAR CARD</div></td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_account_statement)) && (
                                                            <>
                                                                <div className="image-preview">
                                                                    <img src={src_account_statement} alt={alt_account_statement}/>
                                                                </div>
                                                                <div className="file-name"> {alt_account_statement}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_account_statement)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_account_statement,'application/pdf')}>{alt_account_statement}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="account_statement" name="account_statement" onChange={handleChangeAccountStatement}  accept=".pdf"   />
                                                    <label className="error"> {error_account_statement ? error_account_statement.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_aadhaar_name">No file chosen</span> */}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">GST Declaration Form <span className="asterisk">*</span></div>
                                            (Only Pdf)
                                        </td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">CANCELLED CHEQUE</div>
                                        </td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <Link class="choose-file-btn" to="/files/Non-GST-Declaration-Format.docx" target="_blank" download>Download</Link>
                                            <br></br>
                                            <br></br>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_gst_declaration_form)) && (   
                                                            <>
                                                                <div className="image-preview"> 
                                                                    <img src={src_gst_declaration_form} alt={alt_gst_declaration_form}/>
                                                                </div>
                                                                <div className="file-name"> {alt_gst_declaration_form}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_gst_declaration_form)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_gst_declaration_form,'application/pdf')}>{alt_gst_declaration_form}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="gst_declaration_form"  name="gst_declaration_form"  onChange={handleChangeGstDeclarationForm}  accept=".pdf" />
                                                    <label className="error"> {error_gst_declaration_form ? error_gst_declaration_form.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_cc_name">No file chosen</span> */}
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">Self Declaration Form
                                            <span className="asterisk">*</span></div>
                                            (Only Pdf)
                                        </td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">CANCELLED CHEQUE</div>
                                        </td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <Link class="choose-file-btn" to="/files/Self-Declration-form.doc" target="_blank" download>Download</Link>
                                            <br></br>
                                            <br></br>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_self_declaration_form)) && (   
                                                            <>
                                                                <div className="image-preview"> 
                                                                    <img src={src_self_declaration_form} alt={alt_self_declaration_form}/>
                                                                </div>
                                                                <div className="file-name"> {alt_self_declaration_form}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_self_declaration_form)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_self_declaration_form,'application/pdf')}>{alt_self_declaration_form}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="self_declaration_form"  name="self_declaration_form"  onChange={handleChangeSelfDeclarationForm}  accept=".pdf" />
                                                    <label className="error"> {error_self_declaration_form ? error_self_declaration_form.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_cc_name">No file chosen</span> */}
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="tab-label v-mob">Proof Name</div>
                                            <div className="text-upper">Pan Aadhar Undertaking
                                            <span className="asterisk">*</span></div>
                                            (Only Pdf)
                                        </td>
                                        {/* <td>
                                            <div className="tab-label v-mob">Document Name</div>
                                            <div className="text-upper">CANCELLED CHEQUE</div>
                                        </td> */}
                                        <td>
                                            <div className="tab-label v-mob">Attach File</div>
                                            <Link class="choose-file-btn" to="/files/Pan-Aadhar-Undertaking-Format.docx" target="_blank" download>Download</Link>
                                            <br></br>
                                            <br></br>
                                            <div className="upload-wrapper">
                                                <div className="attach-file-wrp">
                                                    <div className="image-preview-wrp">
                                                        {(isImg(src_pan_aadhar_undertaking)) && (   
                                                            <>
                                                                <div className="image-preview"> 
                                                                    <img src={src_pan_aadhar_undertaking} alt={alt_pan_aadhar_undertaking}/>
                                                                </div>
                                                                <div className="file-name"> {alt_pan_aadhar_undertaking}</div>
                                                            </>
                                                        )}
                                                        {(!isImg(src_pan_aadhar_undertaking)) && (
                                                            <div className="file-name" onClick={()=> viewPDF(src_pan_aadhar_undertaking,'application/pdf')}>{alt_pan_aadhar_undertaking}</div>
                                                        )}
                                                    </div>
                                                    <br></br>
                                                    <br></br>
                                                    <input type="file" id="pan_aadhar_undertaking"  name="pan_aadhar_undertaking"  onChange={handleChangePanAadharUndertaking}  accept=".pdf" />
                                                    <label className="error"> {error_pan_aadhar_undertaking ? error_pan_aadhar_undertaking.msg : ""} </label>
                                                    {/* <span className="file-text" id="file_cc_name">No file chosen</span> */}
                                                    
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="help-text h-mob">Note: Documents should be in *.jpeg , *.jpg or *.pdf format. Size of document should be between 4KB and 5MB.</div>
                    </div>
                    
                </div>
                {(!props.final_flag) && (
                    <div className="form-action">
                        {(btn_disable) ? ( <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                                />) : (<button className="submit-btn" type="submit">Upload</button>)}
                </div>
                )} 
            </div>
        </form>
        
        
          )
    )
}
export default VendorDocComponent;
