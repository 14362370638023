import React, { useState, useEffect } from "react";
import '../../css/signUpPage.css';
import { useNavigate } from "react-router-dom";
import CreativeLogoComponent from '../../components/creativeLogoComponent';
import { useFormInputValidation } from "react-form-input-validation";
import AuthService from '../../services/auth.service';
import { RotatingTriangles } from 'react-loader-spinner';
import { ColorRing } from 'react-loader-spinner'

function AccountLogin() {
    const navigate = useNavigate();
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 10000;//10 sec 
    const [loading, setLoading] = useState(true);
    const [passwordType, setPasswordType] = useState("password");
    const [fields, errors, form] = useFormInputValidation({
        email_address: "",
        password: "",
    }, {
        email_address: "required|email",
        password: "required"
    });

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            setBtnDisable(true);
            let payload = { "email": fields.email_address, "password": fields.password, "role": "Account" }
            async function Login() {
                let response = await AuthService.superAdminLogin(payload);
                if (response?.status === "success") {
                    navigate(process.env.PUBLIC_URL + `/account-all-listing`);
                }


            }
            setTimeout(() => {
                setBtnDisable(false);
            }, MINUTE_MS);
            Login();
        }
    }

    useEffect(() => {
        if (AuthService.isLoggedInUser()) {
            navigate(process.env.PUBLIC_URL + "/account-all-listing");
        }
        setLoading(false);
    }, [navigate]);

    return (
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
        />) : (
            <div className="login-page">
                <div className="auth-wrapper">
                    <div className="mxw-container">
                        <CreativeLogoComponent />
                        <div className="auth-form-wpr">
                            <div className="form-w">
                                <div className="form-card">
                                    <div className="greeting">
                                        <h2>Welcome</h2>
                                        <h3>Account Sign in</h3>
                                    </div>
                                    <form className="myForm" noValidate autoComplete="off" onSubmit={onSubmit}>
                                        <div className="fg">
                                            <input id="emailPhoneNumber" placeholder="Email" className="fc" type="email" name="email_address" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.email_address} />
                                            <label className="error"> {errors.email_address ? errors.email_address : ""} </label>
                                        </div>
                                        <div className="fg">
                                            <div className="pwd-wpr">
                                                <input type={passwordType} id="password" placeholder="Password" className="fc" name="password" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password} />
                                                <span className="pi-wpr" ><i className="fi fi-visibility-black-o" onClick={togglePassword}></i></span>
                                            </div>
                                            <label className="error"> {errors.password ? errors.password : ""} </label>
                                        </div>
                                        <div className="fg fg-has-check" style={{ display: 'none' }}>
                                            <div className="cf-wrap">
                                                <div className="custom-check">
                                                    <input id="remember-me" name="remember" className="form-check-input" type="checkbox" />
                                                    <label className="form-check-label" >Remember Me</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fg form-action">
                                            {(btn_disable) ? (<ColorRing
                                                visible={true}
                                                height="80"
                                                width="80"
                                                ariaLabel="blocks-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="blocks-wrapper"
                                                colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                                            />) : (<button type="submit" className="submit-btn">Sign In</button>)}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)

    )
}
export default AccountLogin;
