import { getAdminAxios, postAdminAxios, toastMsg, postSuperAdminAxios, getSuperAdminAxios } from '../../utils/EventApiHelper';

class AdminService {
  getAllEvents(page, pageSize, searchQuery) {
    return getAdminAxios(`admin-event/getAll?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getAllEventsUser(page, pageSize, searchQuery,id) {
    return getAdminAxios(`admin-event/getAllEventUser/${id}?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
    }

  getEvents(id) {
    return getAdminAxios(`admin-event/get/${id}`)
      .then(response => {
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    toastMsg("Logout successfully", 'success');
  }

  deleteEvent(payload) {
    return postAdminAxios(`admin-event/delete`, payload)
    .then(response => {
      if(response){
        toastMsg(response.message,'success');
      }
      return response;
    });
  }

  setEventDetails(payload) {
    return postAdminAxios("admin-event/add", payload)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }

  getProperty() {
    return getAdminAxios(`admin-event/getProperty`)
      .then(response => {
        return response.data;
      });
  }

  getExcelUser(id) {
    return getAdminAxios(`admin-event/getAllUserswithExcel/${id}`)
      .then(response => {
        return response;
      });
  }


  setUserDetails(payload){
    return postSuperAdminAxios("sadmin-event/add", payload)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }

  getAllUsers(page, pageSize, searchQuery) {
    return getSuperAdminAxios(`sadmin-event/getAll?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
    }

    deleteUser(payload) {
      return postSuperAdminAxios(`sadmin-event/delete`, payload)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
    }

    getUser(id) {
      return getSuperAdminAxios(`sadmin-event/get/${id}`)
        .then(response => {
          return response.data;
        });
    }
}

export default new AdminService();