import homePage from './pages/homePage';
import AboutUsPage from './pages/aboutUs';
import signUpPage from './pages/signUpPage';
import LoginPage from './pages/loginPage';
import VerifyOtpPage from './pages/verifyOtpPage';
import VendorDashboardPage from './pages/vendorDashboardPage';
import VendorTrackingPage from './pages/vendorTrackingPage';
import VendorTrackingPage2 from './pages/VendorTrackingPage2';
import VendorTrackingPage3 from './pages/VendorTrackingPage3';

import VendorContractCopyPage from './pages/vendorContractCopyPage';
import NotFound from './pages/NotFound';
import ReportersList from './pages/reportersList';
import AccountLogin from "./pages/account/loginPage";
import AccountReporterDetails from "./pages/account/AccountReporterDetails";
import SuperAdminLogin from './pages/superAdmin/login';
import SuperAdminDashboard from './pages/superAdmin/dashboard';
import ManagementLoginPage from './pages/management/login';
import ManagementDashboard from './pages/management/dashboard';
import ManagementDetails from './pages/management/managementDetails';
import LegalLoginPage from './pages/legal/login';
import LegalDashboard from './pages/legal/dashboard';
import ManagementAllListing from './pages/management/allListing';
import ManagementContractCopyListing from './pages/management/contractCopyListing';
import ManagementCompletedListing from './pages/management/completedListing';
import ManagementNewListing from './pages/management/newListing';
import ManagementSubmittedListing from './pages/management/submittedListing';
import ManagementApprovedListing from './pages/management/approvedListing';
import vendorUploadContract from './pages/legal/vendorUploadContract';
import LegalAllListing from './pages/legal/allListing';
import LegalNewListing from './pages/legal/newListing';
import LegalSubmittedListing from './pages/legal/submittedListing';
import SubmittedContractCopyListing from './pages/legal/submittedContractCopyListing';
import ContractCopyListing from './pages/legal/contractCopyListing';
import CompleteListing from './pages/legal/completeListing';
import AccountAllListing from './pages/account/allListing';
import AccountNewListing from './pages/account/newListing';
import AcoountSubmittedListing from './pages/account/submittedListing';
import AccountCompleteListing from './pages/account/completeListing';

import ManagementApproveCopy from './pages/management/approveContractCopy';

import AdminLogin from "./pages/admin/loginPage";
import AdminAllListing from './pages/admin/allListing';
import AdminNewListing from './pages/admin/newListing';
import AdminApprovedListing from './pages/admin/approvedListing';
import AdminRejectedListing from './pages/admin/rejectedListing';
import AdminCompleteListing from './pages/admin/completeListing';
import AdminReporterDetails from "./pages/admin/ReporterDetails";
import AdminReporterDetails2 from "./pages/admin/AdminReportersList2";

import VendorSignup from "./pages/admin/SignUpPage";

import EventLoginPage from './pages/event/loginPage';
import EventListingData from './pages/event/eventResult';
import EventAdd from './pages/event/eventAdd';
import EventEdit from './pages/event/eventEdit';
import EventUserList from './pages/event/eventUserList';
import UserAdd from './pages/event/userAdd';
import UserList from './pages/event/userList';
import SadminLogin from './pages/event/sadminLoginPage';
import UserEdit from './pages/event/userEdit';
const routes = [
  {
    path: '/'+process.env.PUBLIC_URL,
    component: homePage,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/reporters-list',
    component: ReportersList,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-dashboard',
    component: VendorDashboardPage,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-login',
    component: LoginPage,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-tracking',
    component: VendorTrackingPage3,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-tracking-2',
    component: VendorTrackingPage2,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-tracking-3',
    component: VendorTrackingPage3,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-contract-copy',
    component: VendorContractCopyPage,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/account-login',
    component: AccountLogin,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/account-details/:id',
    component: AccountReporterDetails,
    exact: true
  },
  {
    path: '*',
    component: NotFound,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/super-admin-login',
    component: SuperAdminLogin,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/super-admin-dashboard',
    component: SuperAdminDashboard,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-login',
    component: ManagementLoginPage,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-dashboard',
    component: ManagementDashboard,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-all-listing',
    component: ManagementAllListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-contract-listing',
    component: ManagementContractCopyListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-completed-listing',
    component: ManagementCompletedListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-approved-listing',
    component: ManagementApprovedListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-new-listing',
    component: ManagementNewListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-submitted-listing',
    component: ManagementSubmittedListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-details/:id',
    component: ManagementDetails,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/management-approve-copy/:id',
    component: ManagementApproveCopy,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-login',
    component: LegalLoginPage,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-dashboard',
    component: LegalDashboard,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-upload-contract/:id',
    component: vendorUploadContract,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-all-listing',
    component: LegalAllListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-new-listing',
    component: LegalNewListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-listing',
    component: LegalSubmittedListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-contract-copy-listing',
    component: ContractCopyListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-contract-copy/:id',
    component: SubmittedContractCopyListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/legal-complete-listing',
    component: CompleteListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/account-all-listing',
    component: AccountAllListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/account-new-listing',
    component: AccountNewListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/account-submitted-listing',
    component: AcoountSubmittedListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/account-complete-listing',
    component: AccountCompleteListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-login',
    component: AdminLogin,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-all-listing',
    component: AdminAllListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-new-listing',
    component: AdminNewListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-approved-listing',
    component: AdminApprovedListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-rejected-listing',
    component: AdminRejectedListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-complete-listing',
    component: AdminCompleteListing,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-details/:id',
    component: AdminReporterDetails,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/admin-details2/:id',
    component: AdminReporterDetails2,
    exact: true
  },
  {
    path: process.env.PUBLIC_URL+'/vendor-signup',
    component: VendorSignup,
    exact: true
  },
  {
    path: '/event-admin-login',
    component: EventLoginPage,
    exact: true
  },
  {
    path: '/event-list',
    component: EventListingData,
    exact: true
  },
  {
    path: '/event-user-list/:id',
    component: EventUserList,
    exact: true
  },
  {
    path: '/event-add',
    component: EventAdd,
    exact: true
  },
  {
    path: '/edit-events/:id',
    component: EventEdit,
    exact: true
  },
  {
    path: '/event-super-admin-login',
    component: SadminLogin,
    exact: true
  },
  {
    path: '/user-list',
    component: UserList,
    exact: true
  },
  {
    path: '/user-add',
    component: UserAdd,
    exact: true
  },
  {
    path: '/user-edit/:id',
    component: UserEdit,
    exact: true
  },
 // and so on for other routes in your project
]

export default routes;