import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import '../css/file18.css';
import VendorService  from '../services/vendor.service';
import AuthService  from '../services/auth.service';
import Sidebar from '../components/sidebarComponent';
import VendorBasicDetails from '../components/vendorBasicDetailsComponent';
import VendorAddress from '../components/vendorAddressComponent';
import VendorDeclaration from '../components/vendorDeclarationComponent';
import VendorDoc from '../components/vendorDocComponent';


function VendorDashboardPage(){
    const navigate = useNavigate();
    const [ vendor, setVendor ] = useState([]);
    const [toggleState, setToggleState] = useState(1)
    const toggleTab = (index) => {
        setToggleState(index);
    }

    const setToggleStateClid=(index)=>{
        console.log(index,"index");
        setToggleState(index);
        console.log(toggleState,"toggleState");
        async function getVendor() {
            let data = await VendorService.getCurrentVendor();
                if(data){
                    setVendor(data); 
                }else{
                    return false;
                }   
           }
           getVendor();
    }
    useEffect(() => {
        if(!AuthService.isLoggedInVendor()){
            navigate(process.env.PUBLIC_URL+"/vendor-login");
        }else{
            getVendor();
        }
       async function getVendor() {
        let data = await VendorService.getCurrentVendor();
            if(data){
                setVendor(data);
                if(data.final_flag){
                    setToggleState(1);
                }else if(data.document_flag){
                    setToggleState(4);
                }else if(data.address_flag){
                    setToggleState(3);
                }else if(data.info_flag){
                    setToggleState(2);
                } 
            }else{
                return false;
            }   
       }
      }, []);

    return(
        <div className="page-wrapper">
        <Sidebar sidebarFor="Vendor"  final_form_flag={vendor.final_flag}  />
        <div className="content-wrapper">
            <div className="page-content page-reporters">
                <div className="page-header">
                    <h4>Contributor Opening Form</h4>
                </div>
                <div className="card">
                    <div className="card-head">
                        <div className="tab-list">
                            <button className={toggleState === 1 ? 'tablink tab-active' : "tablink"} onClick={() => toggleTab(1)}>Basic Details</button>
                            <button disabled={(vendor.info_flag) ? "" : "disabled" }  className={toggleState === 2 ? 'tablink tab-active' : "tablink"} onClick={() => toggleTab(2)}>Address</button>
                            <button disabled={(vendor.info_flag && vendor.address_flag) ? "" : "disabled" }  className={toggleState === 3 ? 'tablink tab-active' : "tablink"} onClick={() => toggleTab(3)}>Doc</button>
                            <button disabled={(vendor.info_flag && vendor.address_flag && vendor.document_flag) ? "" : "disabled" } className={toggleState === 4 ? 'tablink tab-active' : "tablink"} onClick={() => toggleTab(4)}>Declaration</button>
                        </div>
                    </div>
                    <div id="basic-details" className={toggleState === 1 ? 'tab-listcontent contributor-basic-details active-content' : "tab-listcontent contributor-basic-details"}>
                        <div className="card-body">
                            <VendorBasicDetails final_flag={(vendor.final_flag) ? true : false } setToggleStateClid={setToggleStateClid} info_flag={(vendor.info_flag) ? true : false } vendorinfo={(typeof  vendor !== 'undefined' && typeof  vendor.vendor_info !== 'undefined')? vendor.vendor_info : null}/>
                        </div>
                    </div>
                    <div id="vndor-address" className={toggleState === 2 ? 'tab-listcontent active-content' : "tab-listcontent"}>
                        <div className="card-body">
                            <VendorAddress final_flag={(vendor.final_flag) ? true : false } setToggleStateClid={setToggleStateClid} address_flag={(vendor.address_flag) ? true : false } vendoraddress={(typeof  vendor !== 'undefined' && typeof  vendor.vendor_address !== 'undefined')? vendor.vendor_address : null} />
                        </div>
                    </div>
                    <div id="vendor-doc"   className={toggleState === 3 ? 'tab-listcontent active-content' : "tab-listcontent"}> 
                        <div className="card-body">
                           <VendorDoc final_flag={(vendor.final_flag) ? true : false } setToggleStateClid={setToggleStateClid} document_flag={(vendor.document_flag) ? true : false } vendordocuments={(typeof  vendor !== 'undefined' && typeof  vendor.vendor_documents !== 'undefined')? vendor.vendor_documents : null} />
                        </div>
                    </div>
                    <div id="vendor-declaration" className={toggleState === 4 ? 'tab-listcontent active-content' : "tab-listcontent"}>
                        <div className="card-body">
                            <VendorDeclaration setToggleStateClid={setToggleStateClid} final_flag={(vendor.final_flag) ? true : false } vendor={vendor}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default VendorDashboardPage;
