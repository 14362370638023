import RouterProvider from './utils/RouterProvider'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
        <>
          <RouterProvider/>
          <ToastContainer />
         </>
  );
}

export default App;
