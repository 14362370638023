import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "../pagination";
import { format } from 'date-fns';

function ManagementList(props) {

    const { managementData, currentPage, limit, totalPages, totalResults, handlePagination, handleSearch, pageType } = props;
    //console.log(managementData.rows[0].vendor_address);
    const [activeRows, setActiveRows] = useState([]);

    const toggleRow = (index) => {
      setActiveRows(activeRows.includes(index) 
        ? activeRows.filter((i) => i !== index) 
        : [...activeRows, index]
      );
    };

    return (
        <div>
            <div className="search-filter">
                <div className="search-form has-search-icon">
                    <input type="text" className="input-control" placeholder="Search" onChange={handleSearch}></input>
                    <span className="icon-left">
                        <i className="fi fi-search-black"></i>
                    </span>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>District</th>
                            <th>Submitted On</th>
                            <th>Status</th>
                            <th className="action">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {managementData && managementData.rows && managementData.rows.map((users, index) => (
                        <>
                        <tr className={`tblflx ${activeRows.includes(index) ? "tr_active" : ""}`}>
                            <td><a className="tr_toggle_action" onClick={() => toggleRow(index)}><i className="fi fi-chevron-right-black"></i></a></td>
                            <td data-label="Name">{users?.vendor_info?.firstname} {users?.vendor_info?.lastname}</td>
                            <td data-label="Email">{users?.email}</td>
                            <td data-label="District">{users?.vendor_address?.district}</td>
                            <td data-label="Submitted On">{format(new Date(users?.created_at), 'd MMMM yyyy')}</td>
                            <td data-label="Status">{users?.status}</td>  
                            <td data-label="Action" className="action">
                            {/* {pageType === 'Contract_Listing' ? (
                                <Link
                                to={`${process.env.PUBLIC_URL}/management-approve-copy/${users?.id}`}
                                className="action-btn"
                                >
                                    <i className="fi fi-arrow-left-short"></i>
                                </Link>
                            ) :  */}
                                <Link
                                    to={`${process.env.PUBLIC_URL}/management-details/${users?.id}`}
                                    className="action-btn"
                                    >
                                        <i className="fi fi-arrow-left-short"></i>
                                </Link>
                            {/* } */}                                
                            </td>                          
                        </tr>                        
                        </>
                         ))}
                    </tbody>
                </table>
            </div>
            {managementData.rows && managementData.rows.length > 0 && (
            <Pagination 
            currentPage={currentPage}
            limit={limit}
            totalPages={totalPages}
            totalResults={totalResults}
            handlePagination={handlePagination}
            />
            )}
        </div>
    )
}
export default ManagementList;
