import {getAxios,postAxios,toastMsg} from '../utils/ApiHelper';

class ManagementService {
  getAllUsers(page, pageSize, searchQuery) {
    return getAxios(`management/get-all?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getNewUsers(page, pageSize, searchQuery) {
    return getAxios(`management/get-new-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getSubmittedUsers(page, pageSize, searchQuery) {
    return getAxios(`management/get-submitted-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getCompletedUsers(page, pageSize, searchQuery) {
    return getAxios(`management/get-completed-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getApprovedUsers(page, pageSize, searchQuery) {
    return getAxios(`management/get-approved-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getContractUsers(page, pageSize, searchQuery) {
    return getAxios(`management/get-contract-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getUserInfo(id) {
    return getAxios(`management/get/${id}`)
      .then(response => {
        return response.data;
      });
  }

  updateUserDetails(id, payload) {
    return postAxios(`management/update/${id}`, payload)
      .then(response => {
        if(response.status === 'success'){
          toastMsg(response.message,'success');
        }
        return response.data;
      });
  }

  updateContractCopyDetails(id, payload) {
    return postAxios(`management/update-contract-copy/${id}`, payload)
      .then(response => {
        if(response.status === 'success'){
          toastMsg(response.message,'success');
        }
        return response.data;
      });
  }
}

export default new ManagementService();
