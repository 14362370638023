import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import { useNavigate, useParams } from "react-router-dom";
import { useFormInputValidation } from "react-form-input-validation";
import { RotatingTriangles } from 'react-loader-spinner'
import Sidebar from '../../components/sidebarComponent';
import ManagementApproveCopy from '../../components/management/approveContractCopy';
import ManagementService from '../../services/managment-service';
import { ColorRing } from 'react-loader-spinner';
import { format } from 'date-fns';
import { Modal } from 'react-responsive-modal';
import Previewdoc from '../../components/management/Previewdoc';

function ManagementDetails(){
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [managementData, setManagementData] = useState([]);
    let photograph = '';
    let finaldate = '';
    let pan_card = '';
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 10000;//10 sec;
    const [modalFilename, setmodalFilename] = useState("");
    const [open, setOpen] = useState(false);
    
    const [fields, errors, form] = useFormInputValidation({
        joining_date: "",
        pan_no:"",
        contract_duration: "",
        designation: "",
        salary: "",
        comments: ""
    }, {
        joining_date: "required",
        pan_no: "required|alpha_num|max:10|min:10",
        contract_duration: "required",
        designation: "required",
        salary: "required|numeric",
        comments: "required",
    });

    const onOpenModal = (filename) => {
        setmodalFilename(filename);
        setOpen(true);

    };
    const onCloseModal = () => {
        setOpen(false);
        setmodalFilename("");
    };

    const viewPDF = (data, type) => {
        const win = window.open("", "_blank", "titlebar=yes,width = 600, height = 600");
        if (win) {
            const document = `<!DOCTYPE html>
            <html>
            <head>
                <title>PDF in HTML</title>
            </head>
            <body>
            <iframe src="https://docs.google.com/gview?url=${data}&embedded=true" 
            style="width:600px; height:500px;" frameborder="0"></iframe>

                
            </body>
            </html>
            `;
            win.document.write(document);
        }   
    }
    //Set current date in the datepicker
    /*let dateNow = new Date();

    let month = dateNow.getMonth() + 1;
    let day = dateNow.getDate();
    let year = dateNow.getFullYear();

    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
    
    let current_date = `${year}-${month}-${day}`;*/

    //Extract only photograph from the documents data
    {managementData && managementData.vendor_documents && managementData.vendor_documents.map((documents, index) => {
        if(documents.document_type === 'photograph') {
            photograph = documents.document_path
        }
        if (documents.document_type === 'pan card') {
            const extension = documents.document_path.split('.').pop()
            const filename = documents.document_path.substring(documents.document_path.lastIndexOf('/') + 1);
            if (extension === 'pdf') {
              pan_card = (
                <div>
                  <div>
                    <div className="file-name" onClick={() => viewPDF(documents.document_path, 'application/pdf')}>Preview Pan Card</div>    
                  </div>
                </div>
              );
            } else {
              pan_card = (
                <div>
                    <img onClick={() => onOpenModal(documents.document_path)} src={documents.document_path} alt="" style={{ height: "94px", width: "132px" }} />
                </div>              
              );
            }
        }
    })}

    const fetchData = async () => {
        const result = await ManagementService.getUserInfo(id);
        console.log(result);
        setManagementData(result || []);
        
        if(result){
            if(result?.vendor_info?.joining_date){
                let joindate = new Date(result?.vendor_info?.joining_date);
                finaldate = joindate.toISOString().substring(0, 10);
            }   
            fields.joining_date=finaldate;
            fields.pan_no=result?.vendor_info?.pan_no;
            fields.contract_duration=result?.vendor_info?.contract_duration;
            fields.designation=result?.vendor_info?.designation;
            fields.salary=result?.vendor_info?.salary;
            fields.comments=result?.vendor_info?.comments;            
        }
        setLoading(false);
    }
    
    useEffect(() => {
        fetchData();
    }, []);

    //Submission details

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            setBtnDisable(true);
            let payload = {
                'joining_date': fields.joining_date,
                'pan_no': fields.pan_no.toUpperCase(),
                'contract_duration': fields.contract_duration,
                'designation': fields.designation,
                'salary': fields.salary,
                'comments': fields.comments
            }

            let response = await ManagementService.updateUserDetails(id, payload);
            console.log(response);
            if(response.data = 1){
                navigate(`${process.env.PUBLIC_URL}/management-submitted-listing`);
            } else {
                return false;
            }
            setTimeout(() => {
                setBtnDisable(false);
            }, MINUTE_MS);
        }
    };

    return(  (loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
      />) : managementData && (    
        <div className="page-wrapper">
        <Sidebar sidebarFor="Management"/>
        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-reporter-details management-details">
                <div className="page-header">
                    <h4>Add Management Details</h4>
                    <div className="breadcrumb-w">
                        <ol className="ms-breadcrumb">
                            <li><a href="reporters.html" >Form- All</a></li>
                            <li className="active">{managementData?.vendor_info?.firstname} {managementData?.vendor_info?.lastname}</li>
                        </ol>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        
                            <div className="rd-section">
                                <h3 className="section-title">Account Details</h3>
                                <div className="table-responsive">
                                    <table className="table rd-table">
                                        <thead>
                                            <tr>
                                                <th width="10%">Name</th>
                                                <th width="12%">PAN</th>
                                                <th width="12%">Phone</th>
                                                <th width="12%">District</th>
                                                <th width="25%" className="address">Address</th>
                                                <th width="20%">Vendor Code</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-label="Name">
                                                    <div className="reporter-thumb">
                                                        <img src={photograph} alt="" />
                                                    </div>
                                                    <div className="reporter-name">{managementData?.vendor_info?.firstname} {managementData?.vendor_info?.lastname}</div>
                                                </td>
                                                <td data-label="Pan">
                                                    <div className="val">{pan_card}</div>
                                                    <div className="val">{managementData?.vendor_info?.pan_no}</div>
                                                </td>
                                                <td data-label="Phone">
                                                    <div className="val">{managementData?.phoneno}</div>
                                                </td>
                                                <td data-label="District">
                                                    <div className="val">{managementData?.vendor_address?.city_permanent}</div>
                                                </td>
                                                <td data-label="Address" className="address">
                                                    <div className="val">{managementData?.vendor_address?.flat_no_permanent}, 
                                                    {managementData.vendor_address
                                                    .street_permanent} <br /> {managementData.vendor_address
                                                        .landmark_permanent}, {managementData.vendor_address
                                                        .locality_permanent},{managementData.vendor_address
                                                            .village_permanent}  <br /> {managementData.vendor_address
                                                        .city_permanent} - {managementData.vendor_address
                                                            .pincode_permanent},{managementData.vendor_address
                                                                .state_permanent} ,{managementData.vendor_address.country_permanent}   
                                                    </div>
                                                </td>
                                                <td data-label="Vendor Code">
                                                    <div className="val">
                                                        <span className="vendor-code">{managementData?.vendor_info?.vendor_code}</span>
                                                    </div>
                                                    <div className="created-date">Created On {format(new Date(managementData?.vendor_address?.created_at), 'd MMMM yyyy')}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {((managementData.status === "Accounts" || managementData.status === "Management" ) && (
                                <form action="" onSubmit={onSubmit}>
                                    <div className="rd-section md-section mbtm">
                                        <h3 className="section-title">Management Details</h3>
                                        <div className="table-responsive">
                                                <table className="table rd-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Joining Date</th>
                                                            <th>PAN</th>
                                                            <th>Contract Duration</th>
                                                            <th>Designation</th>
                                                            <th>Salary</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="formGroup has-date-icon">
                                                                    <div className="form-wrapper">
                                                                        <span className="right-icon">
                                                                        <input type="date" placeholder="DD-MM-YY" id="datepicker" name="joining_date" value={fields.joining_date} onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} className="input-control w-half"/>
                                                                        <i className="fi fi-calendar-month-black" style={{display: 'none'}}></i></span>
                                                                    </div>
                                                                </div>
                                                                <label className="error"> {errors.joining_date ? errors.joining_date : ""} </label>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup">
                                                                    <input type="text" name="pan_no" className="input-control pan_no" id="pan_no" value={fields.pan_no} onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}/>
                                                                </div>
                                                                <label className="error"> {errors.pan_no ? errors.pan_no : ""} </label>
                                                            </td>
                                                            <td>
                                                                {/* <div className="formGroup">
                                                                    <select id="contract_duration" className="input-control " name="contract_duration" value={fields.contract_duration} onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}>
                                                                        <option value="">Select</option>
                                                                        <option value="3 months">3 months</option>
                                                                        <option value="6 months">6 months</option>
                                                                        <option value="1 year">1 year</option>
                                                                    </select>
                                                                </div> */}
                                                                <div className="formGroup">
                                                                    <input type="text" name="contract_duration" className="input-control" id="contract_duration" value={fields.contract_duration} onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}/>
                                                                </div>
                                                                <label className="error"> {errors.contract_duration ? errors.contract_duration : ""} </label>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup">
                                                                    <select name="designation" id="designation" className="input-control" value={fields.designation} onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}>
                                                                        <option value="">Select</option>
                                                                        <option value="contributor">Contributor</option>
                                                                        <option value="approver">Approver</option>
                                                                    </select>
                                                                </div>
                                                                <label className="error"> {errors.designation ? errors.designation : ""} </label>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup">
                                                                    <input type="text" name="salary" className="input-control" id="salary" value={fields.salary} onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}/>
                                                                </div>
                                                                <label className="error"> {errors.salary ? errors.salary : ""} </label>
                                                            </td>
                                                            <td>
                                                                <div className="formGroup formTextarea">
                                                                    <textarea name="comments" id="comments" value={fields.comments} onBlur={form.handleBlurEvent} cols="30" rows="10" onChange={form.handleChangeEvent}></textarea>
                                                                </div>
                                                                <label className="error"> {errors.comments ? errors.comments : ""} </label>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            
                                        </div>
                                    </div>
                                    <div className="form-action">
                                        {(btn_disable) ? ( <ColorRing
                                            visible={true}
                                            height="80"
                                            width="80"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                            colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                                        />) : (<button type="submit" className="submit-btn btn-solid">Send to Legal</button>)}
                                    </div>
                                </form>
                             ))}
                            {((managementData.status === "Signed Contract Copy" || managementData.status === "Approved" || managementData.status === "Completed" || managementData.status === "User Creation Failed") && (
                                <ManagementApproveCopy/>
                            ))}
                    </div>
                </div>
                <div className="card" style={{ marginTop: '30px' }}>
                    <div className="card-head"></div>
                    <div className="card-body" >
                        {params.id && <Previewdoc id={params.id} />} {/* Render Previewdoc only if id is available */}                    
                    </div>
                </div>
            </div>
        </div>
        <Modal open={open} onClose={onCloseModal} center>
          <img style={{ width: "400px", height: "auto" }} src={modalFilename} alt="show image" />
        </Modal>
        </div>
        )
    )
}
export default ManagementDetails;