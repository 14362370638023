import React,{useState,useEffect} from "react";
import { useFormInputValidation } from "react-form-input-validation";
import { ColorRing } from 'react-loader-spinner'
import VendorService  from '../services/vendor.service';
import { RotatingTriangles } from 'react-loader-spinner'

function VendorAddressComponent(props){
    const [loading, setLoading] = useState(true);
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 6000;//10 sec 
    const [vendor_address, setvendorAddress] = useState({});
    const [permanentDlt, setpermanentDlt] = useState({});
    const [current_flg, setCurrentFlg] = useState(0);
    const [fields, errors, form] = useFormInputValidation({
        present_flat_no:"",
        present_landmark:"",
        present_village:"",
        present_street:"",
        present_locality:"",
        present_pin_code:"",
        present_city_district:"",
        present_state:"",
        present_country:"",
        permanent_flat_no:"",
        permanent_landmark:"",
        permanent_village:"",
        permanent_street:"",
        permanent_locality:"",
        permanent_pin_code:"",
        permanent_city_district:"",
        permanent_state:"",
        permanent_country:""
        
    }, {
        present_flat_no:"required|remove_special|max:30",
        present_landmark:"required|remove_special|max:30|min:3",
        present_village:"required|alphabetical_space|max:30|min:3",
        present_street:"required|remove_special|max:30|min:3",
        present_locality:"required|alphabetical_space|max:30|min:3",
        present_pin_code:"required|pin_valid",
        present_city_district:"required|alphabetical_space|max:30|min:3",
        present_state:"required",
        present_country:"required",
        permanent_flat_no:"required|remove_special|max:30",
        permanent_landmark:"required|remove_special|max:30|min:3",
        permanent_village:"required|alphabetical_space|max:30|min:3",
        permanent_street:"required|remove_special|max:30|min:3",
        permanent_locality:"required|alphabetical_space",
        permanent_pin_code:"required|pin_valid",
        permanent_city_district:"required|alphabetical_space|max:30|min:3",
        permanent_state:"required",
        permanent_country:"required"
    });
    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            setBtnDisable(true);
        let payload={
        "flat_no_present":fields.present_flat_no,
        "landmark_present": fields.present_landmark,
        "village_present": fields.present_village,
        "street_present": fields.present_street,
        "locality_present": fields.present_locality,
        "pincode_present": fields.present_pin_code,
        "city_present": fields.present_city_district,
        "state_present": fields.present_state,
        "country_present": fields.present_country,
        "flat_no_permanent": fields.permanent_flat_no,
        "landmark_permanent": fields.permanent_landmark,
        "village_permanent": fields.permanent_village,
        "street_permanent": fields.permanent_street,
        "locality_permanent": fields.permanent_locality,
        "pincode_permanent":fields.permanent_pin_code,
        "city_permanent": fields.permanent_city_district,
        "state_permanent": fields.permanent_state,
        "country_permanent": fields.permanent_country,
        "address_flag": true
            }
          async function setAddressVendor() {
            if (props.address_flag) {
                let data = await VendorService.updateAddressVendor(payload);
                if(!data){
                    return false;
                }
            }else{
                let data = await VendorService.setAddressVendor(payload);
                if(!data){
                    return false;
                }
            }
            setTimeout(() => {
                setBtnDisable(false);
                props.setToggleStateClid(3);
            }, MINUTE_MS);
           }
           setAddressVendor(); 
        }
      }

    const setPermanentAddess =  (event) => {
        if(event.target.checked){
         permanentDlt.permanent_flat_no=fields.present_flat_no;
         permanentDlt.permanent_landmark=fields.present_landmark;
         permanentDlt.permanent_village=fields.present_village;
         permanentDlt.permanent_street=fields.present_street;
         permanentDlt.permanent_locality=fields.present_locality;
         permanentDlt.permanent_pin_code=fields.present_pin_code;
         permanentDlt.permanent_city_district=fields.present_city_district;
         permanentDlt.permanent_state=fields.present_state;
         permanentDlt.permanent_country=fields.present_country;
         setpermanentDlt(permanentDlt);
         setCurrentFlg(1);
         errors.permanent_flat_no=(fields.present_flat_no!=='')? '' : errors.permanent_flat_no;
         errors.permanent_landmark=(fields.present_flat_no!=='')? '' : errors.permanent_landmark;
         errors.permanent_village=(fields.permanent_village!=='')? '' : errors.permanent_village;
         errors.permanent_street=(fields.permanent_street!=='')? '' : errors.permanent_street;
         errors.permanent_locality=(fields.permanent_locality!=='')? '' : errors.permanent_locality;
         errors.permanent_pin_code=(fields.permanent_pin_code!=='')? '' : errors.permanent_pin_code;
         errors.permanent_city_district=(fields.permanent_city_district!=='')? '' : errors.permanent_city_district;
         errors.permanent_state=(fields.permanent_state!=='')? '' : errors.permanent_state;
         errors.permanent_country=(fields.permanent_country!=='')? '' : errors.permanent_country;
        }else{
            permanentDlt.permanent_flat_no="";
            permanentDlt.permanent_landmark="";
            permanentDlt.permanent_village="";
            permanentDlt.permanent_street="";
            permanentDlt.permanent_locality="";
            permanentDlt.permanent_pin_code="";
            permanentDlt.permanent_city_district="";
            permanentDlt.permanent_state="";
            permanentDlt.permanent_country="";
            setpermanentDlt(permanentDlt);
            setCurrentFlg(1);
        }
        if(form.isValidForm){
            //
        };
      }
      useEffect(() => {
        form.registerAsync("pin_valid", function (
          str,
          attribute,
          req,
          passes
        ) {
            var specials = /^[0-9]+$/i;
            if (!specials.test(str))
              passes(false, "only number is allow.");
            else if (str.length !==6)
              passes(false, "Pin code should have 6 digit.");  
            else passes();
        });
        form.registerAsync("remove_special", function (
            str,
            attribute,
            req,
            passes
          ) {
              var specials = /^[A-Za-z0-9? /,_-]+$/i;
              if (!specials.test(str))
                passes(false, "Special characters are not allow.");
              else passes();
          });
        form.registerAsync("only_alphabetical", function (
            str,
            attribute,
            req,
            passes
          ) {
              var specials = /^[A-Za-z]+$/i;
              if (!specials.test(str))
                passes(false, "only alphabet is allow.");
              else passes();
          });
          form.registerAsync("alphabetical_space", function (
            str,
            attribute,
            req,
            passes
          ) {
              var specials = /^[A-Za-z ]+$/i;
              if (!specials.test(str))
                passes(false, "only alphabet is allow.");
              else passes();
          });
      }, []);
      useEffect(() => {
        if(props.vendoraddress && current_flg===0){
            setvendorAddress(props.vendoraddress);
             fields.present_flat_no=props.vendoraddress.flat_no_present;
             fields.present_landmark=props.vendoraddress.landmark_present;
             fields.present_village=props.vendoraddress.village_present;
             fields.present_street=props.vendoraddress.street_present;
             fields.present_locality=props.vendoraddress.locality_present;
             fields.present_pin_code=props.vendoraddress.pincode_present;
             fields.present_city_district=props.vendoraddress.city_present;
             fields.present_state=props.vendoraddress.state_present;
             fields.present_country=props.vendoraddress.country_present;
             fields.permanent_flat_no=props.vendoraddress.flat_no_permanent;
             fields.permanent_landmark=props.vendoraddress.landmark_permanent;
             fields.permanent_village=props.vendoraddress.village_permanent;
             fields.permanent_street=props.vendoraddress.street_permanent;
             fields.permanent_locality=props.vendoraddress.locality_permanent;
             fields.permanent_pin_code=props.vendoraddress.pincode_permanent;
             fields.permanent_city_district=props.vendoraddress.city_permanent;
             fields.permanent_state=props.vendoraddress.state_permanent;
             fields.permanent_country=props.vendoraddress.country_permanent;
             setLoading(false);
             setCurrentFlg(2);
        }     
        if(current_flg===1){
            fields.permanent_flat_no=permanentDlt.permanent_flat_no;
            fields.permanent_landmark=permanentDlt.permanent_landmark;
            fields.permanent_village=permanentDlt.permanent_village;
            fields.permanent_street=permanentDlt.permanent_street;
            fields.permanent_locality=permanentDlt.permanent_locality;
            fields.permanent_pin_code=permanentDlt.permanent_pin_code;
            fields.permanent_city_district=permanentDlt.permanent_city_district;
            fields.permanent_state=permanentDlt.permanent_state;
            fields.permanent_country=permanentDlt.permanent_country;
            setCurrentFlg(2);
        }
       },[props.vendoraddress,current_flg]);
    return(
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : vendor_address && ( 
            <form className="contributor-address doc-form" onSubmit={onSubmit}>
                <div className="pad-same">
                    <div className="toggle-checkbox">
                        <label  className="check-w">
                            <input style={{width:"25px",height:"25px"}}  type="checkbox" id="isPAD_Same" className="" name="current_flg" onChange={setPermanentAddess} />
                            {/* <div className="checkbox-toggler">
                                <div className="checked">Yes</div>
                                <div className="unchecked">No</div>
                            </div> */}
                        </label>
                        <label htmlFor="isPAD_Same" style={{fontWeight:"bold"}} >Premanent Address is same as Present Address</label> 
                    </div>
                </div>
                <div className="form-col-2 form-horizontal">
                    <div className="form-cap-card">
                        <div className="form-caption">Present Address Details </div>
                        <div style={{fontWeight:"bold"}}>(Address as per mentioned in Aadhar)</div>
                        <br/>
                        <div className="formGroup">
                            <label  className="input-label">Flat /House No. <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="present_flat_no" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_flat_no} data-attribute-name="present_flat_no" data-async />
                                <label className="error"> {errors.present_flat_no ? errors.present_flat_no : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Landmark<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="present_landmark" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_landmark} data-attribute-name="present_landmark" data-async />
                                <label className="error"> {errors.present_landmark ? errors.present_landmark : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Village<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="present_village" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_village} data-attribute-name="present_village" data-async />
                                <label className="error"> {errors.present_village ? errors.present_village : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Road/ Street<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control"  name="present_street" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_street} data-attribute-name="present_street" data-async  />
                                <label className="error"> {errors.present_street ? errors.present_street : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Locality/Taluka<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="present_locality" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_locality} data-attribute-name="present_locality" data-async  />
                                <label className="error"> {errors.present_locality ? errors.present_locality : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">PIN Code<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="has-half-inp">
                                    <input type="number" className="input-control w-half" name="present_pin_code" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_pin_code} data-attribute-name="present_pin_code" data-async  />
                                </div>
                                <label className="error"> {errors.present_pin_code ? errors.present_pin_code : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">City/Town/District <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="has-half-inp">
                                    <input type="text" className="input-control w-half" name="present_city_district" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_city_district} data-attribute-name="present_city_district" data-async />
                                </div>
                                <label className="error"> {errors.present_city_district ? errors.present_city_district : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">State/ UT<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select  className="input-control w-half" name="present_state" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_state} >
                                        <option value="">Select</option>
                                        <option value="Andaman & NicobarIslands">Andaman & NicobarIslands</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Daman & Diu">Daman & Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="JammuKashmir">Jammu Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Ladakh">Ladakh</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.present_state ? errors.present_state : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">Country<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select className="input-control w-half" name="present_country" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.present_country}>
                                        <option value="">Select</option>
                                        <option value="India">India</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.present_country ? errors.present_country : ""} </label>
                            </div>
                        </div>
                    </div>
                    <div className="form-cap-card">
                        <div className="form-caption">Permanent Address Details</div>
                        <div style={{fontWeight:"bold"}}>(Address as per mentioned in Aadhar)</div>
                        <br/>
                        <div className="formGroup">
                            <label  className="input-label">Flat /House No. <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="permanent_flat_no" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_flat_no} data-attribute-name="permanent_flat_no" data-async />
                                <label className="error"> {errors.permanent_flat_no ? errors.permanent_flat_no : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Landmark<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="permanent_landmark" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_landmark}  data-attribute-name="permanent_landmark" data-async />
                                <label className="error"> {errors.permanent_landmark ? errors.permanent_landmark : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Village<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="permanent_village" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_village} data-attribute-name="permanent_village" data-async />
                                <label className="error"> {errors.permanent_village ? errors.permanent_village : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Road/ Street<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="permanent_street" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_street} data-attribute-name="permanent_street" data-async />
                                <label className="error"> {errors.permanent_street ? errors.permanent_street : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Locality/Taluka<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" className="input-control" name="permanent_locality" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_locality} data-attribute-name="permanent_locality" data-async />
                                <label className="error"> {errors.permanent_locality ? errors.permanent_locality : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">PIN Code<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="has-half-inp">
                                    <input type="text" className="input-control w-half" name="permanent_pin_code" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_pin_code} data-attribute-name="permanent_pin_code" data-async />
                                </div>
                                <label className="error"> {errors.permanent_pin_code ? errors.permanent_pin_code : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">City/Town/District <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="has-half-inp">
                                    <input type="text" className="input-control w-half" name="permanent_city_district" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_city_district} data-attribute-name="permanent_city_district" data-async />
                                </div>
                                <label className="error"> {errors.permanent_city_district ? errors.permanent_city_district : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">State/ UT<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select className="input-control w-half" name="permanent_state" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_state}>
                                        <option value="">Select</option>
                                        <option value="Andaman & NicobarIslands">Andaman & NicobarIslands</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Daman & Diu">Daman & Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="JammuKashmir">Jammu Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Ladakh">Ladakh</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.permanent_state ? errors.permanent_state : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">Country<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-contorl">
                                    <select className="input-control w-half" name="permanent_country" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.permanent_country}>
                                        <option value="">Select</option>
                                        <option value="India">India</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.permanent_country ? errors.permanent_country : ""} </label>
                            </div>
                        </div>
                    </div>
                </div>
                {(!props.final_flag) && (
                    <div className="form-action">
                        {(btn_disable) ? ( <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                                />) : (<button className="submit-btn" type="submit">Save</button>)}
                </div>
                )}
            </form>
          )
   )
}
export default VendorAddressComponent;
