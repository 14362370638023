import { Link, useLocation } from "react-router-dom";

function ManagementTabs() {
    const location = useLocation();
    return (
        <div className="card-head">
            <div className="tab-list">
                <Link to={`${process.env.PUBLIC_URL}/management-all-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/management-all-listing` ? "tablink tab-active" :"tablink"}>All</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/management-new-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/management-new-listing` ? "tablink tab-active" :"tablink"}>New</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/management-submitted-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/management-submitted-listing` ? "tablink tab-active" :"tablink"}>Submitted</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/management-contract-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/management-contract-listing` ? "tablink tab-active" :"tablink"}>Contract Copy</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/management-approved-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/management-approved-listing` ? "tablink tab-active" :"tablink"}>Approved</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/management-completed-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/management-completed-listing` ? "tablink tab-active" :"tablink"}>Completed</button>
                </Link>
            </div>
        </div>
    )
}

export default ManagementTabs;