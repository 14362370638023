import React from "react";
import '../css/file18.css';
import Sidebar from '../components/sidebarComponent';

function ReportersList(){

    return(
        <div className="page-wrapper">
        <header className="file-header">
            <img src={`${process.env.PUBLIC_URL}/img/network18_creative.png`} className="nw-logo" alt=" " />
            <div className="hamburger-icon" >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        </header>
        <Sidebar/>
        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-reporters">
                <div className="page-header">
                    <h4>New Reporters</h4>
                </div>
                <div className="card">
                    <div className="card-head">
                        <div className="tab-list">
                            <button className="tablinks tab-active ">All</button>
                            <button className="tablinks" >Approved</button>
                            <button className="tablinks" >Rejected</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="tab-wrapper">
                            <div id="all" className="tab-content">
                                <div className="search-filter">
                                    <div className="search-form has-search-icon">
                                        <input type="text" className="input-control" placeholder="Search" />
                                        <span className="icon-left">
                                            <i className="fi fi-search-black"></i>
                                        </span>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>District</th>
                                                <th>Submitted On</th>
                                                <th>Status</th>
                                                <th className="action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td data-label="Name">Steve Krug</td>
                                                <td data-label="Email">steve.krug@gmail.com</td>
                                                <td data-label="District">Prayagraj</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">New</td>
                                                <td className="action">
                                                    <a href="reporter-details.html" className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">Ashlee Vance</td>
                                                <td data-label="Email">ashlee.v@gmail.com</td>
                                                <td data-label="District">Bareilly</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">New</td>
                                                <td className="action">
                                                    <a href="reporter-details.html" className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">Dale Carnegie</td>
                                                <td data-label="Email">dale.c@gmail.com</td>
                                                <td data-label="District">Ayodhya</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">New</td>
                                                <td className="action">
                                                    <a href="reporter-details.html" className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">Spencer Johnson</td>
                                                <td data-label="Email">s.jhonson@gmail.com</td>
                                                <td data-label="District">Gorakhpur</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">New</td>
                                                <td className="action">
                                                    <a href="reporter-details.html" className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">Mike Clement</td>
                                                <td data-label="Email">mike.clement@gmail.com</td>
                                                <td data-label="District">Jhansi</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">New</td>
                                                <td className="action">
                                                    <a href="reporter-details.html" className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">Joseph Murphy</td>
                                                <td data-label="Email">j.murphy@gmail.com</td>
                                                <td data-label="District">Kanpur</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">Approved</td>
                                                <td className="action">
                                                    <button className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">John Doe</td>
                                                <td data-label="Email">john.doe@gmail.com</td>
                                                <td data-label="District">Aligarh</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">Approved</td>
                                                <td className="action">
                                                    <button className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">John Doe</td>
                                                <td data-label="Email">john.doe@gmail.com</td>
                                                <td data-label="District">Aligarh</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">Rejected</td>
                                                <td className="action">
                                                    <button className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">John Doe</td>
                                                <td data-label="Email">john.doe@gmail.com</td>
                                                <td data-label="District">Aligarh</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">Approved</td>
                                                <td className="action">
                                                    <button className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td data-label="Name">John Doe</td>
                                                <td data-label="Email">john.doe@gmail.com</td>
                                                <td data-label="District">Aligarh</td>
                                                <td data-label="Submitted On">10 January 2023</td>
                                                <td data-label="Status">Approved</td>
                                                <td className="action">
                                                    <button className="action-btn">
                                                        <i className="fi fi-arrow-left-short"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="pagination-strip">
                                        <span>Rows per page</span>
                                        {/* <input type="text" name="" id="" value="10"> */}
                                        <span>1-10 of 70</span>
                                        <div className="pg-buttons">
                                            <button className="backto disabled">
                                                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_107_7)"><rect width="20" height="20" transform="translate(20 20) rotate(-180)" fill="white"/><path d="M15 4L9 10L15 16" stroke="#272d3b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><rect x="5.5" y="16.5" width="1" height="13" rx="0.5" transform="rotate(-180 5.5 16.5)" stroke="#272d3b"/></g><defs><clipPath id="clip0_107_7"><rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)"/></clipPath></defs></svg> */}
                                            </button>
                                            <button className="back disabled">
                                                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_109_10)"><rect width="20" height="20" transform="translate(20 20) rotate(180)" fill="white"/><path d="M13 4L7 10L13 16" stroke="#272d3b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_109_10"><rect width="20" height="20" fill="white" transform="translate(20 20) rotate(180)"/></clipPath></defs></svg> */}
                                            </button>
                                            <button className="next">
                                                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_109_2)"><rect width="20" height="20" fill="white"/><path d="M7 16L13 10L7 4" stroke="#272d3b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_109_2"><rect width="20" height="20" fill="white"/></clipPath></defs></svg> */}
                                            </button>
                                            <button className="nextto">
                                                {/* <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_107_3)"><rect width="20" height="20" fill="white"/><path d="M5 16L11 10L5 4" stroke="#272d3b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><rect x="14.5" y="3.5" width="1" height="13" rx="0.5" stroke="#272d3b"/></g><defs><clipPath id="clip0_107_3"><rect width="20" height="20" fill="white"/></clipPath></defs></svg> */}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                    </div>
                </div>   
            </div>
        </div>
    </div>
    )
}
export default ReportersList;
