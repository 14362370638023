import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import { useNavigate, useParams } from "react-router-dom";
import { RotatingTriangles } from 'react-loader-spinner'
import Sidebar from '../../components/sidebarComponent';
import ManagementService from '../../services/managment-service';
import { format } from 'date-fns';

function ManagementApproveCopy(){
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [managementData, setManagementData] = useState([]);
    const [status, setStatus] = useState(true);
    const [otherReasonStatus, showOtherReason] = useState(false);
    const [rejectComment, setRejectComment] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [moderation, setModeration] = useState('accepted');
    const [rejectError, setRejectError] = useState();
    const [rejectOtherError, setOtherRejectError] = useState();
    
    let photograph = '';
    let finaldate = '';
    let comment = '';
    
    const handleStatus = (event) => {
        setStatus(event.target.checked);

        if(event.target.checked){
            setModeration('accepted')
            setOtherReason('');
            setRejectComment('');
            showOtherReason(false);
        } else {
            setModeration('rejected')
        }
    }

    const handleComment = (event) => {
        const selected_value = event.target.value;
        if(selected_value === 'other'){
            showOtherReason(true);
        } else {
            showOtherReason(false);
        }
        setRejectComment(selected_value);
    }

    const handleOtherReason = (event) => {
        const other_reason = event.target.value;
        setOtherReason(other_reason);
    }

    //Extract only photograph from the documents data
    {managementData && managementData.vendor_documents && managementData.vendor_documents.map((documents, index) => {
        if(documents.document_type === 'photograph') {
            photograph = documents.document_path
        }
    })}

    if(managementData?.vendor_info?.joining_date){
        let joindate = new Date(managementData?.vendor_info?.joining_date);
        finaldate = joindate.toISOString().substring(0, 10);
    }   

    const fetchData = async () => {
        const result = await ManagementService.getUserInfo(id);
        console.log(result);
        if(result.status != 'Signed Contract Copy'){
            navigate(`${process.env.PUBLIC_URL}/management-contract-listing`);
        }
        setManagementData(result || []);
        setLoading(false);
    }
    
    useEffect(() => {
        fetchData();
    }, []);

    //Submission details

    const onSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateForm(event);

        if(isValid){
            if(otherReasonStatus === true) {
                comment = otherReason;
            } else {
                comment = rejectComment;
            }
    
            let payload = {
                'status': moderation,
                'comments': comment,
            }
    
            let response = await ManagementService.updateContractCopyDetails(id, payload);
            if(response.data = 1){
                navigate(`${process.env.PUBLIC_URL}/management-contract-listing`);
            } else {
                return false;
            }
        }        
    };

    const validateForm = (e) => {
        let isValid = true;

        if(status === false && rejectComment === ''){
            setRejectError({s: false, msg: 'Please select a reason'});
            isValid = false;
        }

        if(status === false && rejectComment === 'other' && otherReason === ''){
            setOtherRejectError({s: false, msg: 'Other reason field cannot be empty'});
            isValid = false;
        }
        return isValid;
    }

    return(  (loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
      />) : managementData && (    
        <div className="page-wrapper">
            <header className="file-header">
                <img src="img/network18_creative.png" className="nw-logo" alt=" " />
                <div className="hamburger-icon" >
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </header>
            <Sidebar sidebarFor="Management"/>
            <div className="content-wrapper">
                <div className="overlay" id="overlay"></div>
                <div className="page-content page-reporter-details management-details">
                    <div className="page-header">
                        <h4>Approve Contract Copy</h4>
                        <div className="breadcrumb-w">
                            <ol className="ms-breadcrumb">
                                <li><a href="reporters.html" >Contract Copy</a></li>
                                <li className="active">{managementData?.vendor_info?.firstname} {managementData?.vendor_info?.lastname}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <form action="" onSubmit={onSubmit}>
                                <div className="rd-section">
                                    <h3 className="section-title">Account Details</h3>
                                    <div className="table-responsive">
                                        <table className="table rd-table">
                                            <thead>
                                                <tr>
                                                    <th width="10%">Name</th>
                                                    <th width="12%">PAN</th>
                                                    <th width="12%">Phone</th>
                                                    <th width="12%">District</th>
                                                    <th width="25%" className="address">Address</th>
                                                    <th width="20%">Vendor Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-label="Name">
                                                        <div className="reporter-thumb">
                                                            <img src={photograph} alt="" />
                                                        </div>
                                                        <div className="reporter-name">{managementData?.vendor_info?.firstname} {managementData?.vendor_info?.lastname}</div>
                                                    </td>
                                                    <td data-label="Pan">
                                                        <div className="val">{managementData?.vendor_info?.pan_no}</div>
                                                    </td>
                                                    <td data-label="Phone">
                                                        <div className="val">{managementData?.phoneno}</div>
                                                    </td>
                                                    <td data-label="District">
                                                        <div className="val">{managementData?.vendor_address?.city_permanent}</div>
                                                    </td>
                                                    <td data-label="Address" className="address">
                                                        <div className="val">
                                                            {managementData?.vendor_address?.flat_no_permanent}<br /> {managementData?.vendor_address?.landmark_permanent} <br /> {managementData?.vendor_address?.locality_permanent} <br /> {managementData?.vendor_address?.city_permanent} - {managementData?.vendor_address?.pincode_permanent}, {managementData?.vendor_address?.country_permanent}
                                                        </div>
                                                    </td>
                                                    <td data-label="Vendor Code">
                                                        <div className="val">
                                                            <span className="vendor-code">{managementData?.vendor_info?.vendor_code}</span>
                                                        </div>
                                                        <div className="created-date">{format(new Date(managementData?.vendor_address?.created_at), 'd MMMM yyyy')}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="rd-section md-section mbtm">
                                    <h3 className="section-title">Management Details</h3>
                                    <div className="table-responsive">
                                            <table className="table rd-table">
                                                <thead>
                                                    <tr>
                                                        <th>Joining Date</th>
                                                        <th>PAN</th>
                                                        <th>Contract Duration</th>
                                                        <th>Designation</th>
                                                        <th>Salary</th>
                                                        <th>Comments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-label="Joining Date">
                                                            <div className="val">
                                                                {finaldate}
                                                            </div>
                                                        </td>
                                                        <td data-label="Contract Duration">
                                                            <div className="val">
                                                                {managementData?.vendor_info?.pan_no}
                                                            </div>
                                                        </td>
                                                        <td data-label="Contract Duration">
                                                            <div className="val">
                                                                {managementData?.vendor_info?.contract_duration}
                                                            </div>
                                                        </td>
                                                        <td data-label="Designation">
                                                            <div className="val">
                                                                {managementData?.vendor_info?.designation}
                                                            </div>
                                                        </td>
                                                        <td data-label="Salary">
                                                            <div className="val">
                                                                {managementData?.vendor_info?.salary}
                                                            </div>
                                                        </td>
                                                        <td data-label="Comments" className="text-left">
                                                            <div className="val">
                                                                {managementData?.vendor_info?.comments}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        
                                    </div>
                                </div>
                                <div className="rd-section upload-section ar-uploaded-section">
                                    <h3 className="section-title">Signed Contract Copy</h3>
                                    <div className="upload-contract-copy">
                                        <label htmlFor="">Approve Signed contract copy and assign File 18 URL to vendor</label>
                                        <div className="upload-cc-wrapper">
                                            <div className="temp-upload-file">
                                                <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                                <div className="upload_file_name">{managementData?.vendor_documents[2]?.document_name}</div>
                                                <a href="" class="download-icon"><i className="fi fi-file-download"></i></a>
                                            </div>
                                            <div className="ar-toggle-button">
                                                <label htmlFor="isAccept" className="check-w">
                                                    <input type="checkbox" id="isAccept" className="checkbox" name="status" defaultChecked={status} value={status} onChange={handleStatus}/>
                                                    <div className="checkbox-toggler">
                                                        <div className="checked">Accept</div>
                                                        <div className="unchecked">Reject</div>
                                                    </div>
                                                </label>                                                
                                            </div>
                                            <div className="reason-sel">
                                                <div className="formGroup">
                                                    <select name="comments" id="comments" value={rejectComment} className="input-control" disabled={status} onChange={handleComment}>
                                                        <option value="">Select</option>
                                                        <option value="Reason 1">Reason 1</option>
                                                        <option value="Reason 2">Reason 2</option>
                                                        <option value="other">Other</option>
                                                    </select>
                                                </div>
                                                <label className="error"> {rejectError ? rejectError.msg : ""} </label>
                                            </div>
                                            {
                                                otherReasonStatus === true && (
                                                    <div className="reason-sel">
                                                        <div id="if_reason_other" className="other-reason">
                                                            <div className="formGroup">
                                                                <input type="text" name="other_reason" id="other_reason" value={otherReason} className="input-control" onChange={handleOtherReason}/>
                                                            </div>
                                                        </div>
                                                        <label className="error"> {rejectOtherError ? rejectOtherError.msg : ""} </label>
                                                    </div>
                                                )
                                            }                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="form-action">
                                    <button className="submit-btn btn-solid" type="submit">Assign URL</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    )
}
export default ManagementApproveCopy;