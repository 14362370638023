import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import { useNavigate,Link } from "react-router-dom";
import AuthService  from '../../services/auth.service';
import { RotatingTriangles } from 'react-loader-spinner'
import Sidebar from '../../components/sidebarComponent';
import ManagementList from '../../components/management/managementList';
import ManagementService from '../../services/managment-service';
import ManagementTabs from "../../components/management/tabsComponent";

function ManagementSubmittedListing(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState();
    const [totalResults, setTotalResults] = useState();

    const [toggleState, setToggleState] = useState(1);
    const [managementData, setManagementData] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };
    
    const fetchData = async () => {
        return await ManagementService.getSubmittedUsers(currentPage, limit, searchQuery);   
    }
    
    useEffect(() => {
        if(!AuthService.isLoggedInUser()){
            navigate(process.env.PUBLIC_URL+"/management-login");
        }else{
            const fetchDataAndSetState = async () => {
                const result = await fetchData();
                setManagementData(result || []);
                setTotalPages(result?.totalPages);
                setTotalResults(result?.totalResults);
            }
            fetchDataAndSetState();
            setLoading(false);
        }
    }, [toggleState, currentPage, limit, searchQuery]);

    return((loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
    />) : managementData && (       
        <div className="page-wrapper">
            <Sidebar sidebarFor="Management"/>
            <div className="content-wrapper">
                <div className="page-content page-reporters">
                    <div className="page-header">
                        <h4>Submitted Reporters</h4>
                    </div>
                    <div className="card">
                        <ManagementTabs/>
                        <div className="card-body">
                            <div className="tab-wrapper">
                                <div id="reporters_submitted"  className="tab-content">
                                    <ManagementList managementData={managementData}
                                    currentPage={currentPage}
                                    limit={limit}
                                    totalPages={totalPages}
                                    totalResults={totalResults}
                                    handlePagination={handlePagination}
                                    handleSearch={handleSearch}
                                    pageType="Submitted_Listing"
                                    />
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    )
}
export default ManagementSubmittedListing;