import React, { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "./pagination";
import AdminService from '../../services/event/admin.service';
import { format } from 'date-fns'

function EventUserListComponent(props) {

    const { eventUserResult, currentPage, limit, totalPages, totalResults, handlePagination, handleSearch, pageType } = props;
    const [activeRows, setActiveRows] = useState([]);
    const MINUTE_MS = 6000;//10 sec 
    const toggleRow = (index) => {
      setActiveRows(activeRows.includes(index) 
        ? activeRows.filter((i) => i !== index) 
        : [...activeRows, index]
      );
    };
console.log('eventUserResult', eventUserResult.rows);
    const deactivateUser = async (id) => {
        let payload={
            "is_active":0,
            "id":id
            }
        const deactivate = await AdminService.deleteUser(payload);
        if (deactivate.status === 'fail' || deactivate.status === 'error') {
            return false;
        }
        setTimeout(() => {
            window.location.reload();
        }, MINUTE_MS);
    };

    return (
        <div>
            <div className="search-filter">
                <div className="search-form has-search-icon">
                    <input type="text" className="input-control" placeholder="Search By Email" onChange={handleSearch}></input>
                    <span className="icon-left">
                        <i className="fi fi-search-black"></i>
                    </span>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                        <th></th>
                             <th>FullName</th>
                             <th>Designation</th>
                             <th>Company Name</th>
                             <th>Email</th>
                             <th>Contact Number</th>
                             <th>UTM Source</th>
                             <th>Created Date & Time</th>
                             <th>Type</th>
                        </tr>
                    </thead>
                    <tbody>
                    {eventUserResult.rows && eventUserResult.rows.map((eventUsers, index) => (
                        <>
                       
                        <tr className={`tblflx ${activeRows.includes(index) ? "tr_active" : ""}`}>
                            <td><a className="tr_toggle_action" onClick={() => toggleRow(index)}><i className="fi fi-chevron-right-black"></i></a></td>
                            <td data-label="Name">{eventUsers?.firstname+' '+eventUsers?.lastname}</td>
                            <td data-label="Email">{eventUsers?.designation}</td>
                            <td data-label="Status">{eventUsers?.organisation}</td>
                            <td data-label="Status">{eventUsers?.email}</td>  
                            <td data-label="Name">{eventUsers?.phoneno}</td>
                            <td data-label="Email">{eventUsers?.UTM_source}</td>
                            <td data-label="Status">{eventUsers?.created_at}</td>
                            <td data-label="Status">{eventUsers?.type}</td>  
                        </tr>                        
                        </>
                         ))}
                    </tbody>
                </table>
            </div>
            {eventUserResult.rows && eventUserResult.rows.length > 0 && (
            <Pagination 
            currentPage={currentPage}
            limit={limit}
            totalPages={totalPages}
            totalResults={totalResults}
            handlePagination={handlePagination}
            />
            )}
        </div>
    )
}
export default EventUserListComponent;