import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import '../css/file18.css';
import AuthService from '../services/auth.service';
import VendorService from '../services/vendor.service';

function VendorPreview2() {
  const vendorId = 1;
  console.log("tes" + vendorId);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState([]);
  const [preview, setPreviews] = useState([]);
  const documentTypes = [
    'aadhaar card',
    'pan card',
    'cancelled cheque',
    'resume',
    'contributor_form',
    'bank_statement',
    'gst_form',
    'declaration_form',
    'pan_aadhar_undertaking'
  ]


  useEffect(() => {
    if (!AuthService.isLoggedInVendor()) {
      navigate(process.env.PUBLIC_URL + "/vendor-login");
    } else {
      getVendor();
    }
    async function getVendor() {
      let data = await VendorService.getCurrentVendor();
      setVendor(data);
      setPreviews(data.vendor_documents);
      console.log(data.vendor_info);
    }
    getVendor();
    setLoading(false);
  }, []);

  const Capitalize = (str) => {
    if (typeof str !== 'undefined')
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const convertDate = (date) => {
    var todaysDate = new Date(date);
    var yyyy = todaysDate.getFullYear().toString();
    var mm = (todaysDate.getMonth() + 1).toString();
    var dd = todaysDate.getDate().toString();
    var mmChars = mm.split('');
    var ddChars = dd.split('');
    return (ddChars[1] ? dd : "0" + ddChars[0]) + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + yyyy;
  }

  return (
    <div className="page-content page-contributor-preview page-track-status preview-component">
      <div className="page-header">
        <h4>Preview the  Details</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <section className="section-basic-details">
            <div className="cap-wrapper">
              <div className="form-caption">Personal Details</div>
              <div className="preview-data">
                <div className="pre-label">Name</div>
                <div className="pre-value">
                  {Capitalize(vendor.vendor_info?.firstname || '')}{' '}
                  {Capitalize(vendor.vendor_info?.lastname || '')}</div>
              </div>
              <div className="preview-data">
                <div className="pre-label">Gender</div>
                <div className="pre-value">
                  {Capitalize(vendor.vendor_info?.gender || '')}</div>
              </div>
              <div className="preview-data">
                <div className="pre-label">DOB</div>
                <div className="pre-value">{convertDate(vendor.vendor_info?.dob || '')}</div>
              </div>
              <div className="preview-data">
                <div className="pre-label">Nationality</div>
                <div className="pre-value">{Capitalize(vendor.vendor_info?.nationality || '')}</div>
              </div>
              <div className="preview-data">
                <div className="pre-label">Marital Status</div>
                <div className="pre-value">{Capitalize(vendor.vendor_info?.marital_status || '')}</div>
              </div>
            </div>
            <div className="cap-wrapper">
              <div className="form-caption">Family Details</div>
              <div className="preview-data">
                <div className="pre-label">Father's Name</div>
                <div className="pre-value">{Capitalize(vendor.vendor_info?.f_firstname || '')} {Capitalize(vendor.vendor_info?.f_lastname || '')}</div>
              </div>
              <div className="preview-data">
                <div className="pre-label">Mother's Name</div>
                <div className="pre-value">{Capitalize(vendor.vendor_info?.m_firstname || '')} {Capitalize(vendor.vendor_info?.m_lastname || '')}</div>
              </div>
            </div>
          </section>
          <section className="section-address">
            <div className="cap-wrapper">
              <div className="form-caption">Present Address</div>
              <div className="preview-data">
                <div className="pre-value">{vendor.vendor_address
                  ?.flat_no_present || ''} <br />{vendor.vendor_address
                    ?.street_present || ''} <br /> {vendor.vendor_address
                      ?.landmark_present || ''}, {vendor.vendor_address
                        ?.locality_present || ''},{vendor.vendor_address
                          ?.village_present || ''}  <br /> {vendor.vendor_address
                            ?.city_present || ''} - {vendor.vendor_address
                              ?.pincode_present || ''},{vendor.vendor_address
                                ?.state_present || ''} ,{vendor.vendor_address
                                  ?.country_present || ''} </div>
              </div>
            </div>
            <div className="cap-wrapper">
              <div className="form-caption">Permanent Address</div>
              <div className="preview-data">
                <div className="pre-value">{vendor.vendor_address
                  ?.flat_no_permanent || ''} <br />{vendor.vendor_address
                    ?.street_permanent || ''} <br /> {vendor.vendor_address
                      ?.landmark_permanent || ''}, {vendor.vendor_address
                        ?.locality_permanent || ''},{vendor.vendor_address
                          ?.village_permanent || ''}  <br /> {vendor.vendor_address
                            ?.city_permanent || ''} - {vendor.vendor_address
                              ?.pincode_permanent || ''},{vendor.vendor_address
                                ?.state_permanent || ''} ,{vendor.vendor_address
                                  ?.country_permanent || ''} </div>
              </div>
            </div>
          </section>

          {preview.length > 0 ? (
            <section className="section-doc-upload">
              <div className="cap-wrapper w-full">
                <div className="form-caption">Documents</div>
                <div className="tble-wrapper">
                  <div className="thead">
                    <div className="th">Proof Name</div>
                    <div className="th attach-file-div">Document Name</div>

                    <div className="th attach-file-div">Attach File</div>
                  </div>
                  <div className="tbody-wrapper">
                    {preview.map((document) => {
                      return (
                        <div className="tbody" key={document.id}>
                          <div className="td">
                            <div className="tab-label v-mob">Proof Name</div>
                            <div className="label text-upper">
                              {document.document_type === 'aadhaar card' ? (<div>SCANNED {document.document_type}`<span className="asterisk">*</span></div>) : ''}
                              {document.document_type === 'pan card' ? (<div>SCANNED {document.document_type}`<span className="asterisk">*</span></div>) : ''}
                              {document.document_type === 'cancelled cheque' ? (<div>SCANNED {document.document_type}`<span className="asterisk">*</span></div>) : ' '}
                              {document.document_type === 'resume' ? (<div>SCANNED RESUME/CV<span className="asterisk">*</span></div>) : ' '}
                              {document.document_type === 'contributor_form' ? (<div>CONTRIBUTOR OPENING FORM <span className="asterisk">*</span>(Only Xlsx)</div>) : ' '}
                              {document.document_type === 'bank_statement' ? (<div>BANK ACCOUNT STATEMENT <span className="asterisk">*</span>(Only Xlsx)</div>) : ' '}
                              {document.document_type === 'gst_form' ? (<div>GST DECLARATION FORM <span className="asterisk">*</span>(Only PDF)</div>) : ' '}
                              {document.document_type === 'declaration_form' ? (<div>SELF DECLARATION FORM <span className="asterisk">*</span>(Only PDF)</div>) : ' '}
                              {document.document_type === 'pan_aadhar_undertaking' ? (<div>PAN AADHAR UNDERTAKING <span className="asterisk">*</span>(Only pdf)</div>) : ' '}
                              {!documentTypes.includes(document.document_type) ? document.document_type : ''}

                              </div>
                          </div>
                          <div className="td attach-file-div">
                            <div className="attachment">
                              <div className="attachment-label text-upper">
                                {document.document_type === 'aadhaar card' ? `SCANNED ${document.document_type}` : ''}
                                {document.document_type === 'pan card' ? `SCANNED ${document.document_type}` : ''}
                                {document.document_type === 'cancelled cheque' ? `${document.document_type}` : ' '}
                                {document.document_type === 'resume' ? `RESUME/CV` : ' '}
                                {document.document_type === 'contributor_form' ? (<div>CONTRIBUTOR OPENING FORM</div>) : ' '}
                                {document.document_type === 'bank_statement' ? `BANK ACCOUNT STATEMENT` : ' '}
                                {document.document_type === 'gst_form' ? `GST DECLARATION FORM` : ' '}
                                {document.document_type === 'declaration_form' ? (<div>SELF DECLARATION FORM</div>) : ' '}
                                {document.document_type === 'pan_aadhar_undertaking' ? (<div>PAN AADHAR UNDERTAKING</div>) : ' '}
                                {!documentTypes.includes(document.document_type) ? document.document_type : ''}

                              </div>
                            </div>
                          </div>
                          <div className="td attach-file-div">
                            <div className="attach-icon"><i className="fi fi-file-copy-black"></i>
                              {decodeURIComponent(document.document_path.substring(document.document_path.lastIndexOf('/') + 1).substring(37))}
                            </div>
                          </div>


                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <section className="section-doc-upload">
              <div className="cap-wrapper w-full">
                <div className="form-caption">Documents</div>
                <div className="tble-wrapper">
                  <div className="thead">
                    <div className="th">Proof Name</div>
                    <div className="th attach-file-div">Attach File</div>
                  </div>
                  <div className="tbody-wrapper">
                    <center>No data found</center>
                  </div>
                </div>
              </div>
            </section>
          )}

        </div>
      </div>
    </div>
  )
}
export default VendorPreview2;
