import React, { useState, useEffect } from "react";
import '../../css/homePage.css';
import '../../css/file18.css';
import Sidebar from '../../components/sidebarComponent';
import { useNavigate } from "react-router-dom";
import AuthService  from '../../services/auth.service';
import StaffManagement from '../../components/superAdmin/staffManagement';
import SuperAdminService from '../../services/superAdmin.service';
import { RotatingTriangles } from 'react-loader-spinner';

function Dashboard() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState();
    const [totalResults, setTotalResults] = useState();

    const [toggleState, setToggleState] = useState(1)
    const [staffData, setStaffData] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const [searchText, setSearchText] = useState("");
    const [activeTab, setActiveTab] = useState(1);
    
    const handleSearch = (event) => {
        const value = event.target.value;
        setSearchText(value);
        if (value.length >= 3 || value.length === 0) {
            setSearchQuery(value);
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    const toggleTab = (index, pageSize) => {
        setToggleState(index);
        setLimit(pageSize);
        setSearchQuery("");
        setSearchText("");
        setCurrentPage(1);
        setActiveTab(index);
        localStorage.setItem('activeTab', index);
    };

    const fetchData = async () => {
        switch (toggleState) {
            case 1:
                return await SuperAdminService.getAllStaff(currentPage, limit, searchQuery);
            case 2:
                return await SuperAdminService.getAdminStaff(currentPage, limit, searchQuery);
            case 3:
                return await SuperAdminService.getAccountStaff(currentPage, limit, searchQuery);
            case 4:
                return await SuperAdminService.getFinanceStaff(currentPage, limit, searchQuery);
            case 5:
                return await SuperAdminService.getLegalStaff(currentPage, limit, searchQuery);
            case 6:
                return await SuperAdminService.getManagementStaff(currentPage, limit, searchQuery);
            case 7:
                return await SuperAdminService.getSuperAdminStaff(currentPage, limit, searchQuery);
            default:
                return [];
        }
    }

    const fetchDataAndSetState = async () => {
        setLoading(true);
        const result = await fetchData();
        setStaffData(result || []);
        setTotalPages(result?.totalPages);
        setTotalResults(result?.totalResults);
        setLoading(false);
    }

    useEffect(() => {
        const storedActiveTab = localStorage.getItem('activeTab');
        if (storedActiveTab !== null) {
            setToggleState(parseInt(storedActiveTab));
            setActiveTab(parseInt(storedActiveTab));
        }
      }, []);

    useEffect(() => {
        if(!AuthService.isLoggedInUser()){
            navigate(process.env.PUBLIC_URL+"/super-admin-login");
        }else{
            fetchDataAndSetState();
        }
    }, [toggleState, currentPage, limit, searchQuery]);

    return (
        <div>
            <div className="page-wrapper">
                <Sidebar sidebarFor="SuperAdmin" activeTab={activeTab} setActiveTab={setActiveTab} setToggleState={setToggleState}/>
                <div className="content-wrapper">
                    <div className="page-content page-staff-list">
                        <div className="page-header">
                            <h4>Staff List</h4>
                        </div>
                        <div className="card">
                            <div className="card-head">
                                <div className="tab-list">
                                    <button className={toggleState === 1 ? 'tablinks tab-active' : "tablinks"} onClick={() => toggleTab(1, 10)}>All</button>
                                    <button className={toggleState === 2 ? 'tablinks tab-active' : "tablinks"} onClick={() => toggleTab(2, 10)}>Admin</button>
                                    <button className={toggleState === 3 ? 'tablinks tab-active' : "tablinks"} onClick={() => toggleTab(3, 10)}>Account</button>
                                    <button className={toggleState === 4 ? 'tablinks tab-active' : "tablinks"} onClick={() => toggleTab(4, 10)}>Finance</button>
                                    <button className={toggleState === 5 ? 'tablinks tab-active' : "tablinks"} onClick={() => toggleTab(5, 10)}>Legal</button>
                                    <button className={toggleState === 6 ? 'tablinks tab-active' : "tablinks"} onClick={() => toggleTab(6, 10)}>Management</button>
                                    <button className={toggleState === 7 ? 'tablinks tab-active' : "tablinks"} onClick={() => toggleTab(7, 10)}>Super Admin</button>
                                </div>
                            </div>
                            <div id="all" className={toggleState === 1 ? 'tab-content active-content' : "tab-content hidden"}>
                                {loading ? (
                                    <RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="triangle-loading"
                                        visible={loading}
                                    />
                                ) : (
                                    <StaffManagement
                                        staffData={staffData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        fetchDataAndSetState={fetchDataAndSetState}
                                    />
                                )}

                            </div>
                            <div id="admin" className={toggleState === 2 ? 'tab-content active-content' : "tab-content hidden"}>
                                {loading ? (
                                    <RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="triangle-loading"
                                        visible={loading}
                                    />
                                ) : (
                                    <StaffManagement
                                        staffData={staffData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        fetchDataAndSetState={fetchDataAndSetState}
                                    />
                                )}

                            </div>
                            <div id="account" className={toggleState === 3 ? 'tab-content active-content' : "tab-content hidden"}>
                                {loading ? (
                                    <RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="triangle-loading"
                                        visible={loading}
                                    />
                                ) : (
                                    <StaffManagement
                                        staffData={staffData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        fetchDataAndSetState={fetchDataAndSetState}
                                    />
                                )}

                            </div>
                            <div id="management" className={toggleState === 4 ? 'tab-content active-content' : "tab-content hidden"}>
                                {loading ? (
                                    <RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="triangle-loading"
                                        visible={loading}
                                    />
                                ) : (
                                    <StaffManagement
                                        staffData={staffData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        fetchDataAndSetState={fetchDataAndSetState}
                                    />
                                )}

                            </div>
                            <div id="legal" className={toggleState === 5 ? 'tab-content active-content' : "tab-content hidden"}>
                                {loading ? (
                                    <RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="triangle-loading"
                                        visible={loading}
                                    />
                                ) : (
                                    <StaffManagement
                                        staffData={staffData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        fetchDataAndSetState={fetchDataAndSetState}
                                    />
                                )}

                            </div>
                            <div id="finance" className={toggleState === 6 ? 'tab-content active-content' : "tab-content hidden"}>
                                {loading ? (
                                    <RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="triangle-loading"
                                        visible={loading}
                                    />
                                ) : (
                                    <StaffManagement
                                        staffData={staffData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        fetchDataAndSetState={fetchDataAndSetState}
                                    />
                                )}

                            </div>
                            <div id="super-admin" className={toggleState === 7 ? 'tab-content active-content' : "tab-content hidden"}>
                                {loading ? (
                                    <RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="triangle-loading"
                                        visible={loading}
                                    />
                                ) : (
                                    <StaffManagement
                                        staffData={staffData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        searchText={searchText}
                                        fetchDataAndSetState={fetchDataAndSetState}
                                    />
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Dashboard;
