import React from "react";

function creativeLogoComponent(){

    return(
            <div className="intro-creative">
                <div className="creative-logo">
                    <img src="img/network18_creative.png" alt="Network18 Media &amp; Investments Limited" />
                    </div>
            </div> 
    )
}
export default creativeLogoComponent;
