import {getAxios,postAxios,toastMsg} from '../utils/ApiHelper';

class VendorService {
  getCurrentVendor(payload) {
    return getAxios("vendor/get", payload)
      .then(response => {
        return response.data;
      });
  }

  setbasicDetailsVendor(payload) {
    return postAxios("vendor-info/add", payload)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }

  setAddressVendor(payload) {
    return postAxios("vendor-address/add", payload)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
  setDocumentsVendor(payload,file_flg) {
    return postAxios("vendor-document/add", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
  setdeclationVendor(payload,file_flg) {
    return postAxios("vendor-declaration/add", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }

  updatebasicDetailsVendor(payload) {
    return postAxios("vendor-info/update", payload)
      .then(response => {
        if(response){
          if(response){
            toastMsg(response.message,'success');
          }
        }
        return response;
      });
  }

  updateAddressVendor(payload) {
    return postAxios("vendor-address/update", payload)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
  updateDocumentsVendor(payload,file_flg) {
    return postAxios("vendor-document/update", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
  updatedeclationVendor(payload,file_flg) {
    return postAxios("vendor-declaration/update", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
  updateAllDocumentsVendor(payload,file_flg) {
    return postAxios("vendor-document/update-all-media", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response.data;
      });
  }
  uploadContractCopyVendor(payload,file_flg) {
    return postAxios("vendor-contract-copy/add", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response.data;
      });
  }  
  updateContractCopyVendor(payload,file_flg) {
    return postAxios("vendor-contract-copy/update", payload,file_flg)
      .then(response => {
        if(response){
          toastMsg(response.message,'success');
        }
        return response.data;
      });
  } 
  getVendorStatusProgress() {
    return getAxios("vendor-log/get")
      .then(response => {
        return response.data;
      });
  }
}

export default new VendorService();
