import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import { RotatingTriangles } from 'react-loader-spinner'
import Sidebar from '../../components/sidebarComponent';
import ContractList from '../../components/legal/contractList';
import LegalService from '../../services/legal-service';
import LegalTabs from "../../components/legal/tabsComponent";
import AuthService  from '../../services/auth.service';
import { useNavigate } from "react-router-dom";

function ContractCopyListing(){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState();
    const [totalResults, setTotalResults] = useState();

    const [toggleState, setToggleState] = useState(1);
    const [legalData, setLegalData] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };
    
    const fetchData = async () => {
        return await LegalService.getContractCopyUsers(currentPage, limit, searchQuery);
    }
    
    useEffect(() => {
        if(!AuthService.isLoggedInUser()){
            navigate(process.env.PUBLIC_URL+"/legal-login");
        }else{
            const fetchDataAndSetState = async () => {
                const result = await fetchData();
                setLegalData(result || []);
                setTotalPages(result?.totalPages);
                setTotalResults(result?.totalResults);
            }
            fetchDataAndSetState();
            setLoading(false);
        }
    }, [toggleState, currentPage, limit, searchQuery]);

    return((loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
    />) : legalData && ( 
            <div className="page-wrapper">
                <Sidebar sidebarFor="Legal"/>
                <div className="content-wrapper">
                    <div className="page-content page-reporters">
                        <div className="page-header">
                            <h4>Contract Copy</h4>
                        </div>
                        <div className="card">
                            <LegalTabs/>
                            <div className="card-body">
                                <div className="tab-wrapper">
                                    <div id="reporters_draft"  className="tab-content">
                                        <ContractList legalData={legalData}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                        pageType="Contract_Listing"
                                        />
                                    </div>                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
export default ContractCopyListing;
