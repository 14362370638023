import React, { useState, useEffect } from "react";
import '../../css/event/adminHomePage.css';
import '../../css/event/adminFile18.css';
import { RotatingTriangles } from 'react-loader-spinner'
import Sidebar from '../../components/event/sidebarComponent';
import EventListing from '../../components/event/eventListComponent';
import AdminService from '../../services/event/admin.service';
import { useNavigate } from "react-router-dom";
import AuthService from '../../services/event/auth.service';

function EventListingData() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState();
    const [totalResults, setTotalResults] = useState();
    const [activeTab, setActiveTab] = useState(1);
    const [toggleState, setToggleState] = useState(1);
    const [eventResult, setEventResult] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    const fetchData = async () => {
        return await AdminService.getAllEvents(currentPage, limit, searchQuery);
    }

    useEffect(() => {
        if (!AuthService.isLoggedInUser()) {
            navigate("/event-admin-login");
          }else{
            const fetchDataAndSetState = async () => {
                const result = await fetchData();
                setEventResult(result || []);
                setTotalPages(result?.totalPages);
                setTotalResults(result?.totalResults);
            }
            fetchDataAndSetState();
            setLoading(false);
          }
       
    }, [toggleState, currentPage, limit, searchQuery]);

    return ((loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
    />) : eventResult && (
        <div className="page-wrapper">
            <Sidebar sidebarFor="Admin"/>
            <div className="content-wrapper">
                <div className="page-content page-reporters">
                    <div className="page-header">
                        <h4>All Events</h4>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="tab-wrapper">
                                <div id="reporters_all" className="tab-content">
                                    <EventListing eventResult={eventResult}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    )
}
export default EventListingData;
