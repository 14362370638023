export default function adminHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  let headers ="";
  if(user){
     headers = {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Authorization": 'Bearer ' + user.token 
    }
  }else{
     headers = {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      "Authorization": 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHRlc3QuY29tIiwicGhvbmVubyI6IjMxNDA4ODg4ODgiLCJpYXQiOjE2ODc5NTkxMTl9.ZVlNhnOZOAU4wEcvNvMYyulHIjjUH_sIe9MifI8E52o' 
    }
  }
   
    return headers; // for Spring Boot back-end    
}