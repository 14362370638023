import React, { useState, useEffect } from "react";
import '../../css/event/adminHomePage.css';
import '../../css/event/adminFile18.css';
import {useParams} from 'react-router-dom';
import { RotatingTriangles } from 'react-loader-spinner'
import Sidebar from '../../components/event/sidebarComponent';
import EventUserListing from '../../components/event/eventUserListComponent';
import AdminService from '../../services/event/admin.service';
import { useNavigate } from "react-router-dom";
import AuthService from '../../services/event/auth.service';

function EventUserList() {
    const navigate = useNavigate();
    const {id} = useParams();
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState();
    const [totalResults, setTotalResults] = useState();
    const [activeTab, setActiveTab] = useState(1);
    const [toggleState, setToggleState] = useState(1);
    const [eventUserResult, setEventUserResult] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    const fetchData = async () => {
        return await AdminService.getAllEventsUser(currentPage, limit, searchQuery,id);
    }

    useEffect(() => {
        if (!AuthService.isLoggedInUser()) {
            navigate("/event-admin-login");
          }else{
            const fetchDataAndSetState = async () => {
                const result = await fetchData();
                setEventUserResult(result || []);
                setTotalPages(result?.totalPages);
                setTotalResults(result?.totalResults);
            }
            fetchDataAndSetState();
            setLoading(false);
          }
       
    }, [toggleState, currentPage, limit, searchQuery]);

    return ((loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
    />) : eventUserResult && (
        <div className="page-wrapper">
            <Sidebar sidebarFor="Admin"/>
            <div className="content-wrapper">
                <div className="page-content page-reporters">
                    <div className="page-header">
                        <h4>Events User</h4>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="tab-wrapper">
                                <div id="reporters_all" className="tab-content">
                                    <EventUserListing eventUserResult={eventUserResult}
                                        currentPage={currentPage}
                                        limit={limit}
                                        totalPages={totalPages}
                                        totalResults={totalResults}
                                        handlePagination={handlePagination}
                                        handleSearch={handleSearch}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    )
}
export default EventUserList;