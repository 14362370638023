import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import '../css/file18.css';
import AuthService from '../services/auth.service';
import AccountService from '../services/account.service';
import { Modal } from 'react-responsive-modal';

function Previewdoc(id){
    const ids =id.id;

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [ vendor, setVendor ] = useState([]);
    const [preview, setPreviews] = useState([]);
    const [modalFilename, setmodalFilename] = useState("");
    const [open, setOpen] = useState(false);

    const handleDownload = (imageUrl) => {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
         
          const fileExtension = imageUrl.split(".").pop().toLowerCase();
          const timestamp = Date.now(); // Unique timestamp
          const filename = `image_${timestamp}.${fileExtension}`; // Unique filename
           a.download = filename;

          a.click();
          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading image:", error);
        });
    };
    const viewPDF = (data, type) => {
      let win = window.open("", "_blank", "titlebar=yes,width=600,height=600");
    
      if (win) {
        const documentContent = `<!DOCTYPE html>
          <html>
            <head>
              <title>PDF in HTML</title>
            </head>
            <body>
              <iframe src="https://docs.google.com/gview?url=${data}&embedded=true" 
                style="width:600px; height:500px;" frameborder="0"></iframe>
            </body>
          </html>`;
    
        win.document.write(documentContent);
    
        // Close the window and reload the page when the window loses focus
        win.addEventListener("blur", () => {
          win.close();
          win = null;
          // window.location.reload();
        });
      }
    };
    
    
  
  
    const onOpenModal = (filename) => {
      setmodalFilename(filename);
      setOpen(true);
  
    };
    const onCloseModal = () => {
      setOpen(false);
      setmodalFilename("");
    };

    useEffect(() => {
         if (!AuthService.isLoggedInUser()) {
            navigate(process.env.PUBLIC_URL + "/account-login");
          } else{
            getVendor();
        }
       async function getVendor() {
        let data = await AccountService.getVendorDetails(ids);
        setVendor(data);
        let d = data[0].vendor_documents;
        setPreviews(data[0].vendor_documents);
        
    }
       getVendor();
       setLoading(false); 
      }, []);

  return(
        <div className="page-contributor-preview preview-component">
            <div className="page-header">
                <h4>Preview Details</h4>
            </div>
            <div className="card">
                <div className="card-body">
                        
{preview.length > 0 && preview.some((document) => document.document_type !== 'signature' && document.document_type !== 'photograph') ? (
  <section className="section-doc-upload">
    <div className="cap-wrapper w-full">
      <div className="form-caption">Documents</div>
      <div className="tble-wrapper">
        <div className="thead">
          <div className="th">Proof Name</div>
          <div className="th attach-file-div">Attach File</div>
          <div className="th attach-file-div">Status</div>
        </div>
        <div className="tbody-wrapper">
          {preview.map((document) => {
            if (document.document_type !== 'signature' && document.document_type !== 'photograph') {
              return (
                <div className="tbody" key={document.id}>
                  <div className="td">
                    <div className="tab-label v-mob">Proof Name</div>
                    <div className="label prof-nam text-upper">{document.document_type}<span className="asterisk">*</span></div>
                  </div>
                  <div className="td attach-file-div">
                    <div className="tab-label v-mob">Attach File</div>
                    <div className="attachment">
                      <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                      <div className="attachment-name">
                     
                      {document.document_path.substring(document.document_path.lastIndexOf('/') + 1).substring(37)}
                        <div className="attachment-action">
                        {document.document_path && document.document_path.endsWith(".pdf") ? (
                        <a href={document.document_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                        </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(document.document_path)}></i></a>
                        )}
                          <i onClick={() => {
                              if (document.document_path && document.document_path.endsWith('.pdf') || document.document_path.endsWith('.xlsx')) {
                                // Open the PDF file using a PDF viewer
                                viewPDF(document.document_path, 'application/pdf');
                              } else {
                                // Handle other file types
                                onOpenModal(document.document_path);
                              }
                            }} className="fi fi-visibility-black"></i>
                        </div>
                      </div>
                                      
                     
                    </div>
                  </div>
                  <div className="td">
                    <div className="tab-label v-mob">Status</div>
                    <div className="label">
                      {document.status === 'Accepted' || document.status === 'accepted' ? (
                        <div className="status-label accepted">
                          <i className="fi fi-verified-black"></i>
                          Accepted
                        </div>
                      ) : document.status === 'Rejected' ? (
                        <div className="status-label rejected">
                          <i className="fi fi-unpublished-black"></i>
                          Rejected
                        </div>
                      ) : (
                        <div className="status-label">
                          Processing
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  </section>
) : (
  <section className="section-doc-upload">
    <div className="cap-wrapper w-full">
      <div className="form-caption">Documents</div>
      <div className="tble-wrapper">
        <div className="thead">
          <div className="th">Proof Name</div>
          <div className="th attach-file-div">Attach File</div>
        </div>
        <div className="tbody-wrapper">
          <center>No data found</center>
        </div>
      </div>
    </div>
  </section>
)}

  





{preview.length > 0 && preview.some((document) => document.document_type === 'signature' || document.document_type === 'photograph') ? (
  <section className="section-photo-signature">
    <div className="cap-wrapper w-full">
      <div className="form-caption">Photo and Signature</div>
      <div className="ps-wrapper">
        <div className="cap-wrapper">
          {preview.map((document) => {
             if (document.document_type === 'photograph') {
              return (
                <div className="image-preview-data" key={document.id}>
                  <div className="label">Photograph</div>
                  <div className="image-preview">
                    <img src={document.document_path} alt={document.document_name} />
                     <div className="download-action">
                      <a  className="download-icon">
                        <i className="fi fi-file-download" onClick={() => handleDownload(document.document_path)}></i>
                      </a>
                    </div> 
                  </div>
                  <div className="td">
                    <div className="tab-label v-mob">Status</div>
                    <div className="label">
                      {document.status === 'Accepted' || document.status === 'accepted' ? (
                        <div className="status-label accepted">
                          <i className="fi fi-verified-black"></i>
                          Accepted
                        </div>
                      ) : document.status === 'Rejected' ? (
                        <div className="status-label rejected">
                          <i className="fi fi-cross-black"></i>
                          Rejected
                        </div>
                      ) : (
                        <div className="status-label">
                          <i className="fi fi-info-black"></i>
                          Processing
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="cap-wrapper">
          {preview.map((document) => {
            if (document.document_type === 'signature') {
              return (
                <div className="sign-preview-data" key={document.id}>
                  <div className="label">Signature</div>
                  <div className="image-preview">
                    <img src={document.document_path} alt={document.document_name} />
                    <div className="download-action">
                      <a className="download-icon">
                        <i className="fi fi-file-download" onClick={() => handleDownload(document.document_path)}></i>
                      </a>
                    </div>
                  </div>                         
                  <div className="td">
                    <div className="tab-label v-mob">Status</div>
                    <div className="label">
                      {document.status === 'Accepted' ? (
                        <div className="status-label accepted">
                          <i className="fi fi-verified-black"></i>
                          Accepted
                        </div>
                      ) : document.status === 'Rejected' ? (
                        <div className="status-label rejected">
                          <i className="fi fi-cross-black"></i>
                          Rejected
                        </div>
                      ) : (
                        <div className="status-label">
                          <i className="fi fi-info-black"></i>
                          Processing
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            } 
            return null;
          })}
        </div>
      </div>
    </div>
  </section>
) : (
    <section className="section-photo-signature">
    <div className="cap-wrapper w-full">
      <div className="form-caption">Photo and Signature</div>
      <div className="ps-wrapper">
        <div className="cap-wrapper">
          {preview.map((document) => {
            if (document.document_type === 'signature') {
             
            } 
            return null;
          })}
        </div>
        <div className="cap-wrapper">
          {preview.map((document) => {
             if (document.document_type === 'photograph') {
              
            }
            return null;
          })}
        </div>
      </div>
    </div>
  </section>
  )}







                   
                      
                        
                </div>
            </div>
            <Modal open={open} onClose={onCloseModal} center>
          <img style={{ width: "400px", height: "auto" }} src={modalFilename} alt="show image" />
        </Modal>

        
        </div>
    )
}
export default Previewdoc;
