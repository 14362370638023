import React, { useState, useEffect } from "react";
import LegalService from '../../services/legal-service';
import { useNavigate, useParams } from "react-router-dom";
import { ColorRing } from 'react-loader-spinner'

function LegalDocComponent(props){
    const navigate = useNavigate();
    const [vendor_doc, setDoc] = useState({});
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 10000;//10 sec 
    const [file_scanned_contract_copy, setFileScannedContractCopy] = useState({name:'NULL'});
    const [error_contract_copy, setErrorContractCopy] = useState({});
    const initialState = { alt: "", src: "" };
    const [{ alt_contract_copy }, setContractCopyPreview] = useState(initialState);
    const [elementVisible, setElementVisible] = useState(false);
    const params = useParams();
    const id = params.id;
    let contract_copy = '';

    const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

    const  handleChangePan = (event) => {
        const valid=ImageValidation(event.target.files[0]);
       
        if(valid.status){
            setFileScannedContractCopy(event.target.files[0]);
            setSelectedFile(event.target.files[0]);
            setElementVisible(true);
            setIsFilePicked(false);
        }else{
            event.target.value = null;
            setElementVisible(false);
            setSelectedFile('');
            setIsFilePicked(false);
            // setFileScannedContractCopy({name:'NULL'});
        }
        setErrorContractCopy(valid);
    }

    const  deleteContratCopy = (event) => {
        event.target.value = null;
        setElementVisible(false);
        setSelectedFile('');
        setIsFilePicked(false);
        setFileScannedContractCopy({name:'NULL'});
    }

    const ImageValidation =(file)=>{
        var filePath = file.name;
        // Allowing file type
        var allowedExtensions =  /(\.pdf)$/i;
        if (!allowedExtensions.exec(filePath)) {
            return {status:false,"msg":"Invalid file type"};
            
        }else if (file.size >= 25000000) { // Size of document should be between 4KB and 25MB.
            return {status:false,"msg":"File size should not be more than 25 MB."};
        } 
        return {status:true,"msg":""};
  }

   

    //Extract only contract-copy from the documents data
    {props.vendordocuments && props.vendordocuments.map((documents, index) => {
        if(documents.document_type === 'contract copy legal') {
            contract_copy = documents.document_path;
        }
       
    })}
    
    const FormValidation =(e)=>{
        let isValid= true;
        if(file_scanned_contract_copy.name != 'NULL'){
            if(file_scanned_contract_copy.name){
                if (typeof file_scanned_contract_copy.name == 'undefined') {
                    setErrorContractCopy({status:false,"msg":"Please upload contract copy"});
                    isValid=false;
                }
            }else if(file_scanned_contract_copy.document_name){
                if (typeof file_scanned_contract_copy.document_name == 'undefined') {
                    setErrorContractCopy({status:false,"msg":"Please upload contract copy"});
                    isValid=false;
                }
            }
        }else{
            if (file_scanned_contract_copy.name == 'NULL') {
                setErrorContractCopy({status:false,"msg":"Please upload contract copy"});
                isValid=false;
            }
        }
        return isValid;
      }
    
      useEffect(() => {
        if(props.vendordocuments.length > 0){
            setDoc(props.vendordocuments);
            props.vendordocuments.map(function(val, index){
                if(val.document_type === "contract copy legal"){
                    // contract_copy = val.document_path;
                    setFileScannedContractCopy(val);
                    setContractCopyPreview(
                        {
                            src_contract_copy:val.document_path,
                            alt_contract_copy:val.document_name
                        }
                  );
                  setElementVisible(false);
                  setIsFilePicked(true);
                  setSelectedFile('');
                }
            })
             setSelectedFile('');
             setElementVisible(false);
        }     
       },[props]);
       const onSubmit = async (event) => {
        event.preventDefault();
        const isValid= await FormValidation(event);
        if (isValid) {
            setBtnDisable(true);
            if(file_scanned_contract_copy.name != 'NULL'){
                const formData = new FormData();
                formData.append('vendorId', id);
                
                if(file_scanned_contract_copy.name){
                    formData.append('file_scanned_contract_copy', file_scanned_contract_copy);
                }else if(file_scanned_contract_copy.document_name){
                    formData.append('file_scanned_contract_copy', file_scanned_contract_copy);
                }
               
                async function setContractCopy() {
                    if (contract_copy) {
                        let data = await LegalService.updateUploadContractCopy(formData,true);
                        if(!data){
                            return false;
                        }
                    }else{
                        let data = await LegalService.setUploadContractCopy(formData,true);
                        if(!data){
                            return false;
                        }
                    }
                        navigate(process.env.PUBLIC_URL+"/legal-listing");
                    }
                    setTimeout(() => {
                        setBtnDisable(false);
                    }, MINUTE_MS);

                    setContractCopy(); 
                }
            }
           
      }

    console.log('elementVisible',elementVisible);

    return(     
        <form action="" onSubmit={onSubmit}>
            <input type="hidden" id="vendorId" name="vendorId" value={id} />
             <div className="rd-section upload-section">
                <h3 className="section-title">Upload Contract Copy</h3>
            <div className="upload-contract-copy">
                <label htmlFor="">Upload contract copy in pdf format. Maximum file size (25 MB)</label>
                <div className="upload-cc-wrapper">
                    <input type="file" id="file_scanned_contract_copy" name="file_scanned_contract_copy" onChange={handleChangePan}  accept=".pdf" className="file-input-hide"  />
                    <label htmlFor={ isFilePicked ? 'file_scanned_contract_copy disabled' : 'file_scanned_contract_copy' } className="drop-files" >
                        <div className="icon-holder">
                            <i className="fi fi-upload-documnet"></i>
                        </div>
                        { !isFilePicked ? (
                            <div className="text">Drop files <span className="text-blue">browse</span> through your machine.</div>
                        ) : (
                            <div className="text">Drop files after reject below selected file</div>
                        )}
                    </label>
                    <label className="error"> {error_contract_copy ? error_contract_copy.msg : ""} </label>
                    {elementVisible ? (
                        <div id="temp_upload_div" className="temp-upload-file">
                            <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                            <div id="upload_file_name" className="upload_file_name">{selectedFile.name}</div>
                            <div id="remove_file" className="remove-icon" onClick={deleteContratCopy}><i className="fi fi-close-black"></i></div>
                        </div>
                    ) : (
                        isFilePicked ? (
                            <div id="temp_upload_div" className="temp-upload-file">
                                <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                <div id="upload_file_name" className="upload_file_name">{alt_contract_copy}</div>
                                <div id="remove_file" className="remove-icon" onClick={deleteContratCopy}><i className="fi fi-close-black"></i></div>
                            </div>
                        ) : (
                            <p>Select a file to show contract copy details</p>
                        )
                    )}
                    
                </div>
            </div>
        </div>
        <div className="fg form-action">
            {(btn_disable) ? ( <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
            />) : (<button type="submit" className="submit-btn">Send to Vendor</button>)}
        </div>
    </form>
        )
}
export default LegalDocComponent;