import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import Sidebar from '../../components/sidebarComponent';
import { useNavigate } from "react-router-dom";
import AuthService  from '../../services/auth.service';
import ManagementList from '../../components/management/managementList';
import ManagementService from '../../services/managment-service';

function ManagementDashboard(){
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState();
    const [totalResults, setTotalResults] = useState();

    const [toggleState, setToggleState] = useState(1);
    const [managementData, setManagementData] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    const toggleTab = (index, pageSize) => {
        setToggleState(index);
        setLimit(pageSize);
        setSearchQuery("");
        setCurrentPage(1);
    };
    
    const fetchData = async () => {

        switch (toggleState) {
            case 1:
                return await ManagementService.getAllUsers(currentPage, limit, searchQuery);
            case 2:
                return await ManagementService.getNewUsers(currentPage, limit, searchQuery);
            case 3:
                return await ManagementService.getSubmittedUsers(currentPage, limit, searchQuery);
            case 4:
                return await ManagementService.getDraftedUsers(currentPage, limit, searchQuery);
            case 5:
                return await ManagementService.getContractUsers(currentPage, limit, searchQuery);
            default: 
                return [];
        }
    }
    
    useEffect(() => {
        if(!AuthService.isLoggedInUser()){
            navigate(process.env.PUBLIC_URL+"/management-login");
        }else{
            const fetchDataAndSetState = async () => {
                const result = await fetchData();
                setManagementData(result || []);
                setTotalPages(result?.totalPages);
                setTotalResults(result?.totalResults);
            }
            fetchDataAndSetState();
        }
    }, [toggleState, currentPage, limit, searchQuery]);

    return(        
        <div className="page-wrapper">
        <header className="file-header">
            <img src={`${process.env.PUBLIC_URL}/img/network18_creative.png`} className="nw-logo" alt=" " />
            <div className="hamburger-icon" >
                <div className="bar1"></div>
                <div className="bar2"></div>
                <div className="bar3"></div>
            </div>
        </header>
        <Sidebar sidebarFor="Management"/>
        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-reporters">
                <div className="page-header">
                    <h4>New Reporters</h4>
                </div>
                <div className="card">
                    <div className="card-head">
                        <div className="tab-list">
                            <button className={toggleState === 1 ? 'tablink active-tabs' : "tablink"} onClick={() => toggleTab(1, 10)}>All</button>
                            <button className={toggleState === 2 ? 'tablink active-tabs' : "tablink"} onClick={() => toggleTab(2, 10)}>New</button>
                            <button className={toggleState === 3 ? 'tablink active-tabs' : "tablink"} onClick={() => toggleTab(3, 10)}>Submitted</button>
                            <button className={toggleState === 4 ? 'tablink active-tabs' : "tablink"} onClick={() => toggleTab(4, 10)}>Draft</button>
                            <button className={toggleState === 5 ? 'tablink active-tabs' : "tablink"} onClick={() => toggleTab(5, 10)}>Contract Copy</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="tab-wrapper">
                            <div id="reporters_all"  className={toggleState === 1 ? 'tab-content active-content' : "tab-content hidden"}>
                                <ManagementList managementData={managementData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                            <div id="reporters_new" className={toggleState === 2 ? 'tab-content active-content' : "tab-content hidden"}>
                                <ManagementList managementData={managementData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                            <div id="reporters_submitted" className={toggleState === 3 ? 'tab-content active-content' : "tab-content hidden"}>
                                <ManagementList managementData={managementData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                            <div id="reporters_draft" className={toggleState === 4 ? 'tab-content active-content' : "tab-content hidden"}>
                                <ManagementList managementData={managementData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                            <div id="contract_copy" className={toggleState === 5 ? 'tab-content active-content' : "tab-content hidden"}>
                                <ManagementList managementData={managementData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default ManagementDashboard;
