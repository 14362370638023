import { Link, useLocation } from "react-router-dom";

function LegalTabs() {
    const location = useLocation();
    return (
        <div className="card-head">
            <div className="tab-list">
                <Link to={`${process.env.PUBLIC_URL}/legal-all-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/legal-all-listing` ? "tablink tab-active" :"tablink"}>All</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/legal-new-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/legal-new-listing` ? "tablink tab-active" :"tablink"}>New</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/legal-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/legal-listing` ? "tablink tab-active" :"tablink"}>Legal</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/legal-contract-copy-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/legal-contract-copy-listing` ? "tablink tab-active" :"tablink"}>Contract Copy</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/legal-complete-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/legal-complete-listing` ? "tablink tab-active" :"tablink"}>Complete</button>
                </Link>
            </div>
        </div>
    )
}

export default LegalTabs;