import React,{useState,useEffect} from "react";
import AuthService  from '../../services/event/auth.service';
import { useNavigate,Link,useLocation,NavLink } from "react-router-dom";

function Sidebar(props) {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [ismenuOpen, setIsMenuOpen] = useState(false);

        const adminLogOut = async (event) => {
            async function logOut() {
                await AuthService.logout();
                navigate(process.env.PUBLIC_URL + "/event-admin-login");
            }
            logOut();
        }
        

        const sadminLogOut = async (event) => {
            async function slogOut() {
                await AuthService.slogout();
                navigate(process.env.PUBLIC_URL + "/event-super-admin-login");
            }
            slogOut();
        }

          const ToggleSidebar = () => {
            ismenuOpen === true ? setIsMenuOpen(false) : setIsMenuOpen(true);
        }

    return (
        <>
            <header className="file-header">
                <img src={`${process.env.PUBLIC_URL}/img/network18_creative.png`} className="nw-logo" alt=" " />
                <div className={ ismenuOpen == true ?  'hamburger-icon change'   :  'hamburger-icon' }  onClick={ToggleSidebar}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </header>
        <div className={ ismenuOpen == true ?  'sidebar-wrapper sidebar-width'   :  'sidebar-wrapper' } id="sidebar-wrapper">
            <div className="logo-segment">
                <a href="">
                    <img src={`${process.env.PUBLIC_URL}/img/network18_creative.png`} alt="" />
                </a>
            </div>
            <div className="sidebar-menu">
                        {props.sidebarFor==="Admin" && (
                             <ul>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/event-list` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/event-list`}><i className="fi fi-form"></i>Event List</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/event-add` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/event-add`}><i className="fi fi-person-black"></i>Event Add</Link>
                            </li>
                            <li>
                                <a onClick={adminLogOut}><i className="fi fi-person-black"></i> Logout</a>
                            </li>
                            </ul>
                        )}
                        {props.sidebarFor==="Super-Admin" && (
                            <ul>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/user-add` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/user-add`}><i className="fi fi-person-black"></i>User Add</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/user-list` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/user-list`}><i className="fi fi-person-black"></i>User List</Link>
                            </li>
                            <li>
                                <a onClick={sadminLogOut}><i className="fi fi-person-black"></i> Logout</a>
                            </li>
                        </ul>
                        )}
            </div>
        </div>
        <div className={ ismenuOpen == true ?  'show-overlay overlay'   :  'overlay' } id="overlay"></div>
        </>

    )
}
export default Sidebar;