import { getAxios, postAxios, toastMsg } from '../utils/ApiHelper';

class AccountService {
  getAllUsers(page, pageSize, searchQuery) {
    return getAxios(`account/get-all?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getNewUsers(page, pageSize, searchQuery) {
    return getAxios(`account/get-new-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getSubmittedUsers(page, pageSize, searchQuery) {
    return getAxios(`account/get-submitted-users?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getCompleteUsers(page, pageSize, searchQuery) {
    return getAxios(`account/get-complete-user?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  superAdminLogin(payload) {
    return postAxios("user/signin", payload)
      .then(response => {
        if (typeof response.data !== 'undefined' && typeof response.data.token !== 'undefined' && response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
          if (response.data) {
            toastMsg(response.data.message, 'success');
          }
        }
        return response;
      });
  }

  logout() {
    localStorage.removeItem("user");
    toastMsg("Logout successfully", 'success');
  }

  setVendorCode(id, payload) {
    return postAxios(`account/update/${id}`, payload)
      .then(response => {
        if (response) {
          toastMsg(response.message, 'success');
        }
        return response;
      });
  }

  getVendorDetails(id) {
    return getAxios(`account/get/${id}`)
      .then(response => {
        return response.data;
      });
  }

}

export default new AccountService();
