import {getAxios,postAxios,toastMsg} from '../utils/ApiHelper';

class SuperAdminService {
  getAllStaff(page, pageSize, searchQuery) {
    return getAxios(`user/get-all?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getAdminStaff(page, pageSize, searchQuery) {
    return getAxios(`user/get-admin?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getAccountStaff(page, pageSize, searchQuery) {
    return getAxios(`user/get-account?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getManagementStaff(page, pageSize, searchQuery) {
    return getAxios(`user/get-management?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getLegalStaff(page, pageSize, searchQuery) {
    return getAxios(`user/get-legal?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getFinanceStaff(page, pageSize, searchQuery) {
    return getAxios(`user/get-finance?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getSuperAdminStaff(page, pageSize, searchQuery) {
    return getAxios(`user/get-superadmin?page=${page}&pageSize=${pageSize}&search=${searchQuery}`)
      .then(response => {
        return response.data;
      });
  }

  getStaff(id) {
    return getAxios(`user/get/${id}`)
      .then(response => {
        return response.data;
      });
  }

  getStates() {
    return getAxios("state/get-all")
      .then(response => {
        return response.data;
      });
  }

  getDistricts(statename) {
    return getAxios(`district/get-all/${statename}`)
      .then(response => {
        return response.data;
      });
  }

  addStaff(payload) {
    return postAxios("user/add", payload)
      .then(response => {
        if(response.data){
          toastMsg(response.message,'success');
        }
        return response.data;
      });
  }
  updateStaff(id, payload) {
    return postAxios(`user/update/${id}`, payload)
      .then(response => {
        if(response.status === 'success'){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
  deactivateStaff(id) {
    return postAxios(`user/deactivate/${id}`)
      .then(response => {
        if(response.status === 'success'){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
  activateStaff(id) {
    return postAxios(`user/activate/${id}`)
      .then(response => {
        if(response.status === 'success'){
          toastMsg(response.message,'success');
        }
        return response;
      });
  }
}

export default new SuperAdminService();
