import React, { useState, useEffect } from "react";
import '../../css/signUpPage.css';
import Sidebar from '../../components/sidebarComponent';
import { useNavigate } from "react-router-dom";
import AuthService from '../../services/auth.service';
import { useFormInputValidation } from "react-form-input-validation";
import { RotatingTriangles } from 'react-loader-spinner';
import 'react-responsive-modal/styles.css';
import { ColorRing } from 'react-loader-spinner'

function SignUpPage() {
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState("password");
    const [passwordCnfType, setCnfPasswordType] = useState("password");
    const [loading, setLoading] = useState(false);
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 1000;//10 sec 

    const [fields, errors, form] = useFormInputValidation({
        email_address: "",
        phone_number: "",
        password: "",
        password_confirmation: "",
    }, {
        email_address: "required|email",
        phone_number: "required|numeric|digits:10",
        password: "required|password_validation",
        password_confirmation: "required|same:password|password_validation",
    });
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleCnfPassword = () => {
        if (passwordCnfType === "password") {
            setCnfPasswordType("text")
            return;
        }
        setCnfPasswordType("password")
    }

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            setBtnDisable(true);

            let payload = { "email": fields.email_address, "phoneno": fields.phone_number, "password": fields.password }
            async function register() {
                let data = await AuthService.register(payload);
                if (data) {
                    await new Promise(resolve => setTimeout(resolve, MINUTE_MS));
                    navigate(`${process.env.PUBLIC_URL}/admin-all-listing`);                
                }  else {
                    // document.getElementById("registerform").reset();
                    // fields.email_address = "";
                    // fields.phone_number = "";
                    setBtnDisable(false);
                    return false;
                }
            }
            await register();
        }
    }
    useEffect(() => {
        if (AuthService.isLoggedInVendor()) {
            navigate(process.env.PUBLIC_URL + "/admin-login");
        }
        form.registerAsync("password_validation", function (
            str,
            attribute,
            req,
            passes
        ) {
            var specials = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g;
            if (!specials.test(str))
                passes(false, "Password should have minimum eight characters, at least one letter, one number and one special character");
            else passes();
        });
    }, []);
    return (
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : (
       
        <div className="page-wrapper">
        <Sidebar sidebarFor="Admin" />

        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-contributor contributor-basic-details">
                <div className="page-header">
                    <h4>Sign Up to Network 18</h4>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="off" onSubmit={onSubmit} className="signupform" id="registerform">
                            <div className="form-col-2 form-horizontal"  style={{justifyContent: 'center'}}>
                                <div className="form-cap-card">
                                    <div className="form-caption"></div>
                                    
                                    <div className="formGroup">
                                        
                                        <label htmlFor="" className="input-label">Email <span className="asterisk">*</span></label>
                                        <div className="form-wrapper">
                                            <input  id="emailPhoneNumber" placeholder="Email" className="input-control" type="email" name="email_address" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.email_address} />
                                            <label className="error"> {errors.email_address ? errors.email_address : ""} </label>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="" className="input-label">Phone Number</label>
                                        <div className="form-wrapper">
                                        <input id="phoneNumber" placeholder="Phone Number" className="input-control" type="tel" name="phone_number" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.phone_number} pattern="[0-9]{10}" maxLength={10}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                      }}
                                        />
                                <label className="error"> {errors.phone_number ? errors.phone_number : ""} </label>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="" className="input-label">Password</label>
                                        <div className="form-wrapper">
                                        <div className="pwd-wpr">
                                    <input type={passwordType} id="password" placeholder="Password" className="input-control" name="password" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password} data-attribute-name="password" data-async  />
                                    <span className="pi-wpr" ><i onClick={togglePassword} className="fi fi-visibility-black-o"></i></span>
                                </div>
                                <label className="error"> {errors.password ? errors.password : ""} </label>
                                </div>
                                    </div>
                                    <div className="formGroup">
                                        <label htmlFor="" className="input-label">Confirm Password</label>
                                        <div className="form-wrapper">
                                        <div className="pwd-wpr">
                                <input type={passwordCnfType} id="password_confirmation" placeholder="Confirm Password" className="input-control" name="password_confirmation" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password_confirmation} data-attribute-name="password_confirmation" data-async />
                                    <span className="pi-wpr" ><i onClick={toggleCnfPassword} className="fi fi-visibility-black-o"></i></span>
                                </div>
                                <label className="error"> {errors.password_confirmation ? errors.password_confirmation : ""} </label>                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="fg form-action" style={{ display: 'flex', justifyContent: 'center' }}>

                            {(btn_disable) ? (<ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                        />) : (<button type="submit" className="submit-btn">Create Vendor</button>)}
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
          )
    )
     }
export default SignUpPage;
