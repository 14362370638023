import React, { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../pagination";
import { format } from 'date-fns'

function CompleteList(props) {

    const { legalData, currentPage, limit, totalPages, totalResults, handlePagination, handleSearch, pageType } = props;
    const [activeRows, setActiveRows] = useState([]);

    const toggleRow = (index) => {
      setActiveRows(activeRows.includes(index) 
        ? activeRows.filter((i) => i !== index) 
        : [...activeRows, index]
      );
    };

    return (
        <div>
            <div className="search-filter">
                <div className="search-form has-search-icon">
                    <input type="text" className="input-control" placeholder="Search" onChange={handleSearch}></input>
                    <span className="icon-left">
                        <i className="fi fi-search-black"></i>
                    </span>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                        <th></th>
                             <th>Name</th>
                             <th>Email</th>
                             <th>District</th>
                             <th>Submitted On</th>
                             <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {legalData && legalData.rows && legalData.rows.map((users, index) => (
                        <>
                        <tr className={`tblflx ${activeRows.includes(index) ? "tr_active" : ""}`}>
                            <td><a className="tr_toggle_action" onClick={() => toggleRow(index)}><i className="fi fi-chevron-right-black"></i></a></td>
                            <td data-label="Name">{users?.vendor_info?.firstname} {users?.vendor_info?.lastname}</td>
                            <td data-label="Email">{users?.email}</td>
                            <td data-label="District">{users?.vendor_address?.district}</td>
                            <td data-label="Submitted On">{format(new Date(users?.created_at), 'd MMMM yyyy')}</td>
                            <td data-label="Status">{users?.status}</td>  
                           
                        </tr>                        
                        </>
                         ))}
                    </tbody>
                </table>
            </div>
            {legalData.rows && legalData.rows.length > 0 && (
            <Pagination 
            currentPage={currentPage}
            limit={limit}
            totalPages={totalPages}
            totalResults={totalResults}
            handlePagination={handlePagination}
            />
            )}
        </div>
    )
}
export default CompleteList;
