import React, { useState, useEffect, useCallback } from "react";
import SuperAdminService from '../../services/superAdmin.service';
import { RotatingTriangles } from 'react-loader-spinner';
import Select from 'react-select';
import * as Yup from "yup";

function StaffModal(props) {

    const { states, mode, isModalOpen, closeModal, loading, fetchDataAndSetState } = props;
    const [staffData, setStaffData] = useState({});
    const [districts, setDistricts] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [formData, setFormData] = useState({ firstname: "", lastname: "", middlename: "", role: "", email: "", phoneno: "", state: "", city: "" });
    const [errors, setErrors] = useState({});

    const roleOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'Account', label: 'Account' },
        { value: 'Finance', label: 'Finance' },
        { value: 'Legal', label: 'Legal' },
        { value: 'Management', label: 'Management' },
        { value: 'Super Admin', label: 'Super Admin' },
    ];

    const handleRoleChange = useCallback(async (selectedOptions) => {
        setSelectedRoles(selectedOptions);
        const roles = await new Promise((resolve) =>
            setTimeout(() => resolve(selectedOptions.map((option) => option.value).join(",")), 0)
        );
        setFormData((prevState) => ({ ...prevState, role: roles }));
    }, []);

    async function handleStateChange(event) {
        const { name, value } = event.target;
        formData.state = value;
        const districtsData = await SuperAdminService.getDistricts(value);
        setDistricts(districtsData);
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (err) {
            setErrors({ ...errors, [name]: err.errors[0] });
        }
    }

    const schema = Yup.object().shape({
        firstname: Yup.string().required("First name is required").matches(/^[A-Za-z]+$/, "Only letters are allowed").max(30, "Max length is 30 characters").min(3, "Min length is 3 characters"),
        lastname: Yup.string().optional()
            .test(
                "is-valid-lastname",
                "Last name must contain only letters",
                (value) => !value || /^[A-Za-z]+$/.test(value)
            )
            .test(
                "is-valid-lastname-length",
                "Last name must be between 3 and 30 characters long",
                (value) => !value || (value.length >= 3 && value.length <= 30)
            ),
        middlename: Yup.string().optional()
            .test(
                "is-valid-lastname",
                "Middle name must contain only letters",
                (value) => !value || /^[A-Za-z]+$/.test(value)
            )
            .test(
                "is-valid-lastname-length",
                "Middle name must be between 3 and 30 characters long",
                (value) => !value || (value.length >= 3 && value.length <= 30)
            ),
        role: Yup.string().required("Role is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        phoneno: Yup.string().required("Phone number is required").matches(/^[0-9]+$/, "Only numbers are allowed").length(10, "Phone number should be 10 digits"),
        state: Yup.string().required("State is required"),
        city: Yup.string().required("City is required")
    });

    const resetFieldsAndErrors = () => {
        setFormData({
            firstname: "",
            lastname: "",
            middlename: "",
            role: "",
            email: "",
            phoneno: "",
            state: "",
            city: "",
        });
        setErrors({});
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await schema.validate(formData, { abortEarly: false });
            let id = staffData.id;
            async function addStaffDetails() {
                if (mode === 'add') {
                    let data = await SuperAdminService.addStaff(formData);
                    closeModal();
                    await fetchDataAndSetState();
                    if (!(data?.data)) {
                        return false;
                    }
                    resetFieldsAndErrors();
                } else {
                    let data = await SuperAdminService.updateStaff(id, formData);
                    if (data?.status === 'fail' || data?.status === 'error') {
                        return false;
                    }
                }
                closeModal();
                await fetchDataAndSetState();
            }
            addStaffDetails();
        } catch (err) {
            const newErrors = {};
            err.inner.forEach((e) => {
                newErrors[e.path] = e.message;
            });
            setErrors(newErrors);
        }
    };

    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (err) {
            setErrors({ ...errors, [name]: err.errors[0] });
        }
    };

    useEffect(() => {
        if (mode === 'add') {
            setSelectedRoles([]);
            setStaffData({});
            resetFieldsAndErrors();
        }
        if (props.staff) {
            setStaffData(props.staff);
            setErrors({});
            if (props.staff.state) {
                async function getDistricts() {
                    const districtsData = await SuperAdminService.getDistricts(props.staff.state);
                    setDistricts(districtsData);
                }
                getDistricts();
            }
            if (props.staff.role) {
                const optionsArray = props.staff.role.split(",");
                const preSelectedOptions = optionsArray.map(option => ({ value: option, label: option }));
                setSelectedRoles(preSelectedOptions);
            }
            setFormData({
                firstname: props.staff.firstname,
                lastname: props.staff.lastname,
                middlename: props.staff.middlename,
                role: props.staff.role,
                email: props.staff.email,
                phoneno: props.staff.phoneno,
                state: props.staff.state,
                city: props.staff.city,
            });
        }
    }, [props.staff, mode]);

    return (
        <div id={`${mode}StaffModal`} className={isModalOpen === true ? 'staff-modal active-content' : "staff-modal"}>
            <div className="staff-modal-content">
                <div className="staff-mhead">
                    <div className="staff-mtitle">
                        <h3>{mode === 'add' ? 'Add Staff' : 'Edit Staff'}</h3>
                    </div>
                    <div className="staff-mclose" onClick={closeModal}>
                        <i className="fi fi-close"></i>
                    </div>
                </div>
                <div className="staff-mbody">
                    {loading ? (
                        <div className="loader-container">
                            <RotatingTriangles
                                height="100"
                                width="100"
                                color="#fff"
                                ariaLabel="rotating-triangles-loading"
                                wrapperStyle={{}}
                                wrapperClass="triangle-loading"
                                visible={loading}
                            /></div>
                    ) : (
                        <form action="" onSubmit={handleSubmit}>
                            <div className="staff-form form-horizontal">
                                <div className="formGroup">
                                    <label htmlFor="firstname">First Name <span className="asterisk">*</span></label>
                                    <div className="form-wrapper">
                                        <input type="text" placeholder="Enter First Name" className="input-control" name="firstname" onChange={handleChange} value={formData.firstname} data-attribute-name="First Name" />
                                        <div className="error"> {errors.firstname ? errors.firstname : ""} </div>
                                    </div>
                                </div>
                                <div className="formGroup">
                                    <label htmlFor="lastname">Last Name</label>
                                    <div className="form-wrapper">
                                        <input type="text" placeholder="Enter Last Name" className="input-control" name="lastname" onChange={handleChange} value={formData.lastname} data-attribute-name="Last Name" />
                                        <div className="error"> {errors.lastname ? errors.lastname : ""} </div>
                                    </div>
                                </div>
                                <div className="formGroup">
                                    <label htmlFor="middlename">Middle Name</label>
                                    <div className="form-wrapper">
                                        <input type="text" placeholder="Enter Middle Name" className="input-control" name="middlename" onChange={handleChange} value={formData.middlename} data-attribute-name="Middle Name" />
                                        <div className="error"> {errors.middlename ? errors.middlename : ""} </div>
                                    </div>
                                </div>
                                <div className="formGroup">
                                    <label htmlFor="role">Org. Role <span className="asterisk">*</span></label>
                                    <div className="form-wrapper">
                                        <Select name="role" id="role"
                                            value={selectedRoles}
                                            //
                                            onChange={handleRoleChange}
                                            options={roleOptions}
                                            isMulti={true}
                                            placeholder="Select roles"
                                        />
                                        <div className="error"> {errors.role ? errors.role : ""} </div>
                                    </div>
                                </div>
                                <div className="formGroup">
                                    <label htmlFor="email">Email <span className="asterisk">*</span></label>
                                    <div className="form-wrapper">
                                        <input type="text" placeholder="Enter Email" className="input-control" name="email" onChange={handleChange} value={formData.email} />
                                        <div className="error"> {errors.email ? errors.email : ""} </div>
                                    </div>
                                </div>
                                <div className="formGroup">
                                    <label htmlFor="phoneno">Phone Number <span className="asterisk">*</span></label>
                                    <div className="form-wrapper">
                                        <input type="text" placeholder="Enter Phone no" className="input-control" name="phoneno" onChange={handleChange} value={formData.phoneno} />
                                        <div className="error"> {errors.phoneno ? errors.phoneno : ""} </div>
                                    </div>
                                </div>
                                <div className="formGroup">
                                    <label htmlFor="state">State/ UT <span className="asterisk">*</span></label>
                                    <div className="form-wrapper">
                                        <select name="state" id="state" className="input-control w-half" onChange={(event) => handleStateChange(event)} value={formData.state}>
                                            <option value="null">Select</option>
                                            {states && states.map((state) => (
                                                <React.Fragment key={state.id}>
                                                    <option id={state.id} value={state.name}>{state.name}</option>
                                                </React.Fragment>
                                            ))}
                                        </select>
                                        <div className="error"> {errors.state ? errors.state : ""} </div>
                                    </div>
                                </div>
                                <div className="formGroup">
                                    <label htmlFor="city">City/ District <span className="asterisk">*</span></label>
                                    <div className="form-wrapper">
                                        <select name="city" id="city" className="input-control w-half" onChange={handleChange} value={formData.city}>
                                            <option value="null">Select</option>
                                            {districts && districts.map((district) => (
                                                <React.Fragment key={district.id}>
                                                    <option id={district.id} value={district.name}>{district.name}</option>
                                                </React.Fragment>
                                            ))}
                                        </select>
                                        <div className="error"> {errors.city ? errors.city : ""} </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-action">
                                <button type="submit" className="submit-btn">{mode === 'add' ? 'Send Invite' : 'Save'}</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}

export default StaffModal;
