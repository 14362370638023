import { Link, useLocation } from "react-router-dom";

function AccountTabs() {
    const location = useLocation();
    return (
        <div className="card-head">
            <div className="tab-list">
                <Link to={process.env.PUBLIC_URL+`/account-all-listing`} className="tabs">
                    <button className={location.pathname=="/account-all-listing" ? "tablink tab-active" :"tablink"}>All</button>
                </Link>
                <Link to={process.env.PUBLIC_URL+`/account-new-listing`} className="tabs">
                    <button className={location.pathname=="/account-new-listing" ? "tablink tab-active" :"tablink"}>New</button>
                </Link>
                <Link to={process.env.PUBLIC_URL+`/account-submitted-listing`} className="tabs">
                    <button className={location.pathname=="/account-submitted-listing" ? "tablink tab-active" :"tablink"}>Submitted</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/account-complete-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/account-complete-listing` ? "tablink tab-active" :"tablink"}>Complete</button>
                </Link>
            </div>
        </div>
    )
}

export default AccountTabs;