import React,{useState,useEffect} from "react";
import { useFormInputValidation } from "react-form-input-validation";
import VendorService  from '../services/vendor.service';
import { ColorRing } from 'react-loader-spinner'
import { RotatingTriangles } from 'react-loader-spinner'


function VendorBasicDetailsComponent(props){
    const [loading, setLoading] = useState(true);
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 6000;//10 sec 
    const [vendor_info, setvendorInfo] = useState({});
    const [fields, errors, form] = useFormInputValidation({
        first_name: "",
        last_name: "",
        title: "",
        middle_name: "",
        marital_status: "",
        nationality: "",
        dob: "",
        gender: "",
        father_first_name: "",
        father_last_name: "",
        father_title: "",
        father_middle_name: "",
        mother_first_name: "",
        mother_last_name: "",
        mother_title: "",
        mother_middle_name: ""
        
    }, {
        // first_name: "required|only_letters|max:30|min:3",
        first_name: "required|max:30|min:3",
        last_name: "required|max:30|min:3",
        title: "required",
        middle_name: "max:30|min:3",
        marital_status: "required",
        nationality: "required",
        dob: "required|date",
        gender: "required",
        father_first_name: "required|max:30|min:3",
        father_last_name: "required|max:30|min:3",
        father_title: "required",
        father_middle_name: "max:30|min:3",
        mother_first_name: "required|max:30|min:3",
        mother_last_name: "required|max:30|min:3",
        mother_title:"required",
        mother_middle_name:"max:30|min:3",
    });
    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        if (isValid) {
            setBtnDisable(true);
        let payload={
            "title":fields.title,
            "firstname":fields.first_name,
            "lastname":fields.last_name,
            "middlename":fields.middle_name,
            "gender":fields.gender,
            "dob":fields.dob,
            "nationality":fields.nationality,
            "marital_status":fields.marital_status,
            "f_title":fields.father_title,
            "f_firstname":fields.father_first_name,
            "f_lastname":fields.father_last_name,
            "f_middlename":fields.father_middle_name,
            "m_title":fields.mother_title,
            "m_firstname":fields.mother_first_name,
            "m_lastname":fields.mother_last_name,
            "m_middlename":fields.mother_middle_name,
            "info_flag": true
            }
          async function saveBasicDetails() {
            if (props.info_flag) {
                let data = await VendorService.updatebasicDetailsVendor(payload);
                if(!data){
                    return false;
                }
            }else{
                let data = await VendorService.setbasicDetailsVendor(payload);
                if(!data){
                    return false;
                }
            }
            setTimeout(() => {
                setBtnDisable(false);
                props.setToggleStateClid(2);
            }, MINUTE_MS);
           }
           saveBasicDetails(); 
        }
      }
      useEffect(() => {
        form.registerAsync("only_letters", function (
          str,
          attribute,
          req,
          passes
        ) {
            var specials = /[^A-Za-z]/g;
            if (specials.test(str))
            //   passes(false, "Only letters allow.");
              passes(false, "space not allowed.");
            else passes();
        });
      }, []);
      useEffect(() => {
           if(props.vendorinfo){
                setvendorInfo(props.vendorinfo);
                fields.title=props.vendorinfo.title;
                fields.first_name=props.vendorinfo.firstname;
                fields.last_name=props.vendorinfo.lastname;
                fields.middle_name=props.vendorinfo.middlename;
                fields.gender=props.vendorinfo.gender;
                fields.dob=props.vendorinfo.dob;
                fields.nationality=props.vendorinfo.nationality;
                fields.marital_status=props.vendorinfo.marital_status;
                fields.father_title=props.vendorinfo.f_title;
                fields.father_first_name=props.vendorinfo.f_firstname;
                fields.father_last_name=props.vendorinfo.f_lastname;
                fields.father_middle_name=props.vendorinfo.f_middlename;
                fields.mother_title=props.vendorinfo.m_title;
                fields.mother_first_name=props.vendorinfo.m_firstname;
                fields.mother_last_name=props.vendorinfo.m_lastname;
                fields.mother_middle_name=props.vendorinfo.m_middlename;
                setLoading(false);
           }    
          },[props.vendorinfo]);
    return( 
             (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : vendor_info && (  
            <form action="" className="contributor-form" onSubmit={onSubmit}>
                <div className="form-col-2 form-horizontal">
                    <div className="form-cap-card">
                        <div className="form-caption">Personal Details</div>
                        <div style={{fontWeight:"bold"}}>(Name as per mentioned in PAN Card)</div>
                        <br/>
                        <div className="formGroup">
                            <label  className="input-label">Title <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select id="title" className="input-control w-half" name="title" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.title}>
                                        <option value="">Select</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Miss">Miss</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.title ? errors.title : ""} </label>
                            </div>                            
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">First Name <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter First Name"  className="input-control" name="first_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.first_name} data-attribute-name="First Name" data-async />
                                <label className="error"> {errors.first_name ? errors.first_name : ""} </label>
                            </div>
                            
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Last Name <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter Last Name" className="input-control" name="last_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.last_name} data-attribute-name="Last Name" data-async />
                                <label className="error"> {errors.last_name ? errors.last_name : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Middle Name</label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter Middle Name" className="input-control" name="middle_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.middle_name} data-attribute-name="Middle Name" data-async />
                                <label className="error"> {errors.middle_name ? errors.middle_name : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">Gender <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select className="input-control w-half" name="gender" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.gender}>
                                        <option value="">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.gender ? errors.gender : ""} </label>
                            </div>
                            
                        </div>
                        <div className="formGroup has-date-icon m-formGroup">
                            <label  className="input-label">Date of Birth <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="has-half-inp">
                                    <span className="right-icon">
                                        <input type="date" placeholder="DD-MM-YY" id="datepicker" className="input-control w-half" name="dob" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.dob} />
                                    </span>
                                </div>
                                <label className="error"> {errors.dob ? errors.dob : ""} </label>
                            </div>                            
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">Nationality <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select className="input-control w-half" name="nationality" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.nationality}>
                                        <option value="">Select</option>
                                        <option value="Indian">Indian</option>
                                        <option value="NRI">NRI</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.nationality ? errors.nationality : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup m-formGroup">
                            <label  className="input-label">Marital Status <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select className="input-control w-half" name="marital_status" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.marital_status}>
                                        <option value="">Select</option>
                                        <option value="Married">Married</option>
                                        <option value="Single">Single</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.marital_status ? errors.marital_status : ""} </label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-cap-card">
                        <div className="form-caption">Family Details</div>
                        <div style={{fontWeight:"bold"}}>(Name as per mentioned in PAN Card)</div>
                        <br/>
                        <div className="formGroup">
                            <label  className="input-label">Father's Title <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select className="input-control w-half" name="father_title" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.father_title}>
                                        <option value="">Select</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Miss">Miss</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.father_title ? errors.father_title : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Father's First Name <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter First Name" className="input-control"  name="father_first_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.father_first_name} data-attribute-name="Father First Name" data-async />
                                <label className="error"> {errors.father_first_name ? errors.father_first_name : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Father's Last Name <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter Last Name" className="input-control" name="father_last_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.father_last_name} data-attribute-name="Father last Name" data-async />
                                <label className="error"> {errors.father_last_name ? errors.father_last_name : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Father's Middle Name</label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter Name" className="input-control" name="father_middle_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.father_middle_name} data-attribute-name="father middle Name" data-async />
                                <label className="error"> {errors.father_middle_name ? errors.father_middle_name : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Mother's Title <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <div className="sel-control">
                                    <select  className="input-control w-half" name="mother_title" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.mother_title}>
                                        <option value="">Select</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Miss">Miss</option>
                                    </select>
                                </div>
                                <label className="error"> {errors.mother_title ? errors.mother_title : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Mother's First Name <span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter First Name" className="input-control" name="mother_first_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.mother_first_name} data-attribute-name="Mother First Name" data-async />
                                <label className="error"> {errors.mother_first_name ? errors.mother_first_name : ""} </label>
                            </div>
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Mother's Last Name<span className="asterisk">*</span></label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter Last Name" className="input-control" name="mother_last_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.mother_last_name} data-attribute-name="Mother last Name" data-async />
                                <label className="error"> {errors.mother_last_name ? errors.mother_last_name : ""} </label>
                            </div>
                          
                        </div>
                        <div className="formGroup">
                            <label  className="input-label">Mother's Middle Name</label>
                            <div className="form-wrapper">
                                <input type="text" placeholder="Enter Name" className="input-control" name="mother_middle_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.mother_middle_name} data-attribute-name="mother middle Name" data-async />
                                <label className="error"> {errors.mother_middle_name ? errors.mother_middle_name : ""} </label>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {(!props.final_flag) && (
                    <div className="form-action">
                   {(btn_disable) ? ( <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                                />) : (<button className="submit-btn" type="submit">Save</button>)}
                </div>
                )} 
            </form>
            )
    )
}
export default VendorBasicDetailsComponent;
