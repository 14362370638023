import React, { useState, useEffect } from "react";
import JSZip from 'jszip';
import { useNavigate } from "react-router-dom";
import '../css/file18.css';
import { RotatingTriangles, ColorRing } from 'react-loader-spinner'
import VendorService from '../services/vendor.service';
import AuthService from '../services/auth.service';
import Sidebar from '../components/sidebarComponent';
import VendorStatusProgressComponent from '../components/vendorStatusProgressComponent'
import VendorContractCopy from "../components/vendorContractCopy";


function VendorTrackingPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [btn_disable, setBtnDisable] = useState(false);
  const MINUTE_MS = 10000;//10 sec 
  const [vendor, setVendor] = useState([]);
  const [preview, setPreviewData] = useState([]);
  const [fileErrors, setFileErrors] = useState({});
  const [selectedFilesis, setSelectedFiles] = useState({});
  const [formDataArray, setFormDataArray] = useState([]);
  const globalArray = [];
  const [photoPreview, setPhotoPreview] = useState('');
  const [signaturePreview, setSignaturePreview] = useState('');
  const [photoDBUrl, setPhotoDBUrl] = useState('');
  const [signatureDBUrl, setSignatureDBUrl] = useState('');
  const [vendor_id, setVendorId] = useState(); // Initialize vendor_id as null
  let updatedErrors = {};
  const [documentTypesdd, setDocumentTypesdd] = useState([]); // Define the state variable

  
  const [data, setData] = useState([]);
  const documentTypes = [
    'aadhaar card',
    'pan card',
    'cancelled cheque',
    'resume',
    'contributor_form',
    'bank_statement',
    'gst_form',
    'declaration_form',
    'pan_aadhar_undertaking'
  ]

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const ImageValidation = (file, rowData) => {

    let validExtensions = []; // Initialize with an empty array
    const fileExtension = file.type.split('/')[1];
    const takepath = file.name;
    const test_ffff = takepath.substring(takepath.lastIndexOf('.') + 1).toLowerCase();
    if (rowData.document_type === "contributor_form" || rowData.document_type === "bank_statement") {
      validExtensions = ['xlsx','xls'];
    }
    else if (rowData.document_type === 'photograph' || rowData.document_type === 'signature') {
      validExtensions = ['jpg', 'jpeg'];
    } else {
      validExtensions = ['pdf','jpg', 'jpeg'];
    }
  
    if (!validExtensions.includes(test_ffff)) {
      return { status: false, msg: "Invalid file type. Only " + validExtensions.join(', ') + " files are allowed." };
    }

    if (file.size < 4000 || file.size > 5000000) {
      return { status: false, msg: "Size of document should be between 4KB and 5MB." };
    }

    return { status: true, msg: "" };
  };


  const handleDownloadZip = async (event) => {
    event.preventDefault();
    const zip = new JSZip();
    
    try {
      if (event.target.id === 'downloadZipButton') {
        console.log("comes in");
  
        const fetchPromises = preview.map(async (document) => {
          const documentName = document.document_type.replace(/ /g, '_'); 
          const extension = document.document_path.split('.').pop(); 
          const fileName = `${documentName}.${extension}`;
    
          try {
            const response = await fetch(document.document_path);
            if (!response.ok) {
              throw new Error(`Failed to fetch ${documentName}`);
            }
    
            const blob = await response.blob();
            zip.file(fileName, blob);
          } catch (error) {
            console.error(`Error fetching ${documentName}:`, error);
          }
        });
  
        await Promise.all(fetchPromises); // Wait for all fetches to complete
        
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const zipUrl = URL.createObjectURL(zipBlob);
        const link = document.createElement('a');
        link.href = zipUrl;
        link.download = 'documents.zip';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(zipUrl);
      }
    } catch (error) {
      console.error('Error during download:', error);
    }
  };
  
  

  const handleFileChange = (event, rowData) => {
    const file = event.target.files[0];
    const name = event.target.name;
    // const takepath = file.name;
    // const type = takepath.substring(takepath.lastIndexOf('.') + 1).toLowerCase();
    const validation = ImageValidation(file, rowData); // Apply file validation

    if (!validation.status) {
      // If file validation fails, set the error message for the current row
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validation.msg,
      }));
    } else {
      // setData([...data, { file, name }]);
      setData((prevData) => {
        // Check if a file is already selected for this field and remove it
        const updatedData = prevData.filter((item) => item.name !== name);
        return [...updatedData, { file, name }];
      });
      // Remove the error message for the current row
      setFileErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    }

    if (name === 'file_scanned_photograph') {
      setPhotoPreview(URL.createObjectURL(file));
    }
    if (name === 'file_scanned_signature') {
      setSignaturePreview(URL.createObjectURL(file));
    }


    return;

  };


  const handleUpload = (event) => {


    const formData = new FormData();
    formData.append('vendorId', vendor_id);
    let row; // Declare the `row` variable outside the loop
    const filesData = [];


    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        row = data[i]; // Assign the current `data` element to `row`

        if (row.file) {
          if (row.name === 'file_scanned_pan_aadhar_undertaking') {
            formData.append('file_scanned_pan_undertaking', row.file);
          }
          if (row.name === 'file_scanned_resume') {
            formData.append('file_scanned_resume', row.file);
          }
          if (row.name === 'file_scanned_pan card') {
            formData.append('file_scanned_pan', row.file);
          }
          if (row.name === 'file_scanned_aadhaar card') {
            formData.append('file_scanned_aadhaar', row.file);
          }
          if (row.name === 'file_scanned_cancelled cheque') {
            formData.append('file_scanned_cc', row.file);
          }
          if (row.name === 'file_scanned_photograph') {
            formData.append('file_photo', row.file);

          }
          if (row.name === 'file_scanned_signature') {
            formData.append('file_signature', row.file);

          }
          if (row.name === 'file_scanned_contributor_form') {
            formData.append('file_scanned_contributor_form', row.file);
          }
          if (row.name === 'file_scanned_bank_statement') {
            formData.append('file_scanned_bank_statement', row.file);
          }
          if (row.name === 'file_scanned_gst_form') {
            formData.append('file_scanned_gst_form', row.file);
          }
          if (row.name === 'file_scanned_declaration_form') {
            formData.append('file_scanned_declaration_form', row.file);
          }


        }
      }
    }
    else {
      updatedErrors = {};
      documentTypesdd.forEach((item) => {
      let cleanedItem = item.replace(/file_scanned|[_-]/g, '');
      if (cleanedItem === 'panaadharundertaking') {
        cleanedItem = 'Pan Aadhar Undertaking';
      }
      updatedErrors[item] = `Please upload scanned ${cleanedItem}`;
    });

    setFileErrors((prevErrors) => ({
      ...prevErrors,
      ...updatedErrors,
    }));
    
    }

    try {
      async function setAllDocsVendor() {
        await VendorService.updateAllDocumentsVendor(formData, true);
        
      }
    // setBtnDisable(true);
    if(Object.keys(updatedErrors).length === 0)
      {
          setBtnDisable(true);
      }      

      setAllDocsVendor();
      setTimeout(() => {
        setBtnDisable(false);
        getVendor();
      }, MINUTE_MS);

    } catch (error) {
      console.error('Upload error:', error);
    }
    return;
  };



  async function getVendor() {
    let data = await VendorService.getCurrentVendor();
   
    setVendor(data);
    setPreviewData(data.vendor_documents);
    const vendorObject = JSON.parse(localStorage.getItem('vendor'));
    const dd = vendorObject.token;
    const [header, payload, signature] = dd.split('.');
    const decodedPayload = JSON.parse(window.atob(payload));
    setVendorId(decodedPayload.id); // Set the vendor_id state after decoding the payload

    setDocumentTypesdd(data.vendor_documents.map((document) => `file_scanned_${document.document_type}`));
    const photoDocument = data.vendor_documents.find(document => document.document_type === 'photograph');
    const signatureDocument = data.vendor_documents.find(document => document.document_type === 'signature');
    if (photoDocument) {
      setPhotoDBUrl(photoDocument.document_path);
    }
    if (signatureDocument) {
      setSignatureDBUrl(signatureDocument.document_path);
    }
  }

  useEffect(() => {
    if (!AuthService.isLoggedInVendor()) {
      navigate(process.env.PUBLIC_URL + "/vendor-login");
    } else {   
    getVendor();
    }
    setLoading(false);
  }, []);


  const Capitalize = (str) => {
    if (typeof str !== 'undefined')
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const convertDate = (date) => {
    var todaysDate = new Date(date);
    var yyyy = todaysDate.getFullYear().toString();
    var mm = (todaysDate.getMonth() + 1).toString();
    var dd = todaysDate.getDate().toString();
    var mmChars = mm.split('');
    var ddChars = dd.split('');
    return (ddChars[1] ? dd : "0" + ddChars[0]) + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + yyyy;
  }

  return (
    <div className="page-wrapper">
      <Sidebar sidebarFor="Vendor" final_form_flag={vendor.final_flag} />
      <div className="content-wrapper">
        <div className="page-content page-contributor-preview page-track-status">
          <div className="page-header">
            <h4>Track Form Status</h4>
          </div>
          <div className="card">
            <div className="card-body">
              <VendorStatusProgressComponent />
              {(loading) ? (<RotatingTriangles
                height="100"
                width="100"
                color="#fff"
                ariaLabel="rotating-triangels-loading"
                wrapperStyle={{}}
                wrapperclassName="triangle-loading"
                visible={loading}
              />) : vendor.vendor_info && (
                <><form className="contributor-form" onSubmit={event => {
                  event.preventDefault(); // Prevent the default form submission behavior
                  handleUpload();
                }}>

                
                  <div style={{ margin: '30px 0' }}>  
                  <button  id="downloadZipButton" className="submit-btn btn-solid" 
                  onClick={(event) => handleDownloadZip(event)}>
                  Download Zip
                  </button>
                  </div>


                  <section className="section-doc-upload">
                    <div className="cap-wrapper w-full">
                      <div className="form-caption">Documents Upload</div>
                      <div className="tble-wrapper">
                        <div className="thead">
                          <div className="th">Proof Name</div>
                          <div className="th">Document Name</div>
                          <div className="th attach-file-div">Attach File</div>
                          <div className="th">Status</div>
                        </div>
                        <div className="tbody-wrapper">
                          {preview.map((document) => {
                            if (document.document_type !== 'photograph' && document.document_type !== 'signature'
                              && document.document_type !== 'CONTRACT COPY' && document.document_type !== 'CONTRACT COPY LEGAL') {
                              return (
                                <div className="tbody" key={document.id}>
                                  <div className="td">
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">
                                      <div className="label text-upper">
                                        {document.document_type === 'aadhaar card' ? (<div>SCANNED {document.document_type}`<span className="asterisk">*</span></div>) : ''}
                                        {document.document_type === 'pan card' ? (<div>SCANNED {document.document_type}`<span className="asterisk">*</span></div>) : ''}
                                        {document.document_type === 'cancelled cheque' ? (<div>SCANNED {document.document_type}`<span className="asterisk">*</span></div>) : ' '}
                                        {document.document_type === 'resume' ? (<div>SCANNED RESUME/CV<span className="asterisk">*</span></div>) : ' '}
                                        {document.document_type === 'contributor_form' ? (<div>CONTRIBUTOR OPENING FORM <span className="asterisk">*</span>(Only Xlsx)</div>) : ' '}
                                        {document.document_type === 'bank_statement' ? (<div>BANK ACCOUNT STATEMENT <span className="asterisk">*</span>(Only Xlsx)</div>) : ' '}
                                        {document.document_type === 'gst_form' ? (<div>GST DECLARATION FORM <span className="asterisk">*</span>(Only PDF)</div>) : ' '}
                                        {document.document_type === 'declaration_form' ? (<div>SELF DECLARATION FORM <span className="asterisk">*</span>(Only PDF)</div>) : ' '}
                                        {document.document_type === 'pan_aadhar_undertaking' ? (<div>PAN AADHAR UNDERTAKING <span className="asterisk">*</span>(Only pdf)</div>) : ' '}
                                        {!documentTypes.includes(document.document_type) ? document.document_type : ''}
                                      </div>

                                    </div>



                                  </div>
                                  <div className="td">
                                    <div className="tab-label v-mob">Document Name</div>
                                    <div className="label text-upper">
                                      <div className="attachment">
                                        <div className="attachment-label text-upper">
                                          {document.document_type === 'aadhaar card' ? `SCANNED ${document.document_type}` : ''}
                                          {document.document_type === 'pan card' ? `SCANNED ${document.document_type}` : ''}
                                          {document.document_type === 'cancelled cheque' ? `${document.document_type}` : ' '}
                                          {document.document_type === 'resume' ? `RESUME/CV` : ' '}
                                          {document.document_type === 'contributor_form' ? (<div>CONTRIBUTOR OPENING FORM</div>) : ' '}
                                          {document.document_type === 'bank_statement' ? `BANK ACCOUNT STATEMENT` : ' '}
                                          {document.document_type === 'gst_form' ? `GST DECLARATION FORM` : ' '}
                                          {document.document_type === 'declaration_form' ? (<div>SELF DECLARATION FORM</div>) : ' '}
                                          {document.document_type === 'pan_aadhar_undertaking' ? (<div>PAN AADHAR UNDERTAKING</div>) : ' '}
                                          {!documentTypes.includes(document.document_type) ? document.document_type : ''}

                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="td attach-file-div">
                                    <div className="tab-label v-mob">Attach File</div>
                                    {(document.status === "Rejected") && (
                                      <>
                                        <div className="upload-wrapper">
                                          <div className="attach-file-wrp">
                                            <input type="file" onChange={event => handleFileChange(event, document)} 
                                              name={`file_scanned_${document.document_type}`} />


                                          </div>
                                        </div>
                                        <label className="error">{fileErrors[`file_scanned_${document.document_type}`] || ''}</label>                                               </>
                                    )}
                                    {(document.status !== "Rejected") && (
                                      <div className="attachment">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                        <div className="attachment-name">
                                          {decodeURIComponent(document.document_path.substring(document.document_path.lastIndexOf('/') + 1).substring(37))}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="td">
                                    <div className="tab-label v-mob">Status</div>
                                    <div className="label">
                                      {(document.status === "Processing") ? document.status : ""}
                                      {(document.status === "Accepted") && (<div className="status-label accepted">
                                        <i className="fi fi-verified-black"></i> Accepted
                                      </div>)}
                                      {(document.status === "Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                        <div className="reason-text">Reason : {document.comments}.</div></>)}
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}


                        </div>
                      </div>
                    </div>
                  </section>



                  <section className="section-photo-signature">
                    <div className="cap-wrapper w-full">
                      <div className="form-caption">
                        Photo &amp; Signature
                      </div>
                      <div className="ps-wrapper">
                        {preview.map((document) => {
                          if (document.document_type === 'photograph') {
                            return (
                              <div className="cap-wrapper" key={document.id}>
                                <div className="form-caption">{document.document_type}</div>
                                <div className="image-preview-data">
                                  <div className="label">Preview {document.document_type}</div>
                                  <div className="image-preview">
                               
                                  </div>
                                  {(document.status === "Rejected") && (
                                    <>
                                      <div className="upload-wrapper">
                                        <div className="attach-file-wrp">
                                          <input type="file" onChange={event => handleFileChange(event, document)} accept=".jpg, .jpeg, .pdf"
                                            name={`file_scanned_${document.document_type}`} />                                                    </div>
                                      </div>
                                      <label className="error">{fileErrors[`file_scanned_${document.document_type}`] || ''}</label>                                               </>

                                  )}
                                </div>
                              </div>
                            );
                          }

                          if (document.document_type === 'signature') {
                            return (
                              <div className="cap-wrapper" key={document.id}>
                                <div className="form-caption">{document.document_type}</div>
                                <div className="image-preview-data">
                                  <div className="label">Preview {document.document_type}</div>
                                  <div className="image-preview">
                                    {/* <img src={`${document.document_path}`} alt={`Preview ${document.document_path}`} /> */}

                                    {signaturePreview && <img src={signaturePreview} alt="Preview Signature" />}
                                    {!signaturePreview && signatureDBUrl && <img src={signatureDBUrl} alt="Preview Signature" />}
                                  </div>
                                  {(document.status === "Rejected") && (
                                    <>
                                      <div className="upload-wrapper">
                                        <div className="attach-file-wrp">
                                          <input type="file" onChange={event => handleFileChange(event, document)} accept=".jpg, .jpeg"
                                            name={`file_scanned_${document.document_type}`} />                                                    </div>
                                      </div>
                                      <label className="error">{fileErrors[`file_scanned_${document.document_type}`] || ''}</label>                                               </>

                                  )}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                        <div className="ps-status">
                          <div className="label">
                            Signature Status:
                            {preview.some((document) => document.document_type === 'signature') && (
                              <>
                                {preview.map((document) => {
                                  if (document.document_type === 'signature') {
                                    return (
                                      <React.Fragment key={document.id}>
                                        {document.status === "Processing" && document.status}
                                        {document.status === "Accepted" && (
                                          <div className="status-label accepted">
                                            <i className="fi fi-verified-black"></i> Accepted
                                          </div>
                                        )}
                                        {document.status === "Rejected" && (
                                          <>
                                            <div className="status-label rejected">
                                              <i className="fi fi-unpublished-black"></i> Rejected
                                            </div>
                                            <div className="reason-text">Reason: {document.comments}.</div>
                                          </>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                  return null;
                                })}
                              </>
                            )}
                            <br />
                            Photo Status:
                            {preview.some((document) => document.document_type === 'photograph') && (
                              <>
                                {preview.map((document) => {
                                  if (document.document_type === 'photograph') {
                                    return (
                                      <React.Fragment key={document.id}>
                                        {document.status === "Processing" && document.status}
                                        {document.status === "Accepted" && (
                                          <div className="status-label accepted">
                                            <i className="fi fi-verified-black"></i> Accepted
                                          </div>
                                        )}
                                        {document.status === "Rejected" && (
                                          <>
                                            <div className="status-label rejected">
                                              <i className="fi fi-unpublished-black"></i> Rejected
                                            </div>
                                            <div className="reason-text">Reason: {document.comments}.</div>
                                          </>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                  return null;
                                })}
                              </>
                            )}
                          </div>


                        </div>
                      </div>
                    </div>
                  </section>


                  {(vendor.status === "Rejected" && (<div className="form-action">
                    {(btn_disable) ? (<ColorRing
                      visible={true}
                      height="80"
                      width="80"
                      ariaLabel="blocks-loading"
                      wrapperStyle={{}}
                      wrapperClass="blocks-wrapper"
                      colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                    />) :
                      <button className="submit-btn btn-solid" type="submit">Submit</button>
                    }
                  </div>))}

                </form>
                  {((vendor.status === "Legal" || vendor.status === "Signed Contract Copy") && (
                    <VendorContractCopy />
                  ))}

                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default VendorTrackingPage;
