import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import VendorService  from '../services/vendor.service';
import { RotatingTriangles,ColorRing } from 'react-loader-spinner';
import VendorPreview from "./vendorPreviewComponent";
import VendorPreview2 from "./vendorPreviewComponent2";


function VendorDeclarationComponent(props){
    const navigate = useNavigate();
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 6000;//10 sec 
    const [loading, setLoading] = useState(true);
    const [vendor_declaration, setvendorDeclaration] = useState({});
    const initialState = { alt: "", src: "" };
    const [file_photo, setFilePhoto] = useState({});
    const [file_signature, setfileSignature] = useState({});
    const [error_photo, setErrorPhoto] = useState({});
    const [error_signature, setErrorSignature] = useState({});
    const [self_declaration, setSelfDeclaration] = useState(true);
    const [error_self_declaration, setErrorSelfDeclaration] = useState({});
    const [non_gst_declaration, setNonGstDeclaration] = useState(true);
    const [error_non_gst_declaration, setErrorNonGstDeclaration] = useState({});
    const [{ alt, src }, setPhotoPreview] = useState(initialState);
    const [{ alt_s, src_s }, setSignaturePreview] = useState(initialState);
    const [previewStatus, setPreview] = useState(false);
    const [previewBtnStatus, setBtnPreview] = useState(true);

    const onSubmit = async (event) => {
        event.preventDefault();
        const isValidCheckboxes = validateCheckboxes();
        if (!isValidCheckboxes) {
            // Display an error message or perform appropriate action
            return;
        }
    
        if (window.confirm("Please confirm before submitting. Changes cannot be done once submitted") == true) {
            const isValid = await Formvalidate(event);
            if (isValid) {
                setBtnDisable(true);
                const formData = new FormData();
                formData.append('file_photo', file_photo);
                formData.append('file_signature', file_signature);
                formData.append('self_declaration', self_declaration);
                formData.append('non_gst_declaration', non_gst_declaration);
                formData.append('final_flag',true );

                async function saveDeclaration() {
                    if (props.final_flag) {
                        let data = await VendorService.updatedeclationVendor(formData,true);
                        if(!data){
                            return false;
                        }
                    }else{
                        let data = await VendorService.setdeclationVendor(formData,true);
                        if(!data){
                            return false;
                        }
                    }
                    setTimeout(() => {
                        setBtnDisable(false);
                        navigate(process.env.PUBLIC_URL+"/vendor-tracking");
                    }, MINUTE_MS);
                }
                saveDeclaration(); 
            }
        } else {
        
        }
      }


      
      const ImageValidation =(file)=>{
        var filePath = file.name;
        // Allowing file type
        var allowedExtensions =  /(\.jpg|\.jpeg)$/i;
        if (!allowedExtensions.exec(filePath)) {
            return {status:false,"msg":"Invalid file type only jpg,jpeg are allowed"};
        }else if (file.size < 4000 || file.size > 5000000) { // Size of document should be between 4KB and 5MB.
            return {status:false,"msg":"Size of document should be between 4KB and 5MB."};
        }
        // show preview  
        return {status:true,"msg":""};
  }

  const validateCheckboxes = () => {
    // Add your checkbox validation logic here
    if (!self_declaration) {
        setErrorSelfDeclaration({ status: true, msg: "Please agree to self declaration" });
        return false;
    }
    
    if (!non_gst_declaration) {
        setErrorNonGstDeclaration({ status: true, msg: "Please agree to non GST declaration" });
        return false;
    }

    // All checkboxes are validated
    setErrorSelfDeclaration({ status: false, msg: "" });
    setErrorNonGstDeclaration({ status: false, msg: "" });
    return true;
};

  const  handleChangeSelfDeclaration = (event) => {
        console.log(event.target.checked)
        setSelfDeclaration(event.target.checked);    
        setErrorSelfDeclaration({ status: false, msg: "" });

    }

    const  handleChangeNonGstDeclaration = (event) => {
        setNonGstDeclaration(event.target.checked);
        setErrorNonGstDeclaration({ status: false, msg: "" });

    }

    const handlePreview = () => {
        setPreview(true);
        setBtnPreview(false);
    }

  const  handleChangePhoto = (event) => {
    const valid=ImageValidation(event.target.files[0]);
    if(valid.status){
        setFilePhoto(event.target.files[0]);
        setSignaturePreview(
            event.target.files.length
              ? {
                  src_s: URL.createObjectURL(event.target.files[0]),
                  alt_s: event.target.files[0].name
                }
              : initialState
          );
    }else{
        event.target.value = null;
    }
    setErrorPhoto(valid);
    
}
const handleChangeSignature = (event) => {
    const valid=ImageValidation(event.target.files[0]);
    if(valid.status){
        setfileSignature(event.target.files[0]);
        setPhotoPreview(
            event.target.files.length
              ? {
                  src: URL.createObjectURL(event.target.files[0]),
                  alt: event.target.files[0].name
                }
              : initialState
          );
    }else{
        event.target.value = null;
    }
    setErrorSignature(valid);

}

  const Formvalidate =(e)=>{
    let isValid= true;
    if (props.final_flag) {
        if (typeof file_photo.name === 'undefined' && typeof file_signature.name === 'undefined') {
            setErrorPhoto({status:false,"msg":"Please upload photo"});
            isValid=false;
        }
        if (typeof file_photo.name === 'undefined' && typeof file_signature.name === 'undefined') {
            setErrorSignature({status:false,"msg":"Please upload signature"});
            isValid=false;
        }

    }else{
        if (typeof file_photo.name === 'undefined') {
            setErrorPhoto({status:false,"msg":"Please upload photo"});
            isValid=false;
        }
        if (typeof file_signature.name === 'undefined') {
            setErrorSignature({status:false,"msg":"Please upload signature"});
            isValid=false;
        }
    }
    return isValid;
  }
  useEffect(() => {
    if(props.vendor.vendor_documents && props.vendor.vendor_info){
        setvendorDeclaration(props.vendor);
        props.vendor.vendor_documents.map(function(val, index){
            if(val.document_type==="photograph"){
                setSignaturePreview(
                    {
                      src_s:val.document_path,
                      alt_s:val.document_name
                    }
              );
            }
            if(val.document_type==="signature"){
                setPhotoPreview(
                    {
                      src:val.document_path,
                      alt:val.document_name
                    }
              );
            }
            return true;
        })
        if(props.vendor.vendor_info.self_declaration)
            setSelfDeclaration(props.vendor.vendor_info.self_declaration);
        if(props.vendor.vendor_info.gst_declaration)
            setNonGstDeclaration(props.vendor.vendor_info.gst_declaration);
        setLoading(false);
    }     
   },[props.vendor]);
    return(
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : vendor_declaration && ( 
            <form action="" className="contributor-form contributor-declaration" onSubmit={onSubmit}>
                <div className="form-col-2 form-horizontal">
                    <div className="help-text v-mob">Note: Photo and Signature should be in *.jpg/*.jpeg format.
                     If your photograph/ Signature image is in other format, please save it as in .jpg/.jpeg format. Size of photo should be between 4KB and 5MB. SCAN a signature and upload it.</div>
                    <div className="form-cap-card d-block">
                        <div className="form-caption">Upload Photo &amp; Signature</div>
                        <div className="form-iw">
                            <div className="cap-wrapper">
                                <div className="form-caption">Photo</div>
                                <div className="preview-data">
                                    <div className="pre-label">Preview Photo</div>
                                    <div className="image-preview">
                                        <img src={src_s} alt={alt_s} />
                                    </div>
                                    {alt_s}
                                </div>
                                <div className="upload-wrapper">
                                    <div className="attach-file-wrp">
                                        <input type="file" id="file_photo" name="file_photo" onChange={handleChangePhoto} accept="image/jpg, image/jpeg, image/png" />
                                        {/* <label className="choose-file-btn" >Choose File</label> */}
                                        {/* <span className="file-text" id="file_photo_name">No file chosen</span> */}
                                    </div>
                                </div>
                                <label className="error"> {error_photo ? error_photo.msg : ""} </label>
                            </div>
                            <div className="cap-wrapper">
                                <div className="form-caption">Signature</div>
                                <div className="preview-data">
                                    <div className="pre-label">Preview Signature</div>
                                    <div className="image-preview pre-signature">
                                        <div id="preview_sign_text" className="preview-text">
                                            {(src) && (
                                                <img src={src} alt={alt} />
                                            )}
                                        </div>
                                    </div>
                                    {alt}
                                </div>
                                <div className="bottom-wrapper">
                                    <div className="upload-wrapper">
                                        <div className="attach-file-wrp">
                                            <input type="file" id="file_signature" name="file_signature" onChange={handleChangeSignature} accept="image/jpg, image/jpeg, image/png" />
                                            {/* <label className="choose-file-btn" >Choose File</label> */}
                                            {/* <span className="file-text" id="file_signature_name">No file chosen</span> */}
                                        </div>
                                    </div>
                                    <label className="error"> {error_signature ? error_signature.msg : ""} </label>
                                </div>
                            </div>
                        </div>
                        <div className="help-text h-mob">Note: Photo and Signature should be in *.jpg/*.jpeg format. If your photograph/ Signature image is in other format, please save it as in .jpg/.jpeg format. Size of photo should be between 4KB and 5MB. SCAN a signature and upload it.</div>
                    </div>
                </div>
                <div className="form-col-2 form-horizontal d-form-check">
                    <div className="form-cap-card d-block">
                        <div className="form-caption">Declaration</div>
                        <div className="custom-checkbox-item">
                            <label>
                                <input type="checkbox" id="self_declaration" defaultChecked={self_declaration} className="checkbox" name="self_declaration" onChange={handleChangeSelfDeclaration} />
                                <span className="checkbox-content">
                                    <span className="icon-holder"><i className="fi fi-checkbox-unchecked"></i></span>
                                    <span className="content"><strong>Self Declaration:</strong> I/We, hereby agree and acknowledge that all the factual information which is provided to Network18 Media and Investments Limited, under or in connection with any document submitted are true in all material respects and not false or misleading in any respect.</span>
                                </span>
                            </label>
                            <label className="error"> {error_self_declaration ? error_self_declaration.msg : ""} </label>
                        </div>
                        <div className="custom-checkbox-item">
                            <label>
                                <input type="checkbox" id="non_gst_declaration" defaultChecked={non_gst_declaration} className="checkbox" name="non_gst_declaration" onChange={handleChangeNonGstDeclaration} />
                                <span className="checkbox-content">
                                    <span className="icon-holder"><i className="fi fi-checkbox-unchecked"></i></span>
                                    <span className="content"><strong>Non GST Declaration:</strong> I/We, hereby acknowledge and declare that I/We have not obtained any GST Registration under the Act and shall provide any supporting document required to get registered with Network18 Media and Investments Limited.</span>
                                </span>
                            </label>
                            <label className="error"> {error_non_gst_declaration ? error_non_gst_declaration.msg : ""} </label>
                        </div>
                    </div>
                </div>

                {(previewStatus) && (
                    <VendorPreview2 />
                )}
                {(!props.final_flag) && (
                    <div className="form-action">
                        {(btn_disable) ? ( <ColorRing
                                    visible={true}
                                    height="80"
                                    width="80"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                    colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                                />) : (<button className="submit-btn" type="submit">Save</button>)}
                        {(previewBtnStatus) && (
                            <button className="submit-btn preview-btn" type="button" onClick={handlePreview}>Preview</button>
                        )}
                    </div>
                )}
            </form>
          )
    )
}
export default VendorDeclarationComponent;
