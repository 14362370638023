import React, { useState, useEffect } from "react";
import '../../css/event/signUp.css';
import Sidebar from '../../components/event/sidebarComponent';
import { useNavigate, useParams } from "react-router-dom";
import AdminService from '../../services/event/admin.service';
import { useFormInputValidation } from "react-form-input-validation";
import { RotatingTriangles } from 'react-loader-spinner';
import 'react-responsive-modal/styles.css';
import { ColorRing } from 'react-loader-spinner'
import { format } from 'date-fns';
import moment, * as moments from 'moment';
import AuthService from "../../services/event/auth.service";

function EventEdit(props) {
    const {id} = useParams();
    const initialState = { alt: "", src: "" };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 1000;//10 sec 
    const [file_agenda_url, setAgendaUrl] = useState({name:'NULL'});
    const [file_logo_url, setLogoUrl] = useState({name:'NULL'});
    const [{ alt_s, src_s }, setPreview] = useState(initialState);
    const [{ alt_agenda_s, src_agenda_s }, setAgendaPreview] = useState(initialState);
    const [error_photo, setErrorPhoto] = useState({});
    const [error_agenda, setErrorAgenda] = useState({});
    const [eventEditResult, setEventEditResult] = useState([]);
    const[send_mail,setSendMail]=useState(false);
    const[is_listing,setIsListingl]=useState(false);
    const [propertyResult, setPropertyResult] = useState([]);
    const fetchDataList = async () => {
        return await AdminService.getProperty();
    }
    const fetchData = async () => {
        return await AdminService.getEvents(id);
    }
    const  handleChangeSendMail = (event) => {
        console.log(event.target.checked)
        setSendMail(event.target.checked);    
    }
    const handleChangeListing= (event) => {
        console.log(event.target.checked)
        setIsListingl(event.target.checked);    
    }

    const handleDownloadZip = async (event) => {
        event.preventDefault();
        
        try {
          if (event.target.id === 'downloadZipButton') {
            async function saveBasicDetails() {
                try {
                  const response = await AdminService.getExcelUser(id);
                 const blob = new Blob([response], { type: 'text/csv' });
                  const blobUrl = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.download = 'users.csv'; // Use '.csv' file extension for CSV files
                  link.click();
                  window.URL.revokeObjectURL(blobUrl);
                  return 'Download successful';
                } catch (error) {
                  console.error('Error downloading CSV file:', error);
                  return 'Download failed';
                }
              }
           saveBasicDetails(); 
         
          }
        } catch (error) {
          console.error('Error during download:', error);
        }
      };

    useEffect(() => {
        const fetchDataAndSetState = async () => {
            const result1 = await fetchDataList();
            setPropertyResult(result1 || []);
            ///
            const result = await fetchData();
            setEventEditResult(result || []);
            setPreview({
                src_s:result.logo,
                alt_s:result.logo
            });
            setAgendaPreview({
                src_agenda_s:result.agenda_url,
                alt_agenda_s:result.agenda_url
            });
            setLogoUrl(result.logo);
            setAgendaUrl(result.agenda_url);
            fields.short_title=result.short_title;
             fields.venue=result.location;
             var defaultValue = new Date(result.date).toISOString().split("T")[0];
             fields.event_date=defaultValue;
             fields.event_time=result.time;
             fields.microsite_path=result.microsite_path;
             fields.description=result.description;
             fields.property_type=result.property_type;
             fields.event_end_date = result.end_date;
             fields.event_end_time = result.end_time;
            //  fields.logo=result.logo;
            setSendMail(result.is_sent_mail);
            setIsListingl(result.is_listing);
            fields.agenda=result.agenda_url;
        }
        fetchDataAndSetState();
        setLoading(false);
    }, []);
    
    const [fields, errors, form] = useFormInputValidation({
        short_title: "",
        event_date: "",
        event_time: "",
        agenda_url: "",
        description:"",
        logo:"",
        venue:"",
        microsite_path:"",
        property_type:"",
        event_end_date:"",
        event_end_time:"",
        }, {
        short_title: "required",
        event_date: "required",
        event_time: "required",
        agenda_url: "required",
        description:"required",
        logo:"required",
        venue:"required",
        microsite_path:"required",
        property_type:"required",
        event_end_date:"required",
        event_end_time:"required"
    });
    
    const PdfValidation =(file)=>{
        var filePath = file.name;
        // Allowing file type
        var allowedExtensions =  /(\.pdf)$/i;
        if (!allowedExtensions.exec(filePath)) {
            return {status:false,"msg":"Invalid file type"};
            
        }else if (file.size < 4000 || file.size > 5000000) { // Size of document should be between 4KB and 25MB.
            return {status:false,"msg":"File size should not be more than 25 MB."};
        } 
        return {status:true,"msg":""};
  }
  const ImageValidation = (file)=>{
    var filePath = file.name;
    // Allowing file type
    var allowedExtensions =  /(\.jpg|\.png|\.jpeg)$/i;
    if (!allowedExtensions.exec(filePath)) {
        return {status:false,"msg":"Invalid file type"};
        
    }else if (file.size < 4000 || file.size > 5000000) { // Size of document should be between 4KB and 25MB.
        return {status:false,"msg":"File size should not be more than 25 MB."};
    } 
    return {status:true,"msg":""};
  }
  
  const  handleChangeAgenda = (event) => {
    const valid=PdfValidation(event.target.files[0]);
    if(valid.status){
        setAgendaUrl(event.target.files[0]);
        setAgendaPreview(
            event.target.files.length
              ? {
                  src_agenda_s: URL.createObjectURL(event.target.files[0]),
                  alt_agenda_s: event.target.files[0].name
                }
              : initialState
          );
    }else{
        event.target.value = null;
    }
    setErrorAgenda(valid);
}
const Formvalidate =(e)=>{
    let isValid= true;
    console.log('file_logo_url',file_logo_url);
    if(file_logo_url === ' '){
        if (file_logo_url === ' '){ 
            setErrorPhoto({status:false,"msg":"Please upload logo"});
            isValid=false;
        }else if (typeof file_logo_url.name === 'undefined' ||  file_logo_url.name === 'NULL') {
                setErrorPhoto({status:false,"msg":"Please upload logo"});
                isValid=false;
        }
    }
    if(file_agenda_url === ' '){
        if (file_agenda_url === ' '){
            setErrorAgenda({status:false,"msg":"Please upload agenda"});
            isValid=false;
        }else if (typeof file_agenda_url.name === 'undefined' ||  file_agenda_url.name === 'NULL') {
                setErrorAgenda({status:false,"msg":"Please upload agenda"});
                isValid=false;
            }
    }
    return isValid;
  }
const  handleChangeLogo = (event) => {
    const valid=ImageValidation(event.target.files[0]);
    if(valid.status){
        setLogoUrl(event.target.files[0]);
        setPreview(
            event.target.files.length
              ? {
                  src_s: URL.createObjectURL(event.target.files[0]),
                  alt_s: event.target.files[0].name
                }
              : initialState
          );
    }else{
        event.target.value = null;
    }
    setErrorPhoto(valid);
    
}
    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        const isValidUrl = await Formvalidate(event);
        const formData = new FormData();
       console.log('fields',fields);
        if (isValid && isValidUrl) {
            setBtnDisable(true);
            formData.append('short_title', fields.short_title);
            formData.append('date', fields.event_date);
            formData.append('time', fields.event_time);
            formData.append('location', fields.venue);
            if(file_logo_url.name){
                formData.append('logo', file_logo_url);
            }
            if(file_agenda_url.name){
                formData.append('agenda_url', file_agenda_url);
            }
            formData.append('microsite_path', fields.microsite_path);
            formData.append('description', fields.description);
            formData.append('name', fields.short_title);
            formData.append('eventId',id);
            formData.append('property_type',fields.property_type);
            formData.append('end_date',fields.event_end_date);
            formData.append('end_time',fields.event_end_time);
            formData.append('is_sent_mail',send_mail);
            formData.append('is_listing',is_listing);
          async function saveBasicDetails() {
                let data = await AdminService.setEventDetails(formData);
                if(!data){
                    return false;
                }
                navigate('/event-list');
                 setTimeout(() => {
                    setBtnDisable(false);
                        // navigate("/event-list");
                    }, MINUTE_MS);
           }
           saveBasicDetails(); 
        }
      }
    useEffect(() => {
        if (!AuthService.isLoggedInUser()) {
            navigate("/admin-login");
          }
    }, []);
    return (
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : eventEditResult &&  (
       
        <div className="page-wrapper">
        <Sidebar sidebarFor="Admin" />
        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-contributor contributor-basic-details">
                <div className="page-header">
                    <h4>Event Update</h4>
                </div>
                <div className="card">
                    <div className="card-body">
                       <form noValidate autoComplete="off" onSubmit={onSubmit} className="signupform" id="registerform">
                          {/* <button type="submit" className="submit-btn" id="downloadZipButton" onClick={(event) => handleDownloadZip(event)}>Excel download</button> */}
                        <div className="add-event-form form-regular">
                                <div className="form-cap-card">                                    
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Title <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="short_title" placeholder="Title" className="input-control" type="text" name="short_title" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.short_title} />
                                                <label className="errors"> {errors.short_title ? errors.short_title : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Venue <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="venue" placeholder="Venue" className="input-control" type="text" name="venue" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.venue} />
                                                <label className="errors"> {errors.venue ? errors.venue : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Event Date <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                            <input type="date" placeholder="DD-MM-YY" id="datepicker" className="input-control" name="event_date" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.event_date} />
                                                <label className="errors"> {errors.event_date ? errors.event_date : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Event Time <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="event_time" placeholder="Event Time" className="input-control" type="time" name="event_time" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.event_time} />
                                                <label className="errors"> {errors.event_time ? errors.event_time : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Event End Date <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                            <input type="date" placeholder="DD-MM-YY" id="datepicker" className="input-control" name="event_end_date" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.event_end_date} />
                                                <label className="errors"> {errors.event_end_date ? errors.event_end_date : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Event End Time <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="event_end_time" placeholder="Event End Time" className="input-control" type="time" name="event_end_time" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.event_end_time} />
                                                <label className="errors"> {errors.event_end_time ? errors.event_end_time : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Microsite Path <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="microsite_path" placeholder="Microsite Path" className="input-control" type="text" name="microsite_path" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.microsite_path} />
                                                <label className="errors"> {errors.microsite_path ? errors.microsite_path : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Property Type <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                            {(propertyResult &&
                                                <select name="property_type" className="input-control" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.property_type}>
                                                    <option value=" ">Select</option>
                                                    {(propertyResult && propertyResult.map((property, index) => (
                                                        <>
                                                         <option value={property?.type}>{property?.type}</option>
                                                    </>
                                                    )))}
                                                </select>
                                                )}
                                                <label className="errors"> {errors.property_type ? errors.property_type : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <div className="col-12">
                                            <label htmlFor="" className="input-label">Description <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <textarea id="description" placeholder="Description" className="input-control" cols="20" row="30" type="text" name="description" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.description}>
                                                </textarea>
                                                <label className="errors"> {errors.description ? errors.description : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <div className="nw18-terms-div form-div">
                                            <input type="checkbox" name="is_send_mail" checked={send_mail} id="is_send_mail" onChange={handleChangeSendMail}></input>
                                            <label htmlFor="is_send_mail"> Is Send Mail</label>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                        <div className="nw18-terms-div form-div">
                                        <input type="checkbox" name="is_listing" checked={is_listing} id="is_listing" onChange={handleChangeListing}></input>
                                            <label htmlFor="is_listing"> Is Listing</label>
                                        </div>
                                    </div>
                                    <div className="formGroup upload-field">  
                                        <label htmlFor="" className="input-label">Agenda <span className="asterisk">*</span></label>
                                        <div className="form-wrapper upload-field">
                                            <input type="file" id="agenda_url"  name="agenda_url"  onChange={handleChangeAgenda}   accept=".pdf" className="file-input-hide"/>
                                        </div>
                                        
                                        <label className="errors"> {error_agenda ? error_agenda.msg : ""} </label>
                                        {/* <label className="errors"> {errors.error_agenda ? errors.error_agenda : ""} </label> */}
                                    </div>
                                    <div className="formGroup upload-field">  
                                        <label htmlFor="" className="input-label">Logo <span className="asterisk">*</span></label>
                                        <div className="form-wrapper upload-field">
                                            <input type="file" id="logo"  name="logo" onChange={handleChangeLogo}   accept="image/jpg, image/jpeg, image/png" className="file-input-hide"/>
                                        </div>
                                        <label className="errors"> {error_photo ? error_photo.msg : ""} </label>
                                        {/* <label className="errors"> {errors.logo ? errors.logo : ""} </label> */}
                                    </div>
                                    <div className="cap-wrapper">
                                        <div className="preview-data">
                                            <div className="pre-label">Preview Logo</div>
                                            <div className="image-preview">
                                                <img src={src_s} alt={alt_s} />
                                            </div>
                                            {/* {alt_s} */}
                                        </div>
                                    </div>
                                    {src_agenda_s ? (
                                    <div className="preview-data">
                                        <div className="pre-label">Download Agenda</div>
                                        <div className="image-preview1">
                                            <div className="upload-cc-wrapper">
                                                <div className="temp-upload-file">
                                                    {/* <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div> */}
                                                    <div className="upload_file_name">Agenda url   &nbsp;<a href={src_agenda_s} target="_blank" className="download-icon"><i className="fi fi-file-download"></i></a></div>
                                                    
                                                </div>                                         
                                            </div>
                                        </div>
                                    </div>
                                    ) : ( "" )}
                                    
                                </div>
                            </div>
                            
                            <div className="fg form-action" style={{ display: 'flex', justifyContent: 'center' }}>
                            {(btn_disable) ? (<ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                        />) : (<button type="submit" className="submit-btn">Update Event</button>)}
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
          )
    )
     }
export default EventEdit;