import React, { useState, useEffect } from "react";
import '../../css/event/signUp.css';
import Sidebar from '../../components/event/sidebarComponent';
import { useNavigate } from "react-router-dom";
import AdminService from '../../services/event/admin.service';
import AuthService from "../../services/event/auth.service";
import { useFormInputValidation } from "react-form-input-validation";
import { RotatingTriangles } from 'react-loader-spinner';
import 'react-responsive-modal/styles.css';
import { ColorRing } from 'react-loader-spinner'
import { format } from 'date-fns'

function UserAdd(props) {
    const initialState = { alt: "", src: "" };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 1000;//10 sec 
    const [file_agenda_url, setAgendaUrl] = useState({name:'NULL'});
    const [file_logo_url, setLogoUrl] = useState({name:'NULL'});
    const [{ alt_s, src_s }, setSignaturePreview] = useState(initialState);
    const [passwordType, setPasswordType] = useState("password");
    const [passwordCnfType, setCnfPasswordType] = useState("password");
    const [error_photo, setErrorPhoto] = useState({});
    const [error_agenda, setErrorAgenda] = useState({});
    const[send_mail,setSendMail]=useState(false);
    const [fields, errors, form] = useFormInputValidation({
        full_name: "",
        designation:"",
        contact_number:"",
        company_name:"",
        email:"",
        password:"",
        }, {
        full_name: "required",
        designation:"required",
        company_name:"required",
        email: "required|email",
        contact_number: "required|numeric|digits:10",
        password: "required|password_validation",
        password_confirmation: "required|same:password|password_validation",
    });

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    const toggleCnfPassword = () => {
        if (passwordCnfType === "password") {
            setCnfPasswordType("text")
            return;
        }
        setCnfPasswordType("password")
    }
    
    const onSubmit = async (event) => {
        const isValid = await form.validate(event);
        const formData = new FormData();
        // var dates = format(new Date(fields.event_date), 'd MMMM yyyy');
        if (isValid) {
            setBtnDisable(true);
            formData.append('full_name', fields.full_name);
            formData.append('designation', fields.designation);
            formData.append('contact_number', fields.contact_number);
            formData.append('company_name', fields.company_name);
            formData.append('email', fields.email);
            formData.append('password', fields.password);
            formData.append('type', "Admin");
           
          async function saveBasicDetails() {
           
                let data = await AdminService.setUserDetails(formData);
                if(!data){
                   
                    return false;
                }
            navigate('/user-list');
            setTimeout(() => {
                setBtnDisable(false);
                // props.setToggleStateClid(2);
            }, MINUTE_MS);
           }
           saveBasicDetails(); 
        }
      }
      useEffect(() => {
        if (!AuthService.isLoggedInAdmin()) {
            navigate(process.env.PUBLIC_URL + "/event-super-admin-login");
        }
        form.registerAsync("password_validation", function (
            str,
            attribute,
            req,
            passes
        ) {
            var specials = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/g;
            if (!specials.test(str))
                passes(false, "Password should have minimum eight characters, at least one letter, one number and one special character");
            else passes();
        });
    }, []);
    return (
        (loading) ? (<RotatingTriangles
            height="100"
            width="100"
            color="#fff"
            ariaLabel="rotating-triangels-loading"
            wrapperStyle={{}}
            wrapperClass="triangle-loading"
            visible={loading}
          />) : (
       
        <div className="page-wrapper">
        <Sidebar sidebarFor="Super-Admin" />

        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-contributor contributor-basic-details">
                <div className="page-header">
                    <h4>User Add</h4>
                </div>
                <div className="card">
                    <div className="card-body">
                        <form noValidate autoComplete="off" onSubmit={onSubmit} className="signupform" id="registerform">
                            <div className="add-event-form form-regular">
                                <div className="form-cap-card">                                    
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">FullName <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="full_name" placeholder="FullName" className="input-control" type="text" name="full_name" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.full_name} />
                                                <label className="errors"> {errors.full_name ? errors.full_name : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Designation <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="designation" placeholder="Designation" className="input-control" type="text" name="designation" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.venue} />
                                                <label className="errors"> {errors.designation ? errors.designation : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="formGroup">
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Contact Number <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input  id="contact_number" placeholder="Contact Number" className="input-control" type="text" name="contact_number" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.contact_number} />
                                                <label className="errors"> {errors.contact_number ? errors.contact_number : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Email <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input type="text" name="email" placeholder="Email" className="input-control" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}/>
                                                   
                                                <label className="errors"> {errors.email ? errors.email : ""} </label>
                                            </div>
                                        </div>
                                        
                                       
                                    </div>
                                    <div className="formGroup">
                                    
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Password <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                            <input type={passwordType} id="password" placeholder="Password" className="input-control" name="password" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password} data-attribute-name="password" data-async  />
                                    <span className="pi-wpr" ><i onClick={togglePassword} className="fi fi-visibility-black-o"></i></span>
                                                   
                                                <label className="errors"> {errors.password ? errors.password : ""} </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="" className="input-label">Confirm Password <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                            <input type={passwordCnfType} id="password_confirmation" placeholder="Confirm Password" className="input-control" name="password_confirmation" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent} value={fields.password_confirmation} data-attribute-name="password_confirmation" data-async />
                                    <span className="pi-wpr" ><i onClick={toggleCnfPassword} className="fi fi-visibility-black-o"></i></span>
                                                   
                                                <label className="errors"> {errors.password_confirmation ? errors.password_confirmation : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formGroup">
                                    <div className="col-6">
                                            <label htmlFor="" className="input-label">Company Name <span className="asterisk">*</span></label>
                                            <div className="form-wrapper">
                                                <input type="text" name="company_name" placeholder="Company Name" className="input-control" onBlur={form.handleBlurEvent} onChange={form.handleChangeEvent}/>
                                                   
                                                <label className="errors"> {errors.company_name ? errors.company_name : ""} </label>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                    
                            <div className="fg form-action" style={{ display: 'flex', justifyContent: 'center' }}>

                            {(btn_disable) ? (<ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                        />) : (<button type="submit" className="submit-btn">Create User</button>)}
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
          )
    )
     }
export default UserAdd;
