import React, { useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "./pagination";
import AdminService from '../../services/event/admin.service';
import { format } from 'date-fns'

function UserListComponent(props) {

    const { userResult, currentPage, limit, totalPages, totalResults, handlePagination, handleSearch, pageType } = props;
    const [activeRows, setActiveRows] = useState([]);
    const MINUTE_MS = 6000;//10 sec 
    const toggleRow = (index) => {
      setActiveRows(activeRows.includes(index) 
        ? activeRows.filter((i) => i !== index) 
        : [...activeRows, index]
      );
    };
console.log('userResult', userResult.rows);
    const deactivateUser = async (id) => {
        let payload={
            "is_active":0,
            "id":id
            }
        const deactivate = await AdminService.deleteUser(payload);
        if (deactivate.status === 'fail' || deactivate.status === 'error') {
            return false;
        }
        setTimeout(() => {
            window.location.reload();
        }, MINUTE_MS);
    };

    const activateUser = async (id) => {
        let payload={
            "is_active":1,
            "id":id
            }
        const activate = await AdminService.deleteUser(payload);
        if (activate.status === 'fail' || activate.status === 'error') {
            return false;
        }
        setTimeout(() => {
            window.location.reload();
        }, MINUTE_MS);
        // await userResult();
    };


    return (
        <div>
            <div className="search-filter">
                <div className="search-form has-search-icon">
                    <input type="text" className="input-control" placeholder="Search By Email" onChange={handleSearch}></input>
                    <span className="icon-left">
                        <i className="fi fi-search-black"></i>
                    </span>
                </div>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                        <th></th>
                             <th>FullName</th>
                             <th>Company Name</th>
                             <th>Email</th>
                             <th>Contact Number</th>
                             <th>Status</th>
                             <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {userResult && userResult.map((users, index) => (
                        <>
                       
                        <tr className={`tblflx ${activeRows.includes(index) ? "tr_active" : ""}`}>
                        <td><a className="tr_toggle_action" onClick={() => toggleRow(index)}><i className="fi fi-chevron-right-black"></i></a></td>
                          
                            <td data-label="Name">{users?.fullname}</td>
                            <td data-label="Email">{users?.company_name}</td>
                            <td data-label="Status">{users?.email}</td>
                            <td data-label="Status">{users?.contact_number}</td>  
                            <td data-label="Status"> 
                            {(users.is_active === true) ? (
                                <button className="deactive-btn" type="button" onClick={() => deactivateUser(users.id)}>Activate </button>
                            ) : null}
                            {users.is_active === false ? (
                                <button className="deactive-btn" type="button" onClick={() => activateUser(users.id)}>Deactivate</button>
                            ) : null}</td>  
                            <td data-label="Action" className="action">
                            {(users.is_active === true) ? (
                                <Link
                                    to={process.env.PUBLIC_URL+`/user-edit/${users?.id}`}
                                    className="action-btn"
                                >
                                    <i className="fi fi-arrow-left-short"></i>
                                </Link>
                                ) : (
                                   
                                    <button className="deactive-btn" type="button">Edit not allow</button>
                                )}
                            </td>  
                        </tr>                        
                        </>
                         ))}
                    </tbody>
                </table>
            </div>
            {userResult.rows && userResult.rows.length > 0 && (
            <Pagination 
            currentPage={currentPage}
            limit={limit}
            totalPages={totalPages}
            totalResults={totalResults}
            handlePagination={handlePagination}
            />
            )}
        </div>
    )
}
export default UserListComponent;
