import React from "react";
import '../../css/file18.css';
import Sidebar from '../../components/sidebarComponent';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AdminService from '../../services/admin.service';
import AuthService from '../../services/auth.service';
import { RotatingTriangles } from 'react-loader-spinner';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { ColorRing } from 'react-loader-spinner'

function AdminReportersList() {
  const navigate = useNavigate();
  const params = useParams();
  const [btn_disable, setBtnDisable] = useState(false);
  const MINUTE_MS = 2000;//5 sec 
  const [modalFilename, setmodalFilename] = useState("");
  const [open, setOpen] = useState(false);

  const handleDownload = (imageUrl) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        const fileExtension = imageUrl.split(".").pop().toLowerCase();
        const timestamp = Date.now(); // Unique timestamp
        const filename = `image_${timestamp}.${fileExtension}`; // Unique filename
         a.download = filename;

        a.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading image:", error);
      });
  };

  const viewPDF = (data, type) => {
    let win = window.open("", "_blank", "titlebar=yes,width=600,height=600");
  
    if (win) {
      const documentContent = `<!DOCTYPE html>
        <html>
          <head>
            <title>PDF in HTML</title>
          </head>
          <body>
            <iframe src="https://docs.google.com/gview?url=${data}&embedded=true" 
              style="width:600px; height:500px;" frameborder="0"></iframe>
          </body>
        </html>`;
  
      win.document.write(documentContent);
  
      // Close the window and reload the page when the window loses focus
      win.addEventListener("blur", () => {
        win.close();
        win = null;
        // window.location.reload();
      });
    }
  };

  const onOpenModal = (filename) => {
    setmodalFilename(filename);
    setOpen(true);

  };
  const onCloseModal = () => {
    setOpen(false);
    setmodalFilename("");
  };
  const [reporters, setReporters] = useState([]);
  const [loading, setLoading] = useState(true);
  const id = params.id;
  const rejectReason = ["Quality poor", "Offensive content", "Image not appropriate", "Others"];
  const initialState = { alt: "", src: "", file_status: "", comments: "", document_path: "" };
  const [{ alt, src, photo_status, photo_comments }, setPhotoPreview] = useState(initialState);
  const [{ alt_s, src_s, signature_status, signature_comments }, setSignaturePreview] = useState(initialState);
  const [{ file_pan_name, file_pan_status, file_pan_comments, file_pan_path }, setFilePan] = useState(initialState);
  const [{ file_a_name, file_a_status, file_a_comments, file_a_path }, setFileAadhar] = useState(initialState);
  const [{ file_cc_name, file_cc_status, file_cc_comments, file_cc_path }, setFileCC] = useState(initialState);
  const [{ file_resume_name, file_resume_status, file_resume_comments, file_resume_path }, setFileResume] = useState(initialState);
  const [{ file_contributor_name, file_contributor_status, file_contributor_comments, file_contributor_path }, setFileContributor] = useState(initialState);


  const [{ file_bank_statement_name, file_bank_statement_status, file_bank_statement_comments, file_bank_statement_path }, setFileBankStatement] = useState(initialState);
  const [{ file_gst_declaration_name, file_gst_declaration_status, file_gst_declaration_comments, file_gst_declaration_path }, setFileGstDeclaration] = useState(initialState);
  const [{ file_self_declaration_name, file_self_declaration_status, file_self_declaration_comments, file_self_declaration_path }, setFileSelfDeclaration] = useState(initialState);
  const [{ file_pan_adhar_name, file_pan_adhar_status, file_pan_adhar_comments, file_pan_adhar_path }, setFilePanAdhar] = useState(initialState);

  
  const [selectPan, setselectPan] = useState(false);
  const [selectAadhaar, setselectAadhaar] = useState(false);
  const [selectCC, setselectCC] = useState(false);
  const [selectResume, setselectResume] = useState(false);
  const [selectPhoto, setselectPhoto] = useState(false);
  const [selectSign, setselectSign] = useState(false);
  const [inputPan, setInputPan] = useState(true);
  const [inputAadhaar, setInputAadhaar] = useState(true);
  const [inputCC, setInputCC] = useState(true);
  const [inputResume, setInputResume] = useState(true);

  const [inputContributor, setInputContributor] = useState(true);
  const [selectContributor, setselectContributor] = useState(false);
 
  const [inputBankStatement, setInputBankStatement] = useState(true);
  const [selectBankStatement, setselectBankStatement] = useState(false);

  const [inputGstDeclaration, setInputGstDeclaration] = useState(true);
  const [selectGstDeclaration, setselectGstDeclaration] = useState(false);

  const [inputSelfDeclaration, setInputSelfDeclaration] = useState(true);
  const [selectSelfDeclaration, setselectSelfDeclaration] = useState(false);

  const [inputPanAdhar, setInputPanAdhar] = useState(true);
  const [selectPanAdhar, setselectPanAdhar] = useState(false);


  const [inputPhoto, setInputPhoto] = useState(true);
  const [inputSign, setInputSign] = useState(true);
  const [errors_file_pan_reason, setErrorsFilePanReason] = useState("");
  const [errors_file_pan_comments, setErrorsFilePanComments] = useState("");
  const [errors_file_a_reason, setErrorsFileAReason] = useState("");
  const [errors_file_a_comments, setErrorsFileAComments] = useState("");
  const [errors_file_cc_reason, setErrorsFileCCReason] = useState("");
  const [errors_file_cc_comments, setErrorsFileCCComments] = useState("");
  const [errors_file_resume_reason, setErrorsFileResumeReason] = useState("");
  const [errors_file_resume_comments, setErrorsFileResumeComments] = useState("");
  const [errors_photo_reason, setErrorsFilePhotoReason] = useState("");
  const [errors_photo_comments, setErrorsFilePhotoComments] = useState("");
  const [errors_signature_reason, setErrorsFileSignatureReason] = useState("");
  const [errors_signature_comments, setErrorsFileSignatureComments] = useState("");


  const [errors_file_contributor_reason, setErrorsFileContributorReason] = useState("");
  const [errors_file_contributor_comments, setErrorsFileContributorComments] = useState("");

  const [errors_file_bank_statement_reason, setErrorsFileBankStatementReason] = useState("");
  const [errors_file_file_bank_comments, setErrorsFileBankStatementComments] = useState("");

  const [errors_file_gst_declaration_reason, setErrorsFileGstDeclarationReason] = useState("");
  const [errors_file_gst_declaration_comments, setErrorsFileGstDeclarationComments] = useState("");

  const [errors_file_self_declaration_reason, setErrorsFileSelfDeclarationReason] = useState("");
  const [errors_file_self_declaration_comments, setErrorsFileSelfDeclarationComments] = useState("");

  const [errors_file_pan_adhar_reason, setErrorsFilePanAdharReason] = useState("");
  const [errors_file_pan_adhar_comments, setErrorsFilePanAdharComments] = useState("");


  const [formData, updateFormData] = React.useState({});
  const updateData = e => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  const panToggler = (event) => {
    setselectPan(event.target.checked);
    if (event.target.checked) {
      setErrorsFilePanReason('');
      setErrorsFilePanComments('');
      }
    event.target.value = event.target.checked;
    updateData(event);
  }
  const aadhaarToggler = (event) => {
    setselectAadhaar(event.target.checked);
    if (event.target.checked) {
      setErrorsFileAReason('');
      setErrorsFileAComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }
  const ccToggler = (event) => {
    setselectCC(event.target.checked);
    if (event.target.checked) {
      setErrorsFileCCReason('');
      setErrorsFileCCComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }
  const resumeToggler = (event) => {
    setselectResume(event.target.checked);
    if (event.target.checked) {
      setErrorsFileResumeReason('');
      setErrorsFileResumeComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }
  const contributorToggler = (event) => {
    setselectContributor(event.target.checked);
    if (event.target.checked) {
      setErrorsFileContributorReason('');
      setErrorsFileContributorComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }
  const bankStatementToggler = (event) => {
    setselectBankStatement(event.target.checked);
    if (event.target.checked) {
      setErrorsFileBankStatementReason('');
      setErrorsFileBankStatementComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }

  const gstDeclarationToggler = (event) => {
    setselectGstDeclaration(event.target.checked);
    if (event.target.checked) {
      setErrorsFileGstDeclarationReason('');
      setErrorsFileGstDeclarationComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }
  const selfDeclarationToggler = (event) => {
    setselectSelfDeclaration(event.target.checked);
    if (event.target.checked) {
      setErrorsFileSelfDeclarationReason('');
      setErrorsFileSelfDeclarationComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }

  const panAdharToggler = (event) => {
    setselectPanAdhar(event.target.checked);
    if (event.target.checked) {
      setErrorsFilePanAdharReason('');
      setErrorsFilePanAdharComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }

 





  const photoToggler = (event) => {
    setselectPhoto(event.target.checked);
    if (event.target.checked) {
      setErrorsFilePhotoReason('');
      setErrorsFilePhotoComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }
  const signatureToggler = (event) => {
    setselectSign(event.target.checked);
    if (event.target.checked) {
      setErrorsFileSignatureReason('');
      setErrorsFileSignatureComments('');
    }
    event.target.value = event.target.checked;
    updateData(event);
  }


  

  const panInput = (event) => {
    updateData(event);
    setInputPan((event.target.value == "Others") ? false : true);
    setErrorsFilePanReason('');
  }
  const aadhaarInput = (event) => {
    updateData(event);
    setInputAadhaar((event.target.value =="Others") ? false : true);
    setErrorsFileAReason('');
  }
  const ccInputToggler = (event) => {
    updateData(event);
    setInputCC((event.target.value == "Others") ? false : true);
    setErrorsFileCCReason('');
  }
  const resumeInputToggler = (event) => {
    updateData(event);
    setInputResume((event.target.value == "Others") ? false : true);
    setErrorsFileResumeReason('');
  }
  const photoInputToggler = (event) => {
    updateData(event);
    setInputPhoto((event.target.value == "Others") ? false : true);
    setErrorsFilePhotoReason('');
  }
  const signatureInputToggler = (event) => {
    updateData(event);
    setInputSign((event.target.value =="Others") ? false : true);
    setErrorsFileSignatureReason('');
  }
  const contributorInputToggler = (event) => {
    updateData(event);
    setInputContributor((event.target.value == "Others") ? false : true);
    setErrorsFileContributorReason('');
  }


  const bankStatementInputToggler = (event) => {
    updateData(event);
    setInputBankStatement((event.target.value == "Others") ? false : true);
    setErrorsFileBankStatementReason('');
  }

  const gstDeclarationInputToggler = (event) => {
    updateData(event);
    setInputGstDeclaration((event.target.value == "Others") ? false : true);
    setErrorsFileGstDeclarationReason('');
  }


  const selfDeclarationInputToggler = (event) => {
    updateData(event);
    setInputSelfDeclaration((event.target.value == "Others") ? false : true);
    setErrorsFileSelfDeclarationReason('');
  }


  const panAdharInputToggler = (event) => {
    updateData(event);
    setInputPanAdhar((event.target.value == "Others") ? false : true);
    setErrorsFilePanAdharReason('');
  }



  const panComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
      setErrorsFilePanComments('');
  }
  const aadhaarComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
      setErrorsFileAComments('');
  }
  const ccInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
      setErrorsFileCCComments('');
  }
  const resumeInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
      setErrorsFileResumeComments('');
  }

  const contributorInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
      setErrorsFileContributorComments('');
  }

  const bankStatementInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
    setErrorsFileBankStatementComments('');
  }

  const gstDeclarationInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
    setErrorsFileGstDeclarationComments('');
  }

  const selfDeclarationInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
    setErrorsFileSelfDeclarationComments('');
  }

  const panAdharInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
    setErrorsFilePanAdharComments('');
  }



  const photoInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
      setErrorsFilePhotoComments('');
  }
  const signatureInputComment = (event) => {
    updateData(event);
    if (event.target.value !== '')
      setErrorsFileSignatureComments('');
  }

  const fetchData = async () => {
    const result = await AdminService.getVendorDetails(id);
    // if (result[0].vendor_documents) {
      if (result && result.length > 0 && result[0].vendor_documents) {
        result[0].vendor_documents.map(function (val, index) {
        if (val.document_type === "photograph") {
          setPhotoPreview(
            {
              src: val.document_path,
              alt: val.document_name,
              photo_status: val.status,
              photo_comments: (val.comments) ? val.comments : ""
            }
          );
          if (val.status == "Accepted")
            setselectPhoto(true);
        }
        if (val.document_type == "signature") {
          setSignaturePreview(
            {
              src_s: val.document_path,
              alt_s: val.document_name,
              signature_status: val.status,
              signature_comments: (val.comments) ? val.comments : ""
            }
          );
          if (val.status == "Accepted")
            setselectSign(true);
        }
        if (val.document_type == "pan card") {
          setFilePan({
            file_pan_path: val.document_path,
            file_pan_name: val.document_name,
            file_pan_status: val.status,
            file_pan_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
            setselectPan(true);
        }
        if (val.document_type == "aadhaar card") {
          setFileAadhar({
            file_a_path: val.document_path,
            file_a_name: val.document_name,
            file_a_status: val.status,
            file_a_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
            setselectAadhaar(true);
        }
        if (val.document_type == "cancelled cheque") {
          setFileCC({
            file_cc_path: val.document_path,
            file_cc_name: val.document_name,
            file_cc_status: val.status,
            file_cc_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
            setselectCC(true);
        }
        if (val.document_type == "resume") {
          setFileResume({
            file_resume_path: val.document_path,
            file_resume_name: val.document_name,
            file_resume_status: val.status,
            file_resume_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
            setselectResume(true);
        }
        if (val.document_type == "contributor_form") {
          setFileContributor({
            file_contributor_path: val.document_path,
            file_contributor_name: val.document_name,
            file_contributor_status: val.status,
            file_contributor_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
            setselectContributor(true);
        }
        if (val.document_type == "bank_statement") {
          setFileBankStatement({
            file_bank_statement_path: val.document_path,
            file_bank_statement_name: val.document_name,
            file_bank_statement_status: val.status,
            file_bank_statement_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
          setselectBankStatement(true);
        }
        if (val.document_type == "gst_form") {
          setFileGstDeclaration({
            file_gst_declaration_path: val.document_path,
            file_gst_declaration_name: val.document_name,
            file_gst_declaration_status: val.status,
            file_gst_declaration_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
          setselectGstDeclaration(true);
        }
        if (val.document_type == "declaration_form") {
          setFileSelfDeclaration({
            file_self_declaration_path: val.document_path,
            file_self_declaration_name: val.document_name,
            file_self_declaration_status: val.status,
            file_self_declaration_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
          setselectSelfDeclaration(true);
        }
        if (val.document_type == "pan_aadhar_undertaking") {
          setFilePanAdhar({
            file_pan_adhar_path: val.document_path,
            file_pan_adhar_name: val.document_name,
            file_pan_adhar_status: val.status,
            file_pan_adhar_comments: (val.comments) ? val.comments : ""
          });
          if (val.status == "Accepted")
          setselectPanAdhar(true);
        }
      })
    }
    setReporters(result || []);
    setLoading(false);
  }

  useEffect(() => {
    if (!AuthService.isLoggedInUser()) {
      navigate(process.env.PUBLIC_URL + "/admin-login");
    } else {
      fetchData();
    }
  }, []);

  const formValidate = (event) => {
    return true;
  }

  const handleSubmit = async (event) => {
    //console.log("formData?", formData)
    event.preventDefault();
    // return;
    const isValid = await formValidate(event);
    if (isValid) {
      setBtnDisable(true);
      const formDataObj = new FormData();
     // console.log(formData.accept_pan, "formData.accept_pan");
      formDataObj.append('pan_card', (formData?.accept_pan) ? formData.accept_pan : selectPan);
      formDataObj.append('pan_card_reason', (formData?.file_pan_reason && formData.file_pan_reason !== 'Others') ? formData?.file_pan_reason || '' : formData?.file_pan_comments || file_pan_comments);
      formDataObj.append('aadhaar_card', (formData?.accept_a) ? formData.accept_a : selectAadhaar);
      formDataObj.append('aadhaar_card_reason', (formData?.file_a_reason && formData.file_a_reason !== 'Others') ? formData?.file_a_reason || '' : formData?.file_a_comments || file_a_comments);
      formDataObj.append('cancelled_cheque', (formData?.accept_cc) ? formData.accept_cc : selectCC);
      formDataObj.append('cancelled_cheque_reason', (formData?.file_cc_reason && formData.file_cc_reason !== 'Others') ? formData?.file_cc_reason || '' : formData.file_cc_comments || file_cc_comments);
      formDataObj.append('resume', (formData?.accept_resume) ? formData.accept_resume : selectResume);
      formDataObj.append('resume_reason', (formData?.file_resume_reason && formData.file_resume_reason !== 'Others') ? formData?.file_resume_reason || '' : formData.file_resume_comments || file_resume_comments);
      formDataObj.append('photograph', (formData?.accept_photo) ? formData.accept_photo : selectPhoto);
      formDataObj.append('photograph_reason', (formData?.photo_reason && formData.photo_reason !== 'Others') ? formData?.photo_reason || '' : formData.photo_comments || photo_comments);
      formDataObj.append('signature', (formData?.accept_signature) ? formData.accept_signature : selectSign);
      formDataObj.append('signature_reason', (formData?.signature_reason && formData.signature_reason !== 'Others') ? formData.signature_reason || '' : formData.signature_comments || signature_comments);
      
      formDataObj.append('bank_statement', (formData?.accept_bank_statement) ? formData.accept_bank_statement : selectBankStatement);
      formDataObj.append('gst_declaration', (formData?.accept_gst_declaration) ? formData.accept_gst_declaration : selectGstDeclaration);
      formDataObj.append('self_declaration', (formData?.accept_self_declaration) ? formData.accept_self_declaration : selectSelfDeclaration);
      formDataObj.append('contributor', (formData?.accept_contributor) ? formData.accept_contributor : selectContributor);
      formDataObj.append('pan_adhar', (formData?.accept_pan_adhar) ? formData.accept_pan_adhar : selectPanAdhar);

      formDataObj.append('contributor_reason', (formData?.file_contributor_reason && formData.file_contributor_reason !== 'Others') ? formData?.file_contributor_reason || '' : formData.file_contributor_comments || file_contributor_comments);
      formDataObj.append('bank_statement_reason', (formData?.file_bank_statement_reason && formData.file_bank_statement_reason !== 'Others') ? formData?.file_bank_statement_reason || '' : formData.file_bank_statement_comments || file_bank_statement_comments);
      formDataObj.append('gst_declaration_reason', (formData?.file_gst_declaration_reason && formData.file_gst_declaration_reason !== 'Others') ? formData?.file_gst_declaration_reason || '' : formData.file_gst_declaration_comments || file_gst_declaration_comments);
      formDataObj.append('self_declaration_reason', (formData?.file_self_declaration_reason && formData.file_self_declaration_reason !== 'Others') ? formData?.file_self_declaration_reason || '' : formData.file_self_declaration_comments || file_self_declaration_comments);
      formDataObj.append('pan_adhar_reason', (formData?.file_pan_adhar_reason && formData.file_pan_adhar_reason !== 'Others') ? formData?.file_pan_adhar_reason || '' : formData.file_pan_adhar_comments || file_pan_adhar_comments);


      formDataObj.append('vendorId', id);
      console.log(formDataObj, "formDataObj");
      async function saveAdminStatus() {
        let data = await AdminService.saveAdminStatus(formDataObj);
        if (!data) {
          return false;
        }
        else{
         await new Promise(resolve => setTimeout(resolve, MINUTE_MS));
         navigate(`${process.env.PUBLIC_URL}/admin-all-listing`);
        }
      }
      // setTimeout(() => {
      //   setBtnDisable(false);
      // }, MINUTE_MS);
     //saveAdminStatus();

      await saveAdminStatus();

    }
  };

  return (
    (loading) ? (<RotatingTriangles
      height="100"
      width="100"
      color="#fff"
      ariaLabel="rotating-triangels-loading"
      wrapperStyle={{}}
      wrapperClass="triangle-loading"
      visible={loading}
    />) : (
      <>
        <div className="page-wrapper">
          <Sidebar sidebarFor="Admin" />
          <div className="content-wrapper">
            <div className="page-content page-contributor-preview admin-reporter-details">
              <div className="page-header">
                <h4 className="section-title">Reporter Details</h4>
                <div className="breadcrumb-w">
                  <ol className="ms-breadcrumb">
                    <li><a href={process.env.PUBLIC_URL + `/admin-all-listing`} >Form- All</a></li>
                    <li className="active"> {reporters.length > 0 ? (
                      <>{reporters[0].vendor_info?.firstname || ""} {reporters[0].vendor_info?.lastname || ""}</>
                    ) : (
                      ""
                    )}</li>
                  </ol>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <form className="contributor-form" onSubmit={handleSubmit}>
                    <section className="section-basic-details">
                      <div className="cap-wrapper">
                        <div className="form-caption">Personal Details</div>
                        <div className="preview-data">
                          <div className="pre-label">Name</div>
                          <div className="pre-value"><label className="input-label">{reporters?.[0]?.vendor_info?.firstname ?? ''} {reporters?.[0]?.vendor_info?.lastname ?? ''}</label></div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Gender</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.gender}
                          </div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">DOB</div>
                          <div className="pre-value">{new Date(reporters[0]?.vendor_info?.dob).toLocaleString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }).replace(/\//g, '-') || ""}</div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Nationality</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.nationality || ""}</div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Marital Status</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.marital_status || ""}</div>
                        </div>
                      </div>
                      <div className="cap-wrapper">
                        <div className="form-caption">Family Details</div>
                        <div className="preview-data">
                          <div className="pre-label">Father's Name</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.f_firstname || ""} {reporters[0]?.vendor_info?.f_lastname || ""}</div>
                        </div>
                        <div className="preview-data">
                          <div className="pre-label">Mother's Name</div>
                          <div className="pre-value">{reporters[0]?.vendor_info?.m_firstname || ""} {reporters[0]?.vendor_info?.m_lastname || ""}</div>
                        </div>
                      </div>
                    </section>
                    <section className="section-address">
                      <div className="cap-wrapper">
                        <div className="form-caption">Present Address</div>
                        <div className="preview-data">
                          <div className="pre-value">
                            {reporters[0]?.vendor_address?.flat_no_present || ''}{' '}
                            {reporters[0]?.vendor_address?.landmark_present || ''}<br />
                            {reporters[0]?.vendor_address?.village_present || ''}{' '}
                            {reporters[0]?.vendor_address?.street_present || ''}<br />
                            {reporters[0]?.vendor_address?.locality_present || ''}{' '}
                            {reporters[0]?.vendor_address?.pincode_present || ''}<br />
                            {reporters[0]?.vendor_address?.city_present || ''}{' '}
                            {reporters[0]?.vendor_address?.state_present || ''}<br />
                            {reporters[0]?.vendor_address?.country_present || ''}<br />
                          </div>
                        </div>
                      </div>
                      <div className="cap-wrapper">
                        <div className="form-caption">Permanent Address</div>
                        <div className="preview-data">
                          <div className="pre-value"> {reporters[0]?.vendor_address?.flat_no_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.landmark_permanent || ''} <br />
                            {reporters[0]?.vendor_address?.village_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.street_permanent || ''}<br />
                            {reporters[0]?.vendor_address?.locality_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.pincode_permanent || ''}<br />
                            {reporters[0]?.vendor_address?.city_permanent || ''}{' '}
                            {reporters[0]?.vendor_address?.state_permanent || ''}<br />
                            {reporters[0]?.vendor_address?.country_permanent || ''}<br /></div>
                        </div>
                      </div>
                    </section>
                    <section className="section-doc-upload">
                      <div className="cap-wrapper w-full">
                        <div className="form-caption">Documents Upload</div>
                        <div className="table-responsive">
                          <div className="admin-tbl-wrapper">
                            <table className="table admin-report-tbl">
                              <thead>
                                <tr>
                                  <th width="220px">Proof Name</th>
                                  <th>Attach File</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">Scanned Pan Card <span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                        {/* {file_pan_name && (
                                          <>
                                            <div className="attachment-name">{file_pan_name}</div>
                                            <div className="view-icon"><i onClick={() => onOpenModal(file_pan_path)} className="fi fi-visibility-black"></i></div>
                                          </>
                                        )} */}
                                        {file_pan_name && (
                                          <>
                                            <div className="attachment-name">
                                              {file_pan_name}
                                                <div className="attachment-action">
                                                  {file_pan_path && file_pan_path.endsWith(".pdf") ? (
                                                  <a href={file_pan_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_pan_path)}></i></a>
                                                  )}


                                                  <div className="view-icon">
                                                    <i onClick={() => {
                                                      if (file_pan_path && file_pan_path.endsWith('.pdf')) {
                                                        // Open the PDF file using a PDF viewer
                                                        viewPDF(file_pan_path, 'application/pdf');
                                                      } else {
                                                        // Handle other file types
                                                        onOpenModal(file_pan_path);
                                                      }
                                                    }} className="fi fi-visibility-black"></i>
                                                  </div>
                                              </div>

                                            </div>
                                            
                                            
                                         
                                          </>
                                        )}

                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">

                                          <input defaultChecked={(file_pan_status == "Accepted") ? true : false} type="checkbox" name="accept_pan" id="accept_pan" className="checkbox" onChange={panToggler} />
                                          <div id="pan_toggler" className="checkbox-toggler" >
                                            <div className="checked">Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
                                        <div className="formGroup">
                                          <select defaultValue={(file_pan_status == "Rejected") ? (!rejectReason.includes(file_pan_comments)) ? "Others" : file_pan_comments : ""} onInvalid={e => setErrorsFilePanReason('Select rejected reason')} onChange={panInput} required disabled={selectPan} name="file_pan_reason" id="file_pan_reason" className="input-control">
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_pan_reason ? errors_file_pan_reason : ""} </label>
                                      </div>
                                      <div id="isPanResonOther" className="ar-other-reason" >
                                        <div className="formGroup">
                                          <input defaultValue={file_pan_status === "Accepted" ? "" : 
    !rejectReason.includes(file_pan_comments) ? file_pan_comments : ''
  } onInvalid={e => setErrorsFilePanComments('Please enter reason')} required disabled={inputPan} type="text" className="input-control" placeholder="Enter Reason" name="file_pan_comments" id="file_pan_comments" onChange={panComment} />
                                          <label className="error"> {errors_file_pan_comments ? errors_file_pan_comments : ""} </label>
                                        </div>
                                      </div>

                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">Scanned Aadhaar Card <span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                        {/* {file_a_name && (
                                          <>
                                            <div className="attachment-name">{file_a_name}</div>
                                            <div className="view-icon"><i onClick={() => onOpenModal(file_a_path)} className="fi fi-visibility-black"></i></div>
                                          </>
                                        )} */}
                                        {file_a_name && (
                                          <>
                                            <div className="attachment-name">
                                              {file_a_name}
                                              <div className="attachment-action">
                                                 {file_a_path && file_a_path.endsWith(".pdf") ? (
                                                  <a href={file_a_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_a_path)}></i></a>
                                                  )}
                                                  <div className="view-icon">
                                                    <i onClick={() => {
                                                      if (file_a_path && file_a_path.endsWith('.pdf')) {
                                                        // Open the PDF file using a PDF viewer
                                                        viewPDF(file_a_path, 'application/pdf');
                                                      } else {
                                                        // Handle other file types
                                                        onOpenModal(file_a_path);
                                                      }
                                                    }} className="fi fi-visibility-black"></i>
                                                  </div>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_a_status == "Accepted") ? true : false} type="checkbox" onChange={aadhaarToggler} name="accept_a" id="accept_a" className="checkbox" />
                                          <div id="aadhaar_toggler" className="checkbox-toggler">
                                            <div className="checked">Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
                                        <div className="formGroup">
                                          <select defaultValue={(file_a_status == "Rejected") ? (!rejectReason.includes(file_a_comments)) ? "Others" : file_a_comments : ""} onInvalid={e => setErrorsFileAReason('Select rejected reason')} required onChange={aadhaarInput} disabled={selectAadhaar} name="file_a_reason" id="file_a_reason" className="input-control">
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_a_reason ? errors_file_a_reason : ""} </label>
                                      </div>
                                      <div id="isAadhaarResonOther" className="ar-other-reason" >
                                        <div className="formGroup">
                                          {/* <input defaultValue={(!rejectReason.includes(file_a_comments)) ? file_a_comments : ''} onInvalid={e => setErrorsFileAComments('Please enter reason')} required disabled={inputAadhaar} type="text" className="input-control" placeholder="Enter Reason" name="file_a_comments" id="file_a_comments" onChange={aadhaarComment} /> */}
                                          <input defaultValue={file_a_status === "Accepted" ? "" : 
    !rejectReason.includes(file_a_comments) ? file_a_comments : ''
  } onInvalid={e => setErrorsFileAComments('Please enter reason')} required disabled={inputAadhaar} type="text" className="input-control" placeholder="Enter Reason" name="file_a_comments" id="file_a_comments" onChange={aadhaarComment} />

                                        </div>
                                        <label className="error"> {errors_file_a_comments ? errors_file_a_comments : ""} </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">Cancelled Cheque <span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                        {/* {file_cc_name && (
                                          <>
                                            <div className="attachment-name">{file_cc_name}</div>
                                            <div className="view-icon"><i onClick={() => onOpenModal(file_cc_path)} className="fi fi-visibility-black"></i></div>
                                          </>
                                        )} */}


                                        {file_cc_name && (
                                          <>
                                            <div className="attachment-name">{file_cc_name}
                                              <div className="attachment-action">
                                              {file_cc_path && file_cc_path.endsWith(".pdf") ? (
                                                  <a href={file_cc_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_cc_path)}></i></a>
                                                  )}
                                                <div className="view-icon">
                                                  <i onClick={() => {
                                                    if (file_cc_path && file_cc_path.endsWith('.pdf')) {
                                                      // Open the PDF file using a PDF viewer
                                                      viewPDF(file_cc_path, 'application/pdf');
                                                    } else {
                                                      // Handle other file types
                                                      onOpenModal(file_cc_path);
                                                    }
                                                  }} className="fi fi-visibility-black"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_cc_status == "Accepted") ? true : false} type="checkbox" onChange={ccToggler} name="accept_cc" id="accept_cc" className="checkbox" />
                                          <div id="cc_toggler" className="checkbox-toggler">
                                            <div className="checked">Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
                                        <div className="formGroup">
                                          <select defaultValue={(file_cc_status == "Rejected") ? (!rejectReason.includes(file_cc_comments)) ? "Others" : file_cc_comments : ""} onInvalid={e => setErrorsFileCCReason('Select rejected reason')} required onChange={ccInputToggler} disabled={selectCC} name="file_cc_reason" id="file_cc_reason" className="input-control"  >
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_cc_reason ? errors_file_cc_reason : ""} </label>
                                      </div>
                                      <div id="isCCResonOther" className="ar-other-reason">
                                        <div className="formGroup">
                                          {/* <input defaultValue={(!rejectReason.includes(file_cc_comments)) ? file_cc_comments : ''} onInvalid={e => setErrorsFileCCComments('Please enter reason')} required disabled={inputCC} type="text" className="input-control" placeholder="Enter Reason" name="file_cc_comments" id="file_cc_comments" onChange={ccInputComment} /> */}
                                          <input defaultValue={file_cc_status === "Accepted" ? "" : 
    !rejectReason.includes(file_cc_comments) ? file_cc_comments : ''
  } onInvalid={e => setErrorsFileCCComments('Please enter reason')} required disabled={inputCC} type="text" className="input-control" placeholder="Enter Reason" name="file_cc_comments" id="file_cc_comments" onChange={ccInputComment} />

                                          <label className="error"> {errors_file_cc_comments ? errors_file_cc_comments : ""} </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">CV/Resume <span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>


                                        {file_resume_name && (
                                          <>
                                            <div className="attachment-name">
                                              {file_resume_name}
                                              <div className="attachment-action">
                                              {file_resume_path && file_resume_path.endsWith(".pdf") ? (
                                                  <a href={file_resume_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_resume_path)}></i></a>
                                                  )}
                                                <div className="view-icon">
                                                  <i onClick={() => {
                                                    if (file_resume_path && file_resume_path.endsWith('.pdf')) {
                                                      // Open the PDF file using a PDF viewer
                                                      viewPDF(file_resume_path, 'application/pdf');
                                                    } else {
                                                      // Handle other file types
                                                      onOpenModal(file_resume_path);
                                                    }
                                                  }} className="fi fi-visibility-black"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_resume_status == "Accepted") ? true : false} type="checkbox" onChange={resumeToggler} name="accept_resume" id="accept_resume" className="checkbox" />
                                          <div id="resume_toggler" className="checkbox-toggler">
                                            <div className="checked">Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
                                        <div className="formGroup">
                                          <select defaultValue={(file_resume_status == "Rejected") ? (!rejectReason.includes(file_resume_comments)) ? "Others" : file_resume_comments : ""} onInvalid={e => setErrorsFileResumeReason('Select rejected reason')} required onChange={resumeInputToggler} disabled={selectResume} name="file_resume_reason" id="file_resume_reason" className="input-control"  >
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_resume_reason ? errors_file_resume_reason : ""} </label>
                                      </div>
                                      <div id="isResumeResonOther" className="ar-other-reason">
                                        <div className="formGroup">
                                          <input defaultValue={file_resume_status === "Accepted" ? "" : 
    !rejectReason.includes(file_resume_comments) ? file_resume_comments : ''
  } onInvalid={e => setErrorsFileResumeComments('Please enter reason')} required disabled={inputResume} type="text" className="input-control" placeholder="Enter Reason" name="file_resume_comments" id="file_resume_comments" onChange={resumeInputComment} />

                                          <label className="error"> {errors_file_resume_comments ? errors_file_resume_comments : ""} </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                

                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">CONTRIBUTOR OPENING FORM<span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                        {file_contributor_name && (
                                          <>
                                            <div className="attachment-name">
                                              {file_contributor_name}
                                              <div className="attachment-action">
                                                  {file_contributor_path && file_contributor_path.endsWith(".pdf") ? (
                                                  <a href={file_contributor_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_contributor_path)}></i></a>
                                                  )}
                                                  <div className="view-icon">
                                                    <i onClick={() => {
                                                      if (file_contributor_path && file_contributor_path.endsWith('.pdf') || file_contributor_path.endsWith('.xlsx')) {
                                                        // Open the PDF file using a PDF viewer
                                                        viewPDF(file_contributor_path, 'application/pdf');
                                                      } else {
                                                        // Handle other file types
                                                        onOpenModal(file_contributor_path);
                                                      }
                                                    }} className="fi fi-visibility-black"></i>
                                                  </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_contributor_status == "Accepted") ? true : false} type="checkbox" onChange={contributorToggler} name="accept_contributor" id="accept_contributor" className="checkbox" />
                                          <div id="contributor_toggler" className="checkbox-toggler">
                                            <div className="checked">Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
                                        <div className="formGroup">
                                          <select defaultValue={(file_contributor_status == "Rejected") ? (!rejectReason.includes(file_contributor_comments)) ? "Others" : file_contributor_comments : ""} onInvalid={e => setErrorsFileContributorReason('Select rejected reason')} required onChange={contributorInputToggler} disabled={selectContributor} name="file_contributor_reason" id="file_contributor_reason" className="input-control"  >
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_contributor_reason ? errors_file_contributor_reason : ""} </label>
                                      </div>
                                      <div id="isContributorResonOther" className="ar-other-reason">
                                        <div className="formGroup">
                                          <input defaultValue={file_contributor_status === "Accepted" ? "" : 
    !rejectReason.includes(file_contributor_comments) ? file_contributor_comments : ''
  } onInvalid={e => setErrorsFileContributorComments('Please enter reason')} required disabled={inputContributor} type="text" className="input-control" placeholder="Enter Reason" name="file_contributor_comments" id="file_contributor_comments" onChange={contributorInputComment} />

                                          <label className="error"> {errors_file_contributor_comments ? errors_file_contributor_comments : ""} </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">BANK ACCOUNT STATEMENT<span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                        


                                        {file_bank_statement_name && (
                                          <>
                                            <div className="attachment-name">
                                              {file_bank_statement_name}
                                                <div className="attachment-action">
                                                {file_bank_statement_path && file_bank_statement_path.endsWith(".pdf") ? (
                                                  <a href={file_bank_statement_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_bank_statement_path)}></i></a>
                                                  )}
                                                  <div className="view-icon">
                                                    <i onClick={() => {
                                                      if (file_bank_statement_path && file_bank_statement_path.endsWith('.pdf')) {
                                                        // Open the PDF file using a PDF viewer
                                                        viewPDF(file_bank_statement_path, 'application/pdf');
                                                      } else {
                                                        // Handle other file types
                                                        onOpenModal(file_bank_statement_path);
                                                      }
                                                    }} className="fi fi-visibility-black"></i>
                                                  </div>
                                                </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_bank_statement_status == "Accepted") ? true : false} type="checkbox" onChange={bankStatementToggler} name="accept_bank_statement" id="accept_bank_statement" className="checkbox" />
                                          <div id="bank_statement_toggler" className="checkbox-toggler">
                                            <div className="checked" >Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
              
                                        <div className="formGroup">
                                          <select defaultValue={(file_bank_statement_status == "Rejected") ? (!rejectReason.includes(file_bank_statement_comments)) ? "Others" : file_bank_statement_comments : ""} onInvalid={e => setErrorsFileBankStatementReason('Select rejected reason')} required onChange={bankStatementInputToggler} disabled={selectBankStatement} name="file_bank_statement_reason" id="file_bank_statement_reason" className="input-control"  >
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_bank_statement_reason ? errors_file_bank_statement_reason : ""} </label>
                                      </div>
                                      <div id="isBankStatementResonOther" className="ar-other-reason">
                                        <div className="formGroup">
                                          <input defaultValue={file_bank_statement_status === "Accepted" ? "" : 
    !rejectReason.includes(file_bank_statement_comments) ? file_bank_statement_comments : ''
  } onInvalid={e => setErrorsFileBankStatementComments('Please enter reason')} required disabled={inputBankStatement} type="text" className="input-control" placeholder="Enter Reason" name="file_bank_statement_comments" id="file_bank_statement_comments" onChange={bankStatementInputComment} />

                                          <label className="error"> {errors_file_file_bank_comments ? errors_file_file_bank_comments : ""} </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>


                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">GST DECLARATION FORM<span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                       {file_gst_declaration_name && (
                                          <>
                                            <div className="attachment-name">{file_gst_declaration_name}
                                            <div className="attachment-action">
                                                  {file_gst_declaration_path && file_gst_declaration_path.endsWith(".pdf") ? (
                                                  <a href={file_gst_declaration_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_gst_declaration_path)}></i></a>
                                                  )}
                                                  <div className="view-icon">
                                                    <i onClick={() => {
                                                      if (file_gst_declaration_path && file_gst_declaration_path.endsWith('.pdf')) {
                                                        // Open the PDF file using a PDF viewer
                                                        viewPDF(file_gst_declaration_path, 'application/pdf');
                                                      } else {
                                                        // Handle other file types
                                                        onOpenModal(file_gst_declaration_path);
                                                      }
                                                    }} className="fi fi-visibility-black"></i>
                                                  </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_gst_declaration_status == "Accepted") ? true : false} type="checkbox" onChange={gstDeclarationToggler} name="accept_gst_declaration"id="accept_gst_declaration" className="checkbox" />
                                          <div id="gst_declaration_toggler" className="checkbox-toggler">
                                            <div className="checked" >Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
              
                                        <div className="formGroup">
                                          <select defaultValue={(file_gst_declaration_status == "Rejected") ? (!rejectReason.includes(file_gst_declaration_comments)) ? "Others" : file_gst_declaration_comments : ""} onInvalid={e => setErrorsFileGstDeclarationReason('Select rejected reason')} required onChange={gstDeclarationInputToggler} disabled={selectGstDeclaration} name="file_gst_declaration_reason" id="file_gst_declaration_reason" className="input-control"  >
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_gst_declaration_reason ? errors_file_gst_declaration_reason : ""} </label>
                                      </div>
                                      <div id="isGstDeclarationResonOther" className="ar-other-reason">
                                        <div className="formGroup">
                                          <input defaultValue={file_gst_declaration_status === "Accepted" ? "" : 
    !rejectReason.includes(file_gst_declaration_comments) ? file_gst_declaration_comments : ''
  } onInvalid={e => setErrorsFileGstDeclarationComments('Please enter reason')} required disabled={inputGstDeclaration} type="text" className="input-control" placeholder="Enter Reason" name="file_gst_declaration_comments" id="file_gst_declaration_comments" onChange={gstDeclarationInputComment} />

                                          <label className="error"> {errors_file_gst_declaration_comments ? errors_file_gst_declaration_comments : ""} </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">SELF DECLARATION FORM<span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                       {file_self_declaration_name && (
                                          <>
                                            <div className="attachment-name">{file_self_declaration_name}
                                              <div className="attachment-action">
                                                  {file_self_declaration_path && file_self_declaration_path.endsWith(".pdf") ? (
                                                  <a href={file_self_declaration_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_self_declaration_path)}></i></a>
                                                  )}
                                                <div className="view-icon">
                                                  <i onClick={() => {
                                                    if (file_self_declaration_path && file_self_declaration_path.endsWith('.pdf')) {
                                                      // Open the PDF file using a PDF viewer
                                                      viewPDF(file_self_declaration_path, 'application/pdf');
                                                    } else {
                                                      // Handle other file types
                                                      onOpenModal(file_self_declaration_path);
                                                    }
                                                  }} className="fi fi-visibility-black"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_self_declaration_status == "Accepted") ? true : false} type="checkbox" onChange={selfDeclarationToggler} name="accept_self_declaration"id="accept_self_declaration" className="checkbox" />
                                          <div id="self_declaration_toggler" className="checkbox-toggler">
                                            <div className="checked" >Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
              
                                        <div className="formGroup">
                                          <select defaultValue={(file_self_declaration_status == "Rejected") ? (!rejectReason.includes(file_self_declaration_comments)) ? "Others" : file_self_declaration_comments : ""} onInvalid={e => setErrorsFileSelfDeclarationReason('Select rejected reason')} required onChange={selfDeclarationInputToggler} disabled={selectSelfDeclaration} name="file_self_declaration_reason" id="file_self_declaration_reason" className="input-control"  >
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_self_declaration_reason ? errors_file_self_declaration_reason : ""} </label>
                                      </div>
                                      <div id="isSelfDeclarationResonOther" className="ar-other-reason">
                                        <div className="formGroup">
                                          <input defaultValue={file_self_declaration_status === "Accepted" ? "" : 
    !rejectReason.includes(file_self_declaration_comments) ? file_self_declaration_comments : ''
  } onInvalid={e => setErrorsFileSelfDeclarationComments('Please enter reason')} required disabled={inputSelfDeclaration} type="text" className="input-control" placeholder="Enter Reason" name="file_self_declaration_comments" id="file_self_declaration_comments" onChange={selfDeclarationInputComment} />

                                          <label className="error"> {errors_file_self_declaration_comments ? errors_file_self_declaration_comments : ""} </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <div className="tab-label v-mob">Proof Name</div>
                                    <div className="label text-upper">PAN aADHAR-UNDERTAKING<span className="asterisk">*</span></div>
                                  </td>
                                  <td>
                                    <div className="tab-label v-mob">Attach File</div>
                                    <div className="admin-ar-doc">
                                      <div className="attachment has-view-icon">
                                        <div className="attach-icon"><i className="fi fi-file-copy-black"></i></div>
                                       {file_pan_adhar_name && (
                                          <>
                                            <div className="attachment-name">{file_pan_adhar_name}
                                              <div className="attachment-action">
                                                 {file_pan_adhar_path && file_pan_adhar_path.endsWith(".pdf") ? (
                                                  <a href={file_pan_adhar_path}  className="download-icon"  download ><i className="fi fi-file-download"></i>
                                                  </a>  ) : ( <a className="download-icon"><i className="fi fi-file-download" onClick={() => handleDownload(file_pan_adhar_path)}></i></a>
                                                  )}
                                                <div className="view-icon">
                                                  <i onClick={() => {
                                                    if (file_pan_adhar_path && file_pan_adhar_path.endsWith('.pdf')) {
                                                      // Open the PDF file using a PDF viewer
                                                      viewPDF(file_pan_adhar_path, 'application/pdf');
                                                    } else {
                                                      // Handle other file types
                                                      onOpenModal(file_pan_adhar_path);
                                                    }
                                                  }} className="fi fi-visibility-black"></i>
                                                </div>
                                              </div>
                                            </div>
                                            
                                          </>
                                        )}
                                      </div>
                                      <div className="ar-toggler">
                                        <label className="check-w">
                                          <input defaultChecked={(file_pan_adhar_status == "Accepted") ? true : false} type="checkbox" onChange={panAdharToggler} name="accept_pan_adhar"id="accept_pan_adhar" className="checkbox" />
                                          <div id="pan_adhar_toggler" className="checkbox-toggler">
                                            <div className="checked" >Accept</div>
                                            <div className="unchecked">Reject</div>
                                          </div>
                                        </label>

                                      </div>
                                      <div className="ar-sel-reason">
              
                                        <div className="formGroup">
                                          <select defaultValue={(file_pan_adhar_status == "Rejected") ? (!rejectReason.includes(file_pan_adhar_comments)) ? "Others" : file_pan_adhar_comments : ""} onInvalid={e => setErrorsFilePanAdharReason('Select rejected reason')} required onChange={panAdharInputToggler} disabled={selectPanAdhar} name="file_pan_adhar_reason" id="file_pan_adhar_reason" className="input-control"  >
                                            <option value="">Select</option>
                                            <option value="Quality poor">Quality poor</option>
                                            <option value="Offensive content">Offensive content</option>
                                            <option value="Image not appropriate">Image not appropriate</option>
                                            <option value="Others">Others</option>
                                          </select>
                                        </div>
                                        <label className="error"> {errors_file_pan_adhar_reason ? errors_file_pan_adhar_reason : ""} </label>
                                      </div>
                                      <div id="ispanadharResonOther" className="ar-other-reason">
                                        <div className="formGroup">
                                          <input defaultValue={file_pan_adhar_status === "Accepted" ? "" : 
    !rejectReason.includes(file_pan_adhar_comments) ? file_pan_adhar_comments : ''
  } onInvalid={e => setErrorsFilePanAdharComments('Please enter reason')} required disabled={inputPanAdhar} type="text" className="input-control" placeholder="Enter Reason" name="file_pan_adhar_comments" id="file_pan_adhar_comments" onChange={panAdharInputComment} />

                                          <label className="error"> {errors_file_pan_adhar_comments ? errors_file_pan_adhar_comments : ""} </label>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section-photo-signature">
                      <div className="cap-wrapper w-full">
                        <div className="form-caption">Photo &amp; Signature</div>
                        <div className="ps-wrapper">
                          <div className="cap-wrapper">
                            <div className="form-caption">Photo</div>
                            <div className="image-preview-data">
                              <div className="label">Preview Photo</div>
                              <div className="image-preview">
                                <img src={src} alt={alt} />
                                <div className="download-action">
                                  <a className="download-icon">
                                    <i className="fi fi-file-download" onClick={() => handleDownload(src)}></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="admin-ar-ps">
                              <div className="ar-toggler">
                                <label className="check-w">
                                  <input defaultChecked={(photo_status == "Accepted") ? true : false} type="checkbox" name="accept_photo" id="accept_photo" onChange={photoToggler} className="checkbox" />
                                  <div id="photo_toggler" className="checkbox-toggler">
                                    <div className="checked">Accept</div>
                                    <div className="unchecked">Reject</div>
                                  </div>
                                </label>

                              </div>
                              <div className="ar-sel-reason">
                                <div className="formGroup">
                                  <select defaultValue={(photo_status == "Rejected") ? (!rejectReason.includes(photo_comments)) ? "Others" : photo_comments : ""} onInvalid={e => setErrorsFilePhotoReason('Select rejected reason')} required onChange={photoInputToggler} disabled={selectPhoto} name="photo_reason" id="photo_reason" className="input-control" >
                                    <option value="">Select</option>
                                    <option value="Quality poor">Quality poor</option>
                                    <option value="Offensive content">Offensive content</option>
                                    <option value="Image not appropriate">Image not appropriate</option>
                                    <option value="Others">Others</option>
                                  </select>
                                  <label className="error"> {errors_photo_reason ? errors_photo_reason : ""} </label>
                                </div>
                              </div>
                              <div id="isPSResonOther" className="ar-other-reason">
                                <div className="formGroup">
                                  {/* <input defaultValue={(!rejectReason.includes(photo_comments)) ? photo_comments : ''} onInvalid={e => setErrorsFilePhotoComments('Please enter reason')} required disabled={inputPhoto} type="text" className="input-control" placeholder="Enter Reason" name="photo_comments" id="photo_comments" onChange={photoInputComment} /> */}
                                  <input  defaultValue={photo_status === "Accepted" ? "" : 
    !rejectReason.includes(photo_comments) ? photo_comments : ''
  } onInvalid={e => setErrorsFilePhotoComments('Please enter reason')} required disabled={inputPhoto} type="text" className="input-control" placeholder="Enter Reason" name="photo_comments" id="photo_comments" onChange={photoInputComment} />
                                  <label className="error"> {errors_photo_comments ? errors_photo_comments : ""} </label>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="cap-wrapper">
                            <div className="form-caption">Signature</div>
                            <div className="sign-preview-data">
                              <div className="label">Preview Signature</div>
                              <div className="image-preview">
                                <img src={src_s} alt={alt_s} />
                                <div className="download-action">
                                <a className="download-icon">
                                  <i className="fi fi-file-download" onClick={() => handleDownload(src_s)}></i>
                                </a>
                              </div>
                              </div>
                            </div>
                            <div className="admin-ar-ps">
                              <div className="ar-toggler">
                                <label className="check-w">
                                  <input defaultChecked={(signature_status == "Accepted") ? true : false} type="checkbox" name="accept_signature" id="accept_signature" onChange={signatureToggler} className="checkbox" />
                                  <div id="ps_toggler" className="checkbox-toggler">
                                    <div className="checked">Accept</div>
                                    <div className="unchecked">Reject</div>
                                  </div>
                                </label>

                              </div>
                              <div className="ar-sel-reason">
                                <div className="formGroup">
                                  <select defaultValue={(signature_status == "Rejected") ? (!rejectReason.includes(signature_comments)) ? "Others" : signature_comments : ""} onInvalid={e => setErrorsFileSignatureReason('Select rejected reason')} required onChange={signatureInputToggler} disabled={selectSign} name="signature_reason" id="signature_reason" className="input-control" data-attribute-name="signature_reason" >
                                    <option value="">Select</option>
                                    <option value="Quality poor">Quality poor</option>
                                    <option value="Offensive content">Offensive content</option>
                                    <option value="Image not appropriate">Image not appropriate</option>
                                    <option value="Others">Others</option>
                                  </select>
                                  <label className="error"> {errors_signature_reason ? errors_signature_reason : ""} </label>
                                </div>
                              </div>
                              <div id="isPSResonOther" className="ar-other-reason" >
                                <div className="formGroup">
                                  {/* <input defaultValue={(!rejectReason.includes(signature_comments)) ? signature_comments : ''} onInvalid={e => setErrorsFileSignatureComments('Please enter reason')} required disabled={inputSign} type="text" className="input-control" placeholder="Enter Reason" name="signature_comments" id="signature_comments" onChange={signatureInputComment} /> */}
                                  <input defaultValue={signature_status === "Accepted" ? "" : 
    !rejectReason.includes(signature_comments) ? signature_comments : ''
  } onInvalid={e => setErrorsFileSignatureComments('Please enter reason')} required disabled={inputSign} type="text" className="input-control" placeholder="Enter Reason" name="signature_comments" id="signature_comments" onChange={signatureInputComment} />

                                  <label className="error"> {errors_signature_comments ? errors_signature_comments : ""} </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    {/* <div className="form-action">
                        <button className="submit-btn btn-solid" type="submit">Save</button>
                      </div> */}
                    <div className="form-action">
                      {(btn_disable) ? (<ColorRing
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                      />) : (<button className="submit-btn btn-solid" type="submit">Save</button>)}
                    </div>
                  </form>

                </div>

              </div>
            </div>

          </div>
        </div >
        <Modal open={open} onClose={onCloseModal} center>
          <img style={{ width: "400px", height: "auto" }} src={modalFilename} alt="show image" />
        </Modal>
      </>
    )
  )
}
export default AdminReportersList;