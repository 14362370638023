import React,{useState,useEffect} from "react";
import '../css/file18.css';
import { RotatingTriangles } from 'react-loader-spinner'
import VendorService  from '../services/vendor.service';
import Sidebar from '../components/sidebarComponent';
import VendorStatusProgressComponent from '../components/vendorStatusProgressComponent'


function VendorContractCopyPage(){
    const [loading, setLoading] = useState(true);
    const [ vendor, setVendor ] = useState([]);
    const initialState = { alt: "", src: "",file_status:"", comments:""};
    const [{ alt, src, photo_status,photo_comments }, setPhotoPreview] = useState(initialState);
    const [{ alt_s, src_s,signature_status, signature_comments }, setSignaturePreview] = useState(initialState);
    const [{ file_pan_name,file_pan_path,file_pan_status, file_pan_comments}, setFilePan ] = useState(initialState);
    const [ { file_a_name,file_a_path,file_a_status, file_a_comments}, setFileAadhar ] = useState(initialState);
    const [ { file_cc_name,file_cc_path,file_cc_status,file_cc_comments}, setFileCC ] = useState(initialState);
    const [{ contract_name, contract_src, contract_status,contract_comments }, setContractCopyPreview] = useState(initialState);
    const [{ legal_contract_name, legal_contract_src, legal_contract_status,legal_contract_comments }, setLegalContractCopyPreview] = useState(initialState);
    const [error_contract_copy, setErrorContractCopy] = useState({});
    const [file_contract_copy, setContractCopy] = useState({});
    const [contract_preview, setContractPreview] = useState({});
    const [contract_exists, checkContract] = useState({});

   const setPreview= (vendor_documents) =>{ 
    if (vendor_documents) {
        vendor_documents.map(function(val, index){
            if(val.document_type==="photograph"){
                setPhotoPreview(
                    {
                    src:val.document_path,
                    alt:val.document_name,
                    photo_status:val.status,
                    photo_comments:(val.comments)? val.comments :"" 
                    }
            );
            }
            if(val.document_type==="signature"){
                setSignaturePreview(
                    {
                    src_s:val.document_path,
                    alt_s:val.document_name,
                    signature_status:val.status,
                    signature_comments:(val.comments)? val.comments :"" 
                    }
            );
            }
            if(val.document_type==="pan card"){
                setFilePan({
                    file_pan_path:val.document_path,
                    file_pan_name:val.document_name,
                    file_pan_status:val.status,
                    file_pan_comments:(val.comments)? val.comments :"" 
                    });
            }
            if(val.document_type==="aadhaar card"){
                setFileAadhar({
                    file_a_path:val.document_path,
                    file_a_name:val.document_name,
                    file_a_status:val.status,
                    file_a_comments:(val.comments)? val.comments :"" 
                    });
            }
            if(val.document_type==="cancelled cheque"){
                setFileCC({
                    file_cc_path:val.document_path,
                    file_cc_name:val.document_name,
                    file_cc_status:val.status,
                    file_cc_comments:(val.comments)? val.comments :"" 
                    });
            }
            if(val.document_type==="contract copy"){
                setContractCopyPreview({
                    contract_src:val.document_path,
                    contract_name:val.document_name,
                    contract_status:val.status,
                    contract_comments:(val.comments)? val.comments :"" 
                    });
                    checkContract(true);
            }
            if(val.document_type==="contract copy legal"){
                setLegalContractCopyPreview({
                    legal_contract_src:val.document_path,
                    legal_contract_name:val.document_name,
                    legal_contract_status:val.status,
                    legal_contract_comments:(val.comments)? val.comments :"" 
                    });
            }
        })
    }
   }

   const onSubmit = async (event) => {
        event.preventDefault();
        const isValid= await FormValidation(event);
        if (isValid) {
            const formData = new FormData();
            formData.append('file_contract_copy', file_contract_copy);
            async function setContractCopyVendor() {            
                if(contract_exists){
                    await VendorService.updateContractCopyVendor(formData,true);
                    let data = await VendorService.getCurrentVendor();
                    setVendor(data);
                } else {
                    await VendorService.uploadContractCopyVendor(formData,true);
                    let data = await VendorService.getCurrentVendor();
                    setVendor(data);
                }
            }
            setContractCopyVendor(); 
        }
    }

   const ImageValidation =(file)=>{
    var filePath = file.name;
    // Allowing file type
    var allowedExtensions =  /(\.jpg|\.jpeg|\.pdf)$/i;
    if (!allowedExtensions.exec(filePath)) {
        return {status:false,"msg":"Invalid file type"};
    }else if (file.size > 25000000) { // Size of document should be between 4KB and 5MB.
        return {status:false,"msg":"Size of contract copy should be less than 25MB."};
    } 
    return {status:true,"msg":""};
}
   const FormValidation =(e)=>{
        let isValid= true;
        if (typeof file_contract_copy.name == 'undefined') {
            setErrorContractCopy({status:false,"msg":"Please upload contract copy"});
            isValid=false;
        }
        return isValid;
    }

    useEffect(() => {
        async function getVendor() {
        let data = await VendorService.getCurrentVendor();
        setVendor(data);
        setPreview(data.vendor_documents);
        }
        getVendor();
        setLoading(false); 
    }, []);

    const handleChangeContractCopy = (event) => {
        const valid=ImageValidation(event.target.files[0]);
        if(valid.status){
            setContractCopy(event.target.files[0]);
            setContractPreview(true);
        }else{
            event.target.value = null;
        }
        setErrorContractCopy(valid);
    }
    
    const Capitalize =(str)=>{
        if (typeof str !== 'undefined')
            return str.charAt(0).toUpperCase() + str.slice(1);   
    }

    const deleteContractCopy = (event) => {
        event.target.value = null;
        document.querySelector(".upload_contract_name").innerText = '';
        setContractPreview(false);
    }

    return(
        <div className="page-wrapper">
        <Sidebar sidebarFor="Vendor" final_form_flag={vendor.final_flag} />
        <div className="content-wrapper">
            <div className="overlay" id="overlay"></div>
            <div className="page-content page-contributor-preview page-track-status">
                <div className="page-header">
                    <h4>Track Form Status</h4>
                </div>
                <div className="card">
                    <div className="card-body">
                        <VendorStatusProgressComponent/>
                        {(loading) ? (<RotatingTriangles
                                        height="100"
                                        width="100"
                                        color="#fff"
                                        ariaLabel="rotating-triangels-loading"
                                        wrapperStyle={{}}
                                        wrapperclassName="triangle-loading"
                                        visible={loading}
                                    />) : vendor.vendor_info && (   
                        <form className="contributor-form" onSubmit={onSubmit}>
                            {console.log(vendor)}
                            <section className="section-basic-details">
                                <div className="cap-wrapper">
                                    <div className="form-caption">Personal Details</div>
                                    <div className="preview-data">
                                        <div className="pre-label">Name</div>
                                        <div className="pre-value">{Capitalize(vendor.vendor_info.firstname)} {Capitalize(vendor.vendor_info.lastname)}</div>
                                    </div>
                                    <div className="preview-data">
                                        <div className="pre-label">Gender</div>
                                        <div className="pre-value">{Capitalize(vendor.vendor_info.gender)}</div>
                                    </div>
                                    <div className="preview-data">
                                        <div className="pre-label">DOB</div>
                                        <div className="pre-value">07-07-1983</div>
                                    </div>
                                    <div className="preview-data">
                                        <div className="pre-label">Nationality</div>
                                        <div className="pre-value">{Capitalize(vendor.vendor_info.nationality)}</div>
                                    </div>
                                    <div className="preview-data">
                                        <div className="pre-label">Marital Status</div>
                                        <div className="pre-value">{Capitalize(vendor.vendor_info.marital_status)}</div>
                                    </div>
                                </div>
                                <div className="cap-wrapper">
                                    <div className="form-caption">Family Details</div>
                                    <div className="preview-data">
                                        <div className="pre-label">Father's Name</div>
                                        <div className="pre-value">{Capitalize(vendor.vendor_info.f_firstname)} {Capitalize(vendor.vendor_info.f_lastname)}</div>
                                    </div>
                                    <div className="preview-data">
                                        <div className="pre-label">Mother's Name</div>
                                        <div className="pre-value">{Capitalize(vendor.vendor_info.m_firstname)} {Capitalize(vendor.vendor_info.m_lastname)}</div>
                                    </div>
                                </div>
                            </section>
                            <section className="section-address">
                                <div className="cap-wrapper">
                                    <div className="form-caption">Present Address</div>
                                    <div className="preview-data">
                                        <div className="pre-value">{vendor.vendor_address
.flat_no_present} <br /> {vendor.vendor_address
    .state_present},{vendor.vendor_address
        .street_present} <br /> {vendor.vendor_address
            .landmark_present}, {vendor.vendor_address
            .locality_present},{vendor.vendor_address
                .village_present}  <br /> {vendor.vendor_address
            .city_present} - {vendor.vendor_address
                .pincode_present},{vendor.vendor_address
                    .state_present} ,{vendor.vendor_address
                        .country_present} </div>
                                    </div>
                                </div>
                                <div className="cap-wrapper">
                                    <div className="form-caption">Permanent Address</div>
                                    <div className="preview-data">
                                        <div className="pre-value">{vendor.vendor_address
.flat_no_permanent} <br /> {vendor.vendor_address
    .state_permanent},{vendor.vendor_address
        .street_permanent} <br /> {vendor.vendor_address
            .landmark_permanent}, {vendor.vendor_address
            .locality_permanent},{vendor.vendor_address
                .village_permanent}  <br /> {vendor.vendor_address
            .city_permanent} - {vendor.vendor_address
                .pincode_permanent},{vendor.vendor_address
                    .state_permanent} ,{vendor.vendor_address
                        .country_permanent} </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section-doc-upload">
                                <div className="cap-wrapper w-full">
                                    <div className="form-caption">Documents Upload</div>
                                    <div className="tble-wrapper">
                                        <div className="thead">
                                            <div className="th">Proof Name</div>
                                            <div className="th">Document Name</div>
                                            <div className="th attach-file-div">Attach File</div>
                                            <div className="th">Status</div>
                                        </div>
                                        <div className="tbody-wrapper">
                                            <div className="tbody">
                                                <div className="td">
                                                    <div className="tab-label v-mob">Proof Name</div>
                                                    <div className="label text-upper">Scanned Pan Card <span className="asterisk">*</span></div>
                                                </div>
                                                <div className="td">
                                                    <div className="tab-label v-mob">Document Name</div>
                                                    <div className="label text-upper">Scanned Pan Card</div>
                                                </div>
                                                <div className="td">
                                                    <div className="tab-label v-mob">Status</div>
                                                    <div className="label">
                                                    {(file_pan_status==="Processing")? file_pan_status :""}
                                                    {(file_pan_status==="Accepted") && (<div className="status-label accepted">
                                                            <i className="fi fi-verified-black"></i> Accepted
                                                        </div>)}
                                                        {(file_pan_status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                        <div className="reason-text">Reason : {file_pan_comments}.</div></>)} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tbody">
                                                <div className="td">
                                                    <div className="tab-label v-mob">Proof Name</div>
                                                    <div className="label text-upper">Scanned Aadhaar Card <span className="asterisk">*</span></div>
                                                </div>
                                                <div className="td">
                                                    <div className="tab-label v-mob">Document Name</div>
                                                    <div className="label text-upper">Scanned Aadhaar Card</div>
                                                </div>                                               
                                                <div className="td">
                                                    <div className="tab-label v-mob">Status</div>
                                                    <div className="label">
                                                    {(file_a_status==="Processing")? file_a_status :""}
                                                    {(file_a_status==="Accepted") && (<div className="status-label accepted">
                                                            <i className="fi fi-verified-black"></i> Accepted
                                                        </div>)}
                                                        {(file_a_status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                        <div className="reason-text">Reason : {file_a_comments}.</div></>)} 
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tbody">
                                                <div className="td">
                                                    <div className="tab-label v-mob">Proof Name</div>
                                                    <div className="label text-upper">Cancelled Cheque <span className="asterisk">*</span></div>
                                                </div>
                                                <div className="td">
                                                    <div className="tab-label v-mob">Document Name</div>
                                                    <div className="label text-upper">Cancelled Cheque</div>
                                                </div>
                                                <div className="td">
                                                    <div className="tab-label v-mob">Status</div>
                                                    <div className="label">
                                                    {(file_cc_status==="Processing")? file_cc_status :""}
                                                    {(file_cc_status==="Accepted") && (<div className="status-label accepted">
                                                            <i className="fi fi-verified-black"></i> Accepted
                                                        </div>)}
                                                        {(file_cc_status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                        <div className="reason-text">Reason : {file_cc_comments}.</div></>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section className="section-photo-signature">
                                <div className="cap-wrapper w-full">
                                    <div className="form-caption">Photo &amp; Signature</div>
                                    <div className="ps-wrapper">
                                        <div className="cap-wrapper">
                                            <div className="form-caption">Photo</div>
                                            <div className="image-preview-data">
                                                <div className="label">Preview Photo</div>
                                                <div className="image-preview">
                                                <img src={src_s} alt={alt_s}/>
                                                </div>
                                            </div>                                                
                                        </div>
                                        <div className="cap-wrapper">
                                            <div className="form-caption">Signature</div>
                                            <div className="sign-preview-data">
                                                <div className="label">Preview Signature</div>
                                                <div className="image-preview">
                                                <img src={src} alt={alt}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ps-status">
                                            <div className="label">
                                            Signature Status:{(signature_status==="Processing")? signature_status :""}
                                                    {(signature_status==="Accepted") && (<div className="status-label accepted">
                                                            <i className="fi fi-verified-black"></i> Accepted
                                                        </div>)}
                                                        {(signature_status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                        <div className="reason-text">Reason : {signature_comments}.</div></>)} 
                                            <br/>
                                            Photo  Status: {(photo_status==="Processing")? photo_status :""}
                                                    {(photo_status==="Accepted") && (<div className="status-label accepted">
                                                            <i className="fi fi-verified-black"></i> Accepted
                                                        </div>)}
                                                        {(photo_status==="Rejected") && (<><div className="status-label rejected"><i className="fi fi-unpublished-black"></i> Rejected</div>
                                                        <div className="reason-text">Reason : {photo_comments}.</div></>)} 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div className="section-cc">
                                <h3 className="section-title">Accept Contract Copy</h3>
                                <div className="contract-wrapper">
                                    <div className="col-left">
                                        <div className="label">Download the Contract Copy</div>
                                        <div className="download-file">
                                            <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                            <div className="upload_file_name">{legal_contract_name}</div>
                                            <a href={legal_contract_src} download className="download-icon"><i className="fi fi-file-download"></i></a>
                                        </div>
                                    </div>
                                    <div className="col-right">
                                    {
                                        contract_name && (
                                            <div className="upload-contract-copy">
                                                <label htmlFor="">Signed contract copy pdf format. Maximum file size (25 MB)</label>
                                                <div className="download-file">
                                                    <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                                    <div className="upload_file_name">{contract_name}</div>
                                                    <a href={contract_src} download className="download-icon"><i className="fi fi-file-download"></i></a>
                                                </div>
                                            </div>
                                        )
                                    }
                                        <div className="upload-contract-copy">
                                            <label htmlFor="">Upload the Signed contract copy pdf format. Maximum file size (25 MB)</label>
                                            <div className="upload-cc-wrapper">
                                                <input type="file" id="upload_file_cc"  name="file_contract_copy"  onChange={handleChangeContractCopy}  accept=".pdf" className="file-input-hide"/>
                                                <label htmlFor="upload_file_cc" className="drop-files">
                                                    <div className="icon-holder">
                                                        <i className="fi fi-upload-documnet"></i>
                                                    </div>
                                                    <div className="text">Drop files <span className="text-blue">browse</span> through your machine.</div>
                                                </label>                                                
                                                {
                                                    contract_preview === true && (
                                                        <>
                                                            <div id="temp_upload_div" className="temp-upload-file" >
                                                                <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                                                <div id="upload_contract_name" className="upload_contract_name">{file_contract_copy.name}</div>
                                                                <div id="remove_file" className="remove-icon" onClick={deleteContractCopy}><i className="fi fi-close-black"></i></div>
                                                            </div>
                                                            <label className="error"> {error_contract_copy ? error_contract_copy.msg : ""} </label>
                                                        </>
                                                    )
                                                }                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {(vendor.status==="New" && (<div className="form-action">
                                <button className="submit-btn btn-solid" type="submit">Submit</button>
                            </div>))}
                        </form>
                         )}
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default VendorContractCopyPage;
