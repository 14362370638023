import React,{useState,useEffect} from "react";
import AuthService  from '../services/auth.service';
import { useNavigate,Link,useLocation,NavLink } from "react-router-dom";

function Sidebar(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const onVendorLogOut = async (event) => {
          async function logOut() {
            await AuthService.logoutVendor();
            navigate(process.env.PUBLIC_URL+"/vendor-login");
           }
           logOut(); 
      }
    const [ismenuOpen, setIsMenuOpen] = useState(false);
    const managementLogOut = async (event) => {
    async function logOut() {
        await AuthService.logout();
        navigate(`${process.env.PUBLIC_URL}/management-login`);
        }
        logOut(); 
    }
    
    const superAdminLogOut = async (event) => {
        async function logOut() {
            await AuthService.logout();
            navigate(process.env.PUBLIC_URL+"/super-admin-login");
            }
            logOut(); 
        }

        const legalLogOut = async (event) => {
            async function logOut() {
                await AuthService.logout();
                navigate(process.env.PUBLIC_URL+"/legal-login");
                }
                logOut(); 
            }

        const accountLogOut = async (event) => {
            async function logOut() {
                await AuthService.logout();
                navigate(process.env.PUBLIC_URL+"/account-login");
            }
            logOut();
        }

        const adminLogOut = async (event) => {
            async function logOut() {
                await AuthService.logout();
                navigate(process.env.PUBLIC_URL + "/admin-login");
            }
            logOut();
        }

        
        const handleTabClick = (tabValue) => {
            props.setActiveTab(tabValue);
            props.setToggleState(tabValue);
            localStorage.setItem('activeTab', tabValue);
          }

          const ToggleSidebar = () => {
            ismenuOpen === true ? setIsMenuOpen(false) : setIsMenuOpen(true);
        }

    return (
        <>
            <header className="file-header">
                <img src={`${process.env.PUBLIC_URL}/img/network18_creative.png`} className="nw-logo" alt=" " />
                <div className={ ismenuOpen == true ?  'hamburger-icon change'   :  'hamburger-icon' }  onClick={ToggleSidebar}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </header>
        <div className={ ismenuOpen == true ?  'sidebar-wrapper sidebar-width'   :  'sidebar-wrapper' } id="sidebar-wrapper">
            <div className="logo-segment">
                <a href="">
                    <img src={`${process.env.PUBLIC_URL}/img/network18_creative.png`} alt="" />
                </a>
            </div>
            <div className="sidebar-menu">
                {props.sidebarFor=="Vendor" && (
                    <ul>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/vendor-dashboard` ? "menu-active" :""} ><Link to={`${process.env.PUBLIC_URL}/vendor-dashboard`}><i className="fi fi-form"></i> Contributor Opening Form</Link></li>
                        {props.final_form_flag && (
                            <li className={location.pathname==`${process.env.PUBLIC_URL}/vendor-tracking` ? "menu-active" :""}><Link to={`${process.env.PUBLIC_URL}/vendor-tracking`}><i className="fi fi-settings-black"></i> Vendor Tracking </Link></li>
                        )}
                        <li><a onClick={onVendorLogOut}><i className="fi fi-person-black"></i> Logout</a></li>
                     </ul>
                )}
                {props.sidebarFor=="Management" && (
                     <ul>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/management-all-listing` ? "menu-active" :""}>
                            <Link to={`${process.env.PUBLIC_URL}/management-all-listing`}><i className="fi fi-form"></i>All</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/management-new-listing` ? "menu-active" :""}>
                            <Link to={`${process.env.PUBLIC_URL}/management-new-listing`}><i className="fi fi-form"></i>New</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/management-submitted-listing` ? "menu-active" :""}>
                            <Link to={`${process.env.PUBLIC_URL}/management-submitted-listing`}><i className="fi fi-form"></i>Submitted</Link>
                        </li>                        
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/management-contract-listing` ? "menu-active" :""}>
                            <Link to={`${process.env.PUBLIC_URL}/management-contract-listing`}><i className="fi fi-form"></i>Contract Copy</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/management-approved-listing` ? "menu-active" :""}>
                            <Link to={`${process.env.PUBLIC_URL}/management-approved-listing`}><i className="fi fi-form"></i>Approved</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/management-completed-listing` ? "menu-active" :""}>
                            <Link to={`${process.env.PUBLIC_URL}/management-completed-listing`}><i className="fi fi-form"></i>Completed</Link>
                        </li>
                        <li>
                            <a onClick={managementLogOut}><i className="fi fi-person-black"></i> Logout</a>
                        </li>
                    </ul>
                )}
                {props.sidebarFor=="SuperAdmin" && (
                     <ul>
                     <li className={props.activeTab === 1 ? 'menu-active' : ""}><a onClick={() => handleTabClick(1)}><i className="fi fi-person-black"></i> Users- All</a></li>
                     <li className={props.activeTab === 2 ? 'menu-active' : ""}><a onClick={() => handleTabClick(2)}><i className="fi fi-person-black"></i> Admin</a></li>
                     <li className={props.activeTab === 3 ? 'menu-active' : ""}><a onClick={() => handleTabClick(3)}><i className="fi fi-person-black"></i> Account</a></li>
                     <li className={props.activeTab === 4 ? 'menu-active' : ""}><a onClick={() => handleTabClick(4)}><i className="fi fi-person-black"></i> Finance</a></li>
                     <li className={props.activeTab === 5 ? 'menu-active' : ""}><a onClick={() => handleTabClick(5)}><i className="fi fi-person-black"></i> Legal</a></li>
                     <li className={props.activeTab === 6 ? 'menu-active' : ""}><a onClick={() => handleTabClick(6)}><i className="fi fi-person-black"></i> Management</a></li>
                     <li className={props.activeTab === 7 ? 'menu-active' : ""}><a onClick={() => handleTabClick(7)}><i className="fi fi-person-black"></i> Super Admin</a></li>
                     {/* <li><a href=""><i className="fi fi-settings-black"></i> Settings</a></li> */}
                     <li><a onClick={superAdminLogOut}><i className="fi fi-person-black"></i> Logout</a></li>
                 </ul>
                )}
                 {props.sidebarFor=="Legal" && (
                     <ul>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/legal-all-listing` ? "menu-active" :""}>
                            <Link to={process.env.PUBLIC_URL+`/legal-all-listing`}><i className="fi fi-form"></i>All</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/legal-new-listing` ? "menu-active" :""}>
                            <Link to={process.env.PUBLIC_URL+`/legal-new-listing`}><i className="fi fi-form"></i>New</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/legal-listing` ? "menu-active" :""}>
                            <Link to={process.env.PUBLIC_URL+`/legal-listing`}><i className="fi fi-form"></i>Legal</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/legal-contract-copy-listing` ? "menu-active" :""}>
                            <Link to={process.env.PUBLIC_URL+`/legal-contract-copy-listing`}><i className="fi fi-form"></i>Contract Copy</Link>
                        </li>
                        <li className={location.pathname==`${process.env.PUBLIC_URL}/legal-complete-listing` ? "menu-active" :""}>
                            <Link to={process.env.PUBLIC_URL+`/legal-complete-listing`}><i className="fi fi-form"></i>Complete</Link>
                        </li>
                        <li>
                            <a onClick={legalLogOut}><i className="fi fi-person-black"></i> Logout</a>
                        </li>
                    </ul>
                )}
                {props.sidebarFor == "Account" && (
                        <ul>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/account-all-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL+`/account-all-listing`}><i className="fi fi-form"></i>All</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/account-new-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL+`/account-new-listing`}><i className="fi fi-form"></i>New</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/account-submitted-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL+`/account-submitted-listing`}><i className="fi fi-form"></i>Submitted</Link>
                            </li>
                            <li className={location.pathname==`${process.env.PUBLIC_URL}/account-complete-listing` ? "menu-active" :""}>
                            <Link to={process.env.PUBLIC_URL+`/account-complete-listing`}><i className="fi fi-form"></i>Complete</Link>
                        </li>
                            <li>
                                <a onClick={accountLogOut}><i className="fi fi-person-black"></i> Logout</a>
                            </li>
                        </ul>
                    )}
                    {props.sidebarFor == "Admin" && (
                        <ul>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/admin-all-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/admin-all-listing`}><i className="fi fi-form"></i>All</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/admin-new-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/admin-new-listing`}><i className="fi fi-form"></i>New</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/admin-approved-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/admin-approved-listing`}><i className="fi fi-form"></i>Approved</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/admin-rejected-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/admin-rejected-listing`}><i className="fi fi-form"></i>Rejected</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/admin-complete-listing` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/admin-complete-listing`}><i className="fi fi-form"></i>Complete</Link>
                            </li>
                            <li className={location.pathname == `${process.env.PUBLIC_URL}/vendor-signup` ? "menu-active" : ""}>
                                <Link to={process.env.PUBLIC_URL + `/vendor-signup`}><i className="fi fi-form"></i>Create Vendor</Link>
                            </li>
                            <li>
                                <a onClick={adminLogOut}><i className="fi fi-person-black"></i> Logout</a>
                            </li>
                        </ul>
                    )}
            </div>
        </div>
        <div className={ ismenuOpen == true ?  'show-overlay overlay'   :  'overlay' } id="overlay"></div>
        </>

    )
}
export default Sidebar;