import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import { useNavigate } from "react-router-dom";
import LegalList from '../../components/legal/legalList';
import LegalService from '../../services/legal-service';
import Sidebar from '../../components/sidebarComponent';
import AuthService  from '../../services/auth.service';

function LegalDashboard(){
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [totalPages, setTotalPages] = useState();
    const [totalResults, setTotalResults] = useState();

    const [toggleState, setToggleState] = useState(1);
    const [legalData, setLegalData] = useState([]);

    const [searchQuery, setSearchQuery] = useState("");
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    const toggleTab = (index, pageSize) => {
        setToggleState(index);
        setLimit(pageSize);
        setSearchQuery("");
        setCurrentPage(1);
    };
    
    const fetchData = async () => {

        switch (toggleState) {
            case 1:
                return await LegalService.getAllUsers(currentPage, limit, searchQuery);
            case 2:
                return await LegalService.getNewUsers(currentPage, limit, searchQuery);
            case 3:
                return await LegalService.getSubmittedUsers(currentPage, limit, searchQuery);
            case 4:
                return await LegalService.getDraftedUsers(currentPage, limit, searchQuery);
            default: 
                return [];
        }
    }
    
    useEffect(() => {
        if(!AuthService.isLoggedInUser()){
            navigate(process.env.PUBLIC_URL+"/legal-login");
        }else{
            const fetchDataAndSetState = async () => {
                const result = await fetchData();
                setLegalData(result || []);
                setTotalPages(result?.totalPages);
                setTotalResults(result?.totalResults);
            }
            fetchDataAndSetState();
        }
    }, [toggleState, currentPage, limit, searchQuery]);

    return(        
        <div className="page-wrapper">
        <Sidebar sidebarFor="Legal"/>
        <div className="content-wrapper">
            <div className="page-content page-reporters">
                <div className="page-header">
                    <h4>New Reporters</h4>
                </div>
                <div className="card">
                    <div className="card-head">
                        <div className="tab-list">
                            <button className={toggleState === 1 ? 'tablink tab-active' : "tablink"} onClick={() => toggleTab(1, 10)}>All</button>
                            <button className={toggleState === 2 ? 'tablink tab-active' : "tablink"} onClick={() => toggleTab(2, 10)}>New</button>
                            <button className={toggleState === 3 ? 'tablink tab-active' : "tablink"} onClick={() => toggleTab(3, 10)}>Legal</button>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="tab-wrapper">
                            <div id="reporters_all"  className={toggleState === 1 ? 'tab-content active-content' : "tab-content hidden"}>
                                <LegalList legalData={legalData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                            <div id="reporters_new" className={toggleState === 2 ? 'tab-content active-content' : "tab-content hidden"}>
                                <LegalList legalData={legalData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                            <div id="reporters_submitted" className={toggleState === 3 ? 'tab-content active-content' : "tab-content hidden"}>
                                <LegalList legalData={legalData}
                                currentPage={currentPage}
                                limit={limit}
                                totalPages={totalPages}
                                totalResults={totalResults}
                                handlePagination={handlePagination}
                                handleSearch={handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}
export default LegalDashboard;
