import {postAxios,postAdminAxios,postSuperAdminAxios,toastMsg} from '../../utils/EventApiHelper';

class AuthService {

  adminLogin(payload) {
    return postAdminAxios("userevent/admin-signin", payload)
      .then(response => {
        if (response !== 'undefined') {
          console.log('response.data',response);
          localStorage.setItem("user", JSON.stringify(response));
          if(response){
            toastMsg(response.message,'success');
          }
        }
        return response;
      });
  }

  superAdminLogin(payload) {
    return postSuperAdminAxios("sadmin-event/super-admin-signin", payload)
      .then(response => {
        if (response !== 'undefined') {
          console.log('response.data',response);
          localStorage.setItem("super-admin", JSON.stringify(response));
          if(response){
            toastMsg(response.message,'success');
          }
        }
        return response;
      });
  }

  logout() {
    localStorage.removeItem("user");
    toastMsg("Logout successfully",'success');
  }


  isLoggedInUser() {
    let data= JSON.parse(localStorage.getItem('user'));
    if(data){
      return true;
    }
    return false;
  }
  
  isLoggedInAdmin() {
    let data= JSON.parse(localStorage.getItem('super-admin'));
    if(data){
      return true;
    }
    return false;
  }

  slogout() {
    localStorage.removeItem("super-admin");
    toastMsg("Logout successfully",'success');
  }
}

export default new AuthService();