import React from "react";
import '../../css/file18.css';
import '../../css/homePage.css';
import Sidebar from '../../components/sidebarComponent';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useFormInputValidation } from "react-form-input-validation";
import AccountService from '../../services/account.service';
import { RotatingTriangles } from 'react-loader-spinner'
import AuthService from '../../services/auth.service';
import { useNavigate } from "react-router-dom";
import { ColorRing } from 'react-loader-spinner'
import Previewdoc from '../../components/Previewdoc';


function ReportersList() {
  const navigate = useNavigate();
  const params = useParams();
  const [btn_disable, setBtnDisable] = useState(false);
  const MINUTE_MS = 2000;//10 sec 
  const [reporters, setReporters] = useState([]);
  const [loading, setLoading] = useState(true);
  let photograph = '';
  let pan_card = '';

  const [fields, errors, form] = useFormInputValidation({
    vendor_code: ""
  }, {
    vendor_code: "required|regex:/^[a-zA-Z0-9-]+$/",

  });

  const viewPDF = (data, type) => {
    const win = window.open("", "_blank", "titlebar=yes,width = 600, height = 600");
    if (win) {
      const document = `<!DOCTYPE html>
      <html>
      <head>
          <title>PDF in HTML</title>
      </head>
      <body>
      <iframe src="https://docs.google.com/gview?url=${data}&embedded=true" 
      style="width:600px; height:500px;" frameborder="0"></iframe>

          
      </body>
      </html>
      `;
      win.document.write(document);
    }
  }



  const fetchData = async () => {
    const id = params.id;
    const result = await AccountService.getVendorDetails(id);
    setReporters(result || []);
    console.log(result);
    fields.vendor_code = result?.[0]?.vendor_info?.vendor_code || "";
    setLoading(false);
  }

  {
    reporters[0] && reporters[0].vendor_documents && reporters[0].vendor_documents.map((documents, index) => {
      if (documents.document_type === 'photograph') {
        photograph = documents.document_path
      }
      if (documents.document_type === 'pan card') {
        const extension = documents.document_path.split('.').pop()
        const filename = documents.document_path.substring(documents.document_path.lastIndexOf('/') + 1);
        if (extension === 'pdf') {
          pan_card = (
            <div>
              <div>
                <div className="file-name" onClick={() => viewPDF(documents.document_path, 'application/pdf')}>{filename}</div>

              </div>
            </div>
          );
        } else {
          pan_card = <img src={documents.document_path} alt="" style={{ height: "94px", width: "132px" }} />;
        }
      }
    })
  }





  useEffect(() => {
    if (!AuthService.isLoggedInUser()) {
      navigate(process.env.PUBLIC_URL + "/account-login");
    } else {
      fetchData();

    }

  }, []);


  const handleSubmit = async (event) => {
    let id = params.id;
    const isValid = await form.validate(event);
    if (isValid) {
      setBtnDisable(true);

      let payload = { "vendor_code": fields.vendor_code, "status": "Accounts" }
      async function updateDetails() {
       let data =  await AccountService.setVendorCode(id, payload);
          if (!data) {
            return false;
          }
          else{
            await new Promise(resolve => setTimeout(resolve, MINUTE_MS));
            navigate(`${process.env.PUBLIC_URL}/account-submitted-listing`);
          }
      }
      // setTimeout(() => {
      //   setBtnDisable(false);
      // }, MINUTE_MS);
      // updateDetails();
      await updateDetails();
    }

  }

  return (
    (loading) ? (<RotatingTriangles
      height="100"
      width="100"
      color="#fff"
      ariaLabel="rotating-triangels-loading"
      wrapperStyle={{}}
      wrapperClass="triangle-loading"
      visible={loading}
    />) : (
      <form onSubmit={handleSubmit}>
        <div className="page-wrapper">
          <Sidebar sidebarFor="Account" />
          

          <div className="content-wrapper">
            <div className="page-content page-reporter-details">
              <div className="page-header">
                <h4 className="section-title">Add Vendor Code</h4>
                <div className="breadcrumb-w">
                  <ol className="ms-breadcrumb">
                    <li><a href={process.env.PUBLIC_URL + `/account-all-listing`} >Form- All</a></li>
                    <li className="active"> {reporters.length > 0 ? (
                      <>{reporters[0]?.vendor_info?.firstname || ""} {reporters[0]?.vendor_info?.lastname || ""}</>
                    ) : (
                      ""
                    )}</li>
                  </ol>
                </div>


              </div>
              <div className="card">
                <div className="card-head">
                </div>
                <div className="card-body">
                  <div className="tab-wrapper">
                    <div id="all" className="tab-content">
                      <div className="table-responsive">
                        <table className="table rd-table">
                          <thead>
                            <tr>
                              <th width="12%">Name</th>
                              <th width="12%">PAN</th>
                              <th width="12%">Phone</th>
                              <th width="12%">District</th>
                              <th width="25%" className="address">Address</th>
                              <th width="20%">Vendor Code</th>
                            </tr>
                          </thead>
                          <tbody>

                            {reporters.length > 0 ? (
                              <tr key={reporters.id}>
                                <td data-label="Name">
                                  <div className="reporter-tt-w">
                                    <div className="reporter-thumb">
                                      <img src={photograph} alt="" style={{ height: "95px", width: "95px" }} />
                                      {/* <img src="https://via.placeholder.com/150" alt="placeholder" style={{ height: "50px", width: "50px" }} /> */}
                                    </div>
                                    <div className="reporter-name">{reporters[0]?.vendor_info?.firstname || ""} {reporters[0]?.vendor_info?.lastname || ""}</div>
                                  </div>
                                </td>
                                <td data-label="Pan">
                                  <div className="pc-thumb">
                                    {pan_card}
                                  </div>
                                </td>
                                <td data-label="District">
                                  <div className="val">{reporters[0]?.phoneno || ''} </div>
                                </td>
                                <td data-label="District">
                                  <div className="val">
                                    {reporters[0]?.vendor_address?.city_permanent || ""}</div>
                                </td>
                                <td data-label="Address" className="address">
                                  <div className="val">
                                    {reporters[0]?.vendor_address?.flat_no_permanent || ''}, {reporters[0]?.vendor_address?.street_permanent || ''}<br />
                                    {reporters[0]?.vendor_address?.landmark_permanent || ''}, {reporters[0]?.vendor_address?.locality_permanent || ''}, {reporters[0]?.vendor_address?.village_permanent || ''}<br />
                                    {reporters[0]?.vendor_address?.city_permanent || ''}, {reporters[0]?.vendor_address?.pincode_permanent || ''}, {reporters[0]?.vendor_address?.state_permanent || ''}, {reporters[0]?.vendor_address?.country_permanent || ''}
                                  </div>
                                </td>
                                <td className="action">
                                  <div className="">
                                    <input
                                      type="text"
                                      placeholder="Enter the Vendor Code"
                                      name="vendor_code"
                                      id="vendor_code"
                                      onBlur={form.handleBlurEvent}
                                      onChange={form.handleChangeEvent}
                                      value={fields.vendor_code}
                                      className="input-control"
                                    />

                                    <label className="error"> {errors.vendor_code ? errors.vendor_code : ""} </label>
                                  </div>
                                </td>

                              </tr>
                            ) : (
                              <tr>
                                <td colSpan="6"><b>No data found.</b></td>
                              </tr>
                            )
                            }



                          </tbody>
                        </table>

                      </div>

                      <div className="fg form-action" style={{ textAlign: "center" }}>
                        {(btn_disable) ? (<ColorRing
                          visible={true}
                          height="80"
                          width="80"
                          ariaLabel="blocks-loading"
                          wrapperStyle={{}}
                          wrapperClass="blocks-wrapper"
                          colors={['#2A426E', '#2A426E', '#2A426E', '#2A426E', '#2A426E']}
                        />) : (<input type="submit" value="Submit" id="vendor_submit" className="submit-btn" />)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginTop: '30px' }}>
                <div className="card-head">
                </div>
                <div className="card-body" >
                {params.id && <Previewdoc id={params.id} />} {/* Render Previewdoc only if id is available */}
                  
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>
    )
  )
}
export default ReportersList;
