import React from 'react';

function Pagination(props) {

    const { currentPage, limit, totalPages, totalResults, handlePagination } = props;

    const startIndex = (currentPage - 1) * limit + 1;
    const endIndex = Math.min(startIndex + limit - 1, totalResults);

    const handlePageChange = (newPage, limit) => {
        if (newPage >= 1 && newPage <= totalPages && handlePagination) {
            handlePagination(newPage);
        }
    };

    return (
        <div className="pagination-strip">
            <span>Rows per page</span>
            <input type="text" value={limit} onChange={(e) => handlePageChange(1, e.target.value)} />
            <span>{startIndex}-{endIndex} of {totalResults}</span>
            <div className="pg-buttons">
                <button className={`backto ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage - 5)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_107_7)"><rect width="20" height="20" transform="translate(20 20) rotate(-180)" fill="white"></rect><path d="M15 4L9 10L15 16" stroke="#272d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><rect x="5.5" y="16.5" width="1" height="13" rx="0.5" transform="rotate(-180 5.5 16.5)" stroke="#272d3b"></rect></g><defs><clipPath id="clip0_107_7"><rect width="20" height="20" fill="white" transform="translate(20 20) rotate(-180)"></rect></clipPath></defs></svg>
                </button>
                <button className={`back ${currentPage === 1 ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage - 1)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_109_10)"><rect width="20" height="20" transform="translate(20 20) rotate(180)" fill="white"></rect><path d="M13 4L7 10L13 16" stroke="#272d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></g><defs><clipPath id="clip0_109_10"><rect width="20" height="20" fill="white" transform="translate(20 20) rotate(180)"></rect></clipPath></defs></svg>
                </button>
                <button className={`next ${currentPage === totalPages ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage + 1)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_109_2)"><rect width="20" height="20" fill="white"></rect><path d="M7 16L13 10L7 4" stroke="#272d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></g><defs><clipPath id="clip0_109_2"><rect width="20" height="20" fill="white"></rect></clipPath></defs></svg>
                </button>
                <button className={`nextto ${currentPage === totalPages ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage + 5)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0_107_3)"><rect width="20" height="20" fill="white"></rect><path d="M5 16L11 10L5 4" stroke="#272d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path><rect x="14.5" y="3.5" width="1" height="13" rx="0.5" stroke="#272d3b"></rect></g><defs><clipPath id="clip0_107_3"><rect width="20" height="20" fill="white"></rect></clipPath></defs></svg>
                </button>
            </div>
        </div>
    );
}

export default Pagination;
