import React from "react";
import '../css/homePage.css';
import { Link } from "react-router-dom";

function HomePage(){

    return(
        <div className="splash-screen-wrapper">
        <div className="center-logo">
            <div className="logo-wrapper">
                <img src={`${process.env.PUBLIC_URL}/img/logo_network18.png`} alt="logo"/>
                <div className="image-text">
                    <span className="wc-text">Welcome To</span>
                    <span className="title">Digital First</span>
                </div>
            </div>
            <div className="action">                
                {/* <li className="btn-primary"><Link to={`${process.env.PUBLIC_URL}/sign-up`}>Sign Up</Link></li> */}
                <li className="btn-primary"><Link to={`${process.env.PUBLIC_URL}/vendor-login`}>Login</Link></li>
            </div>
            {/* <div className="action">                
                <li className="btn-primary"><Link to={`${process.env.PUBLIC_URL}/super-admin-login`}>SupAdmin Login</Link></li>
                <li className="btn-primary"><Link to={`${process.env.PUBLIC_URL}/management-login`}>Mgmt Login</Link></li>
            </div>
            <div className="action">                
                <li className="btn-primary"><Link to={`${process.env.PUBLIC_URL}/legal-login`}>Legal Login</Link></li>
                <li className="btn-primary"><Link to={`${process.env.PUBLIC_URL}/account-login`}>Account Login</Link></li>
            </div>
            <div className="action">                
                <li className="btn-primary"><Link to={`${process.env.PUBLIC_URL}/admin-login`}>Admin Login</Link></li>
            </div> */}
        </div>
    </div>
    )
}
export default HomePage;
