import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import { useNavigate, useParams } from "react-router-dom";
import { RotatingTriangles } from 'react-loader-spinner';
import ManagementService from '../../services/managment-service';
import { ColorRing } from 'react-loader-spinner';
import { format } from 'date-fns';

function ManagementApproveCopy(){
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [managementData, setManagementData] = useState([]);
    const [status, setStatus] = useState(true);
    const [otherReasonStatus, showOtherReason] = useState(false);
    const [rejectComment, setRejectComment] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const [moderation, setModeration] = useState('accepted');
    const [rejectError, setRejectError] = useState();
    const [rejectOtherError, setOtherRejectError] = useState();
    const [submitLabel, setSubmitLabel] = useState('Assign Url');
    const [btn_disable, setBtnDisable] = useState(false);
    const MINUTE_MS = 10000;//10 sec;

    let photograph = '';
    let contract_name = '';
    let contract_path = '';
    let finaldate = '';
    let comment = '';
    
    const handleStatus = (event) => {
        setStatus(event.target.checked);

        if(event.target.checked){
            setModeration('accepted')
            setOtherReason('');
            setRejectComment('');
            showOtherReason(false);
            setSubmitLabel('Assign Url');
        } else {
            setModeration('rejected');
            setSubmitLabel('Send To Vendor');
        }
    }

    const handleComment = (event) => {
        const selected_value = event.target.value;
        if(selected_value === 'other'){
            showOtherReason(true);
        } else {
            showOtherReason(false);
        }
        setRejectComment(selected_value);
    }

    const handleOtherReason = (event) => {
        const other_reason = event.target.value;
        setOtherReason(other_reason);
    }

    //Extract only photograph from the documents data
    {managementData && managementData.vendor_documents && managementData.vendor_documents.map((documents, index) => {
        if(documents.document_type === 'photograph') {
            photograph = documents.document_path
        }

        if(documents.document_type === 'contract copy'){
            contract_name = documents.document_name
            contract_path = documents.document_path
        }
    })}

    if(managementData?.vendor_info?.joining_date){
        let joindate = new Date(managementData?.vendor_info?.joining_date);
        finaldate = joindate.toISOString().substring(0, 10);
    }   

    const fetchData = async () => {
        const result = await ManagementService.getUserInfo(id);
        console.log(result);
        setManagementData(result || []);
        setLoading(false);

        if(result){
            let docs = result?.vendor_documents;
            {docs.map((documents, index) => {
                if(documents.document_type === 'contract copy'){
                    if(documents.status === 'accepted'){
                        console.log('ACCEPT')
                        setStatus(true);
                        setSubmitLabel('Assign url');
                    } else {
                        console.log('REJECT')
                        setStatus(false);
                        setModeration('rejected');
                        setSubmitLabel('Send To Vendor');
                        if(documents.comments === 'Contract copy is not signed. Kindly re-upload the signed copy' || documents.comments === 'Uploaded copy is not clear.'){
                            setRejectComment(documents.comments);
                            showOtherReason(false);
                        } else {
                            showOtherReason(true);
                            setRejectComment('other');
                            setOtherReason(documents.comments);
                        }
                    }
                }
            })}
        }
    }
    
    useEffect(() => {
        fetchData();
    }, []);

    //Submission details

    const onSubmit = async (event) => {
        event.preventDefault();
        const isValid = validateForm(event);

        if(isValid){
            setBtnDisable(true);
            if(otherReasonStatus === true) {
                comment = otherReason;
            } else {
                comment = rejectComment;
            }
    
            let payload = {
                'status': moderation,
                'comments': comment,
            }
    
            let response = await ManagementService.updateContractCopyDetails(id, payload);
            if(response.data = 1){
                navigate(`${process.env.PUBLIC_URL}/management-contract-listing`);
            } else {
                return false;
            }
            setTimeout(() => {
                setBtnDisable(false);
            }, MINUTE_MS);
        }        
    };

    const validateForm = (e) => {
        let isValid = true;

        if(status === false && rejectComment === ''){
            setRejectError({s: false, msg: 'Please select a reason'});
            isValid = false;
        }

        if(status === false && rejectComment === 'other' && otherReason === ''){
            setOtherRejectError({s: false, msg: 'Other reason field cannot be empty'});
            isValid = false;
        }
        return isValid;
    }

    return(  (loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
      />) : managementData && (    
            <form action="" onSubmit={onSubmit}>
                <div className="rd-section md-section mbtm">
                    <h3 className="section-title">Management Details</h3>
                    <div className="table-responsive">
                            <table className="table rd-table">
                                <thead>
                                    <tr>
                                        <th>Joining Date</th>
                                        <th>PAN</th>
                                        <th>Contract Duration</th>
                                        <th>Designation</th>
                                        <th>Salary</th>
                                        <th>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td data-label="Joining Date">
                                            <div className="val">
                                                {format(new Date(managementData?.vendor_info?.joining_date), 'dd-MM-yyyy')}
                                            </div>
                                        </td>
                                        <td data-label="Contract Duration">
                                            <div className="val">
                                                {managementData?.vendor_info?.pan_no}
                                            </div>
                                        </td>
                                        <td data-label="Contract Duration">
                                            <div className="val">
                                                {managementData?.vendor_info?.contract_duration}
                                            </div>
                                        </td>
                                        <td data-label="Designation">
                                            <div className="val manage-desig-val">
                                                {managementData?.vendor_info?.designation}
                                            </div>
                                        </td>
                                        <td data-label="Salary">
                                            <div className="val">
                                                {managementData?.vendor_info?.salary}
                                            </div>
                                        </td>
                                        <td data-label="Comments" className="text-left">
                                            <div className="val">
                                                {managementData?.vendor_info?.comments}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        
                    </div>
                </div>
                <div className="rd-section upload-section ar-uploaded-section">
                    <h3 className="section-title">Signed Contract Copy</h3>
                    <div className="upload-contract-copy">
                        <label htmlFor="">Approve Signed contract copy and assign File 18 URL to vendor</label>
                        <div className="upload-cc-wrapper">
                            <div className="temp-upload-file">
                                <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                <div className="upload_file_name">{contract_name}</div>
                                <a href={contract_path} target="_blank" className="download-icon"><i className="fi fi-file-download"></i></a>
                            </div>
                            {console.log(managementData.status)}
                            {((managementData.status === "Approved" || managementData.status === "Signed Contract Copy") && (
                                <><div className="ar-toggle-button">
                                    <label htmlFor="isAccept" className="check-w">
                                        <input type="checkbox" id="isAccept" className="checkbox" name="status" defaultChecked={status} value={status} onChange={handleStatus} />
                                        <div className="checkbox-toggler">
                                            <div className="checked">Accept</div>
                                            <div className="unchecked">Reject</div>
                                        </div>
                                    </label>
                                </div><div className="reason-sel">
                                        <div className="formGroup">
                                            <select name="comments" id="comments" value={rejectComment} className="input-control" disabled={status} onChange={handleComment}>
                                                <option value="">Select</option>
                                                <option value="Contract copy is not signed. Kindly re-upload the signed copy">Contract copy is not signed. Kindly re-upload the signed copy</option>
                                                <option value="Uploaded copy is not clear.">Uploaded copy is not clear.</option>
                                                <option value="other">Other</option>
                                            </select>
                                        </div>
                                        <label className="error"> {rejectError ? rejectError.msg : ""} </label>
                                    </div></>
                                ))}
                                {
                                    otherReasonStatus === true && (managementData.status === "Approved" || managementData.status === "Signed Contract Copy") && (
                                        <div id="if_reason_other" className="other-reason">
                                            <div className="formGroup">
                                                <input type="text" name="other_reason" id="other_reason" value={otherReason} className="input-control" onChange={handleOtherReason}/>
                                                <label className="error"> {rejectOtherError ? rejectOtherError.msg : ""} </label>
                                            </div>
                                        </div>
                                    )
                                }                            
                        </div>
                    </div>
                </div>
                {((managementData.status === "Approved" || managementData.status === "Signed Contract Copy") && (
                    <div className="form-action">
                        {(btn_disable) ? ( <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#2A426E','#2A426E','#2A426E','#2A426E','#2A426E']}
                        />) : (<button type="submit" className="submit-btn btn-solid">{submitLabel}</button>)}
                    </div>
                ))}
            </form>
                   
        )
    )
}
export default ManagementApproveCopy;