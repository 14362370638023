import { Link, useLocation } from "react-router-dom";

function AdminTabs() {
    const location = useLocation();
    return (
        <div className="card-head">
            <div className="tab-list">
                <Link to={process.env.PUBLIC_URL+`/admin-all-listing`} className="tabs">
                    <button className={location.pathname=="/admin-all-listing" ? "tablink tab-active" :"tablink"}>All</button>
                </Link>
                <Link to={process.env.PUBLIC_URL+`/admin-new-listing`} className="tabs">
                    <button className={location.pathname=="/admin-new-listing" ? "tablink tab-active" :"tablink"}>New</button>
                </Link>
                <Link to={process.env.PUBLIC_URL+`/admin-approved-listing`} className="tabs">
                    <button className={location.pathname=="/admin-approved-listing" ? "tablink tab-active" :"tablink"}>Approved</button>
                </Link>
                <Link to={process.env.PUBLIC_URL+`/admin-rejected-listing`} className="tabs">
                    <button className={location.pathname=="/admin-rejected-listing" ? "tablink tab-active" :"tablink"}>Rejected</button>
                </Link>
                <Link to={`${process.env.PUBLIC_URL}/admin-complete-listing`} className="tabs">
                    <button className={location.pathname==`${process.env.PUBLIC_URL}/admin-complete-listing` ? "tablink tab-active" :"tablink"}>Complete</button>
                </Link>
            </div>
        </div>
    )
}

export default AdminTabs;