
import React,{useState,useEffect} from "react";
import '../../css/homePage.css';
import { useNavigate, useParams } from "react-router-dom";
import { RotatingTriangles } from 'react-loader-spinner'
import Sidebar from '../../components/sidebarComponent';
import LegalService from '../../services/legal-service';
import { format } from 'date-fns';
import Previewdoc from '../../components/legal/Previewdoc';

function SubmittedContractCopyListing(){
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams();
    const id = params.id;
    const [legalData, setLegalData] = useState([]);
    
    let photograph = '';
    let finaldate = '';
    let contract_name = '';
    let contract_path = '';

    //Extract only photograph from the documents data
    {legalData && legalData.vendor_documents && legalData.vendor_documents.map((documents, index) => {
        if(documents.document_type === 'photograph') {
            photograph = documents.document_path
        }

        if(documents.document_type === 'contract copy'){
            contract_name = documents.document_name
            contract_path = documents.document_path
        }
    })}

    if(legalData?.vendor_info?.joining_date){
        let joindate = new Date(legalData?.vendor_info?.joining_date);
        finaldate = joindate.toISOString().substring(0, 10);
    }   

    const fetchData = async () => {
        const result = await LegalService.getUserInfo(id);
        console.log(result);
        if(result.status != 'Signed Contract Copy'){
            navigate(`${process.env.PUBLIC_URL}/legal-listing`);
        }
        setLegalData(result || []);
        setLoading(false);
    }
    
    useEffect(() => {
        fetchData();
    }, []);

    return(  (loading) ? (<RotatingTriangles
        height="100"
        width="100"
        color="#fff"
        ariaLabel="rotating-triangels-loading"
        wrapperStyle={{}}
        wrapperClass="triangle-loading"
        visible={loading}
      />) : legalData && (    
        <div className="page-wrapper">
            <header className="file-header">
                <img src="img/network18_creative.png" className="nw-logo" alt=" " />
                <div className="hamburger-icon" >
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </header>
            <Sidebar sidebarFor="Legal"/>
            <div className="content-wrapper">
                <div className="overlay" id="overlay"></div>
                <div className="page-content page-reporter-details management-details">
                    <div className="page-header">
                        <h4>Submitted Contract Copy</h4>
                        <div className="breadcrumb-w">
                            <ol className="ms-breadcrumb">
                                <li><a href="reporters.html" >Contract Copy</a></li>
                                <li className="active">{legalData?.vendor_info?.firstname} {legalData?.vendor_info?.lastname}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                                <div className="rd-section">
                                    <h3 className="section-title">Account Details</h3>
                                    <div className="table-responsive">
                                        <table className="table rd-table">
                                            <thead>
                                                <tr>
                                                    <th width="10%">Name</th>
                                                    <th width="12%">PAN</th>
                                                    <th width="12%">Phone</th>
                                                    <th width="12%">District</th>
                                                    <th width="25%" className="address">Address</th>
                                                    <th width="20%">Vendor Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td data-label="Name">
                                                        <div className="reporter-thumb">
                                                            <img src={photograph} alt="" />
                                                        </div>
                                                        <div className="reporter-name">{legalData?.vendor_info?.firstname} {legalData?.vendor_info?.lastname}</div>
                                                    </td>
                                                    <td data-label="Pan">
                                                        <div className="val">{legalData?.vendor_info?.pan_no}</div>
                                                    </td>
                                                    <td data-label="Phone">
                                                        <div className="val">{legalData?.phoneno}</div>
                                                    </td>
                                                    <td data-label="District">
                                                        <div className="val">{legalData?.vendor_address?.city_permanent}</div>
                                                    </td>
                                                    <td data-label="Address" className="address">
                                                        <div className="val">
                                                            {legalData?.vendor_address?.flat_no_permanent}<br /> {legalData?.vendor_address?.landmark_permanent} <br /> {legalData?.vendor_address?.locality_permanent} <br /> {legalData?.vendor_address?.city_permanent} - {legalData?.vendor_address?.pincode_permanent}, {legalData?.vendor_address?.country_permanent}
                                                        </div>
                                                    </td>
                                                    <td data-label="Vendor Code">
                                                        <div className="val">
                                                            <span className="vendor-code">{legalData?.vendor_info?.vendor_code}</span>
                                                        </div>
                                                        <div className="created-date">{format(new Date(legalData?.vendor_address?.created_at), 'd MMMM yyyy')}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="rd-section md-section mbtm">
                                    <h3 className="section-title">Management Details</h3>
                                    <div className="table-responsive">
                                            <table className="table rd-table">
                                                <thead>
                                                    <tr>
                                                        <th>Joining Date</th>
                                                        <th>PAN</th>
                                                        <th>Contract Duration</th>
                                                        <th>Designation</th>
                                                        <th>Salary</th>
                                                        <th>Comments</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td data-label="Joining Date">
                                                            <div className="val">
                                                                {finaldate}
                                                            </div>
                                                        </td>
                                                        <td data-label="Contract Duration">
                                                            <div className="val">
                                                                {legalData?.vendor_info?.pan_no}
                                                            </div>
                                                        </td>
                                                        <td data-label="Contract Duration">
                                                            <div className="val">
                                                                {legalData?.vendor_info?.contract_duration}
                                                            </div>
                                                        </td>
                                                        <td data-label="Designation">
                                                            <div className="val">
                                                                {legalData?.vendor_info?.designation}
                                                            </div>
                                                        </td>
                                                        <td data-label="Salary">
                                                            <div className="val">
                                                                {legalData?.vendor_info?.salary}
                                                            </div>
                                                        </td>
                                                        <td data-label="Comments" className="text-left">
                                                            <div className="val">
                                                                {legalData?.vendor_info?.comments}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        
                                    </div>
                                </div>
                                <div className="rd-section upload-section ar-uploaded-section">
                                    <h3 className="section-title">Download Signed Contract Copy</h3>
                                    <div className="upload-contract-copy">
                                        {/* <label htmlFor="">Approve Signed contract copy</label> */}
                                        <div className="upload-cc-wrapper">
                                            <div className="temp-upload-file">
                                                <div className="icon-holder"><i className="fi fi-file-copy-black"></i></div>
                                                <div className="upload_file_name">{contract_name}</div>
                                                 <a href={contract_path} target="_blank" className="download-icon"><i className="fi fi-file-download"></i></a>
                                            </div>                                         
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div className="card" style={{ marginTop: '30px' }}>
                        <div className="card-head">
                        </div>
                        <div className="card-body" >
                        {params.id && <Previewdoc id={params.id} />} {/* Render Previewdoc only if id is available */}
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    )
}
export default SubmittedContractCopyListing;